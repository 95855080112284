import { Permission } from 'core/constants/permission';
import AlertsSidebarCounter from 'modules/alerts/alerts-sidebar-counter';
import MarketingSidebarNewBadge from 'modules/marketing/marketing-sidebar-new-badge';
import { OrganisationSettingsNavbarOptions } from 'modules/organisation-settings/organisation-settings-navbar-options';
import { ReactElement } from 'react';
import {
  Bell,
  Briefcase,
  Calendar,
  Clipboard,
  CreditCard,
  Icon,
  Mail,
  PieChart,
  Settings,
  ShoppingBag,
  Smile,
  Tool,
  Users,
} from 'react-feather';

export interface INavbarOption {
  NavIcon?: Icon;
  labelKey: string;
  route: string;
  requiredPermissions: string[][];
  extra?: ReactElement;
}

export const NavbarOptions: INavbarOption[] = [
  {
    NavIcon: Briefcase,
    labelKey: 'navigation.organisations',
    route: 'admin/organisations',
    requiredPermissions: [[Permission.HEARLINK_ADMIN]],
  },
  {
    NavIcon: Users,
    labelKey: 'navigation.users',
    route: 'admin/users',
    requiredPermissions: [[Permission.HEARLINK_ADMIN]],
  },
  {
    NavIcon: Calendar,
    labelKey: 'navigation.calendar',
    route: 'calendar',
    requiredPermissions: [[Permission.APPOINTMENTS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    NavIcon: Smile,
    labelKey: 'navigation.patients',
    route: 'patients',
    requiredPermissions: [[Permission.PATIENTS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    NavIcon: Clipboard,
    labelKey: 'navigation.stock',
    route: 'stock-management',
    requiredPermissions: [[Permission.STOCK_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    NavIcon: ShoppingBag,
    labelKey: 'navigation.orders',
    route: 'orders',
    requiredPermissions: [[Permission.ORDERS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    NavIcon: Tool,
    labelKey: 'navigation.repairs',
    route: 'repairs',
    requiredPermissions: [[Permission.REPAIRS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    NavIcon: PieChart,
    labelKey: 'navigation.reporting',
    route: 'reporting',
    requiredPermissions: [[Permission.REPORTS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    NavIcon: Mail,
    labelKey: 'navigation.marketing',
    route: 'marketing',
    requiredPermissions: [[Permission.MARKETING_BROWSE], [Permission.ORGANISATION_OWNER]],
    extra: <MarketingSidebarNewBadge />,
  },
  {
    NavIcon: CreditCard,
    labelKey: 'navigation.finance',
    route: 'finance',
    requiredPermissions: [[Permission.MARKETING_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    NavIcon: Bell,
    labelKey: 'navigation.patient.alerts',
    route: 'alerts',
    requiredPermissions: [[Permission.PATIENT_ALERTS_BROWSE], [Permission.ORGANISATION_OWNER]],
    extra: <AlertsSidebarCounter />,
  },
  {
    NavIcon: Settings,
    labelKey: 'navigation.organisation_settings',
    route: 'organisation-settings',
    requiredPermissions: [
      [Permission.ORGANISATION_OWNER],
      ...OrganisationSettingsNavbarOptions.flatMap((option) => option.requiredPermissions),
    ],
  },
];
