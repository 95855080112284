import { createContext, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DocumentSnapshot,
  FirestoreError,
  QueryConstraint,
  QuerySnapshot,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import {
  PatientFormSettingsApiService,
  OrderFormSettingsApiService,
  LeadTypesApiService,
  WorkflowApiService,
  MetaApiService,
  ClinicApiService,
  UsersApiService,
  AppointmentTypesApiService,
  UploadTypesApiService,
  AudiometersApiService,
  ResourcesApiService,
  HearingAidsApiService,
  AccessoriesApiService,
  ServicesApiService,
  CustomFormTemplatesApiService,
  PatientAlertsApiService,
} from 'core/api';
import {
  IDomainOrganisationDataType,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useUserState } from './user-provider';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { usePermissionsState } from './permissions-provider';
import { Permission } from 'core/constants/permission';
import { IAppointmentTypeDao, IAudiometerDao, IUserDao } from 'core/api/types';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { IPatientAlertDao } from 'core/api/types/patient-alert.interface';
import dayjs from 'dayjs';

interface IOrganisationFirestoreCacheSubscription {
  key: string;
  type: 'document' | 'collection';
  firestoreAction: (...args: any[]) => Unsubscribe;
  actionArgs?: any[];
  queryConstraints?: QueryConstraint[];
  reducer: ActionCreatorWithPayload<any>;
  dataManipulator?: (data: any) => any;
  condition?: boolean;
}

export const OrganisationFirestoreCacheContext = createContext({});

export const OrganisationFirestoreCacheProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const { userData } = useUserState();
  const { userPermissions } = usePermissionsState();
  const appointmentTypes = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);

  const reformatUpdatedTimestamp = (data: any) => {
    const { updated, ...rest } = data;
    return { ...rest, updatedAtSeconds: updated.at.seconds, updatedBy: updated.by };
  };

  const reformatTimestamps = (data: any) => {
    const { updated, created, ...rest } = data;
    return {
      ...rest,
      updatedAtSeconds: updated.at.seconds,
      updatedBy: updated.by,
      createdAtSeconds: created.at.seconds,
      createdBy: created.by,
    };
  };

  const reformatAudiometer = useCallback((data: IAudiometerDao) => {
    const { calibrationDate, ...rest } = data;
    return {
      ...reformatTimestamps(rest),
      calibratedAtSeconds: calibrationDate?.seconds,
    };
  }, []);

  const reformatAlert = useCallback((data: IPatientAlertDao) => {
    const { alertDateTime, patient, ...rest } = data;
    const { status, ...patientRest } = patient;
    return {
      ...reformatTimestamps(rest),
      alertDateTimeSeconds: alertDateTime?.seconds,
      patient: {
        ...reformatTimestamps(patientRest),
        status: {
          status: status.status,
          updatedAtSeconds: status.updated.at.seconds,
          updatedBy: status.updated.by,
        },
      },
    };
  }, []);

  const subscriptions: IOrganisationFirestoreCacheSubscription[] = useMemo(
    () => [
      {
        key: 'patientFormSettings',
        type: 'document',
        actionArgs: [userData?.organisationUid],
        firestoreAction: PatientFormSettingsApiService.onDocSnapshot,
        reducer: OrganisationSettingsSlice.updatePatientFormSettings,
        dataManipulator: reformatUpdatedTimestamp,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'orderFormSettings',
        type: 'document',
        actionArgs: [userData?.organisationUid],
        firestoreAction: OrderFormSettingsApiService.onDocSnapshot,
        reducer: OrganisationSettingsSlice.updateOrderFormSettings,
        dataManipulator: reformatUpdatedTimestamp,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'leadTypes',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: LeadTypesApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateLeadTypes,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'patientWorkflow',
        type: 'document',
        actionArgs: [userData?.organisationUid],
        firestoreAction: WorkflowApiService.onDocSnapshot,
        reducer: OrganisationSettingsSlice.updatePatientWorkflow,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'meta',
        type: 'document',
        actionArgs: [userData?.organisationUid],
        firestoreAction: MetaApiService.onDocSnapshot,
        reducer: OrganisationSettingsSlice.updateMeta,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'clinics',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: ClinicApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateClinics,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'appointmentTypes',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: AppointmentTypesApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateAppointmentTypes,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'uploadTypes',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: UploadTypesApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateUploadTypes,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'audiometers',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: AudiometersApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateAudiometers,
        dataManipulator: reformatAudiometer,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'resources',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: ResourcesApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateResources,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'hearingAids',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: HearingAidsApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateHearingAids,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'accessories',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: AccessoriesApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateAccessories,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'services',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: ServicesApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateServices,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'customFormsTemplates',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: CustomFormTemplatesApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateCustomFormTemplates,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'alerts',
        type: 'collection',
        queryConstraints: [
          where('organisationUid', '==', userData?.organisationUid),
          where('acknowledged', '==', false),
          where('alertDateTime', '<=', dayjs().endOf('day').toDate()),
        ],
        firestoreAction: PatientAlertsApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updatePatientAlerts,
        dataManipulator: reformatAlert,
        condition:
          userData?.organisationUid !== undefined &&
          (userPermissions?.includes(Permission.PATIENT_ALERTS_BROWSE) ||
            userPermissions?.includes(Permission.ORGANISATION_OWNER)),
      },
    ],
    [reformatAlert, reformatAudiometer, userData?.organisationUid, userPermissions]
  );

  const handleCollectionSubscription = useCallback(
    (subscription: IOrganisationFirestoreCacheSubscription) => {
      dispatch(subscription.reducer({ data: [], status: 'loading' }));
      return subscription.firestoreAction(
        (snap: QuerySnapshot) => {
          const data =
            snap.docs.map((doc) =>
              subscription.dataManipulator ? subscription.dataManipulator(doc.data()) : doc.data()
            ) ?? [];
          dispatch(subscription.reducer({ data, status: 'success' }));
        },
        (error: FirestoreError) => {
          dispatch(subscription.reducer({ data: [], status: 'error' }));
          sentryCaptureException(error, `Error subscribing to ${subscription.key} collection`, userData);
        },
        subscription.queryConstraints
      );
    },
    [dispatch, userData]
  );

  const handleDocumentSubscription = useCallback(
    (subscription: IOrganisationFirestoreCacheSubscription) => {
      dispatch(subscription.reducer({ data: undefined, status: 'loading' }));
      const args = subscription.actionArgs ?? [];
      return subscription.firestoreAction(
        ...args,
        (snap: DocumentSnapshot) => {
          if (!snap.exists()) {
            dispatch(subscription.reducer({ data: undefined, status: 'error' }));
          } else {
            const data = subscription.dataManipulator ? subscription.dataManipulator(snap.data()) : snap.data();
            dispatch(subscription.reducer({ data, status: 'success' }));
          }
        },
        (error: FirestoreError) => {
          dispatch(subscription.reducer({ data: undefined, status: 'error' }));
          sentryCaptureException(error, `Error subscribing to ${subscription.key} document`, userData);
        }
      );
    },
    [dispatch, userData]
  );

  useEffect(() => {
    const unsubscribeFunctions = subscriptions.map((subscription) => {
      if (!subscription.condition) {
        return () => {};
      }

      switch (subscription.type) {
        case 'document':
          return handleDocumentSubscription(subscription);
        case 'collection':
          return handleCollectionSubscription(subscription);
        default:
          throw new Error('Invalid subscription type');
      }
    });

    return () => {
      dispatch(OrganisationSettingsSlice.reset());
      unsubscribeFunctions.forEach((unsubscribeFunction) => {
        unsubscribeFunction();
      });
    };
  }, [dispatch, handleCollectionSubscription, handleDocumentSubscription, subscriptions]);

  const convertUserToCalendarResource = useCallback(
    (user: IUserDao, appointmentTypes: IDomainOrganisationDataType<IAppointmentTypeDao>[]) => {
      return {
        uid: user.uid,
        fullName: user.fullName,
        clinics: user.clinics ?? [],
        assignableAppointmentTypes: appointmentTypes
          .filter((type) => type.assignableUsers.some((assignableUser) => assignableUser.uid === user.uid))
          .map((type) => type.uid),
        workingAreas: user.workingAreas ?? [],
        homeAddress: user.homeAddress,
        workingLocations: user.workingLocations ?? {},
        workingLocationOverrides: user.workingLocationOverrides ?? {},
      };
    },
    []
  );

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (appointmentTypes?.status !== 'success') return;
    let assignableUsers: string[] = [];
    appointmentTypes.data.forEach((type) => {
      type.assignableUsers.forEach((user) => {
        const existingUser = assignableUsers.find((uid) => uid === user.uid);
        if (!existingUser) {
          assignableUsers.push(user.uid);
        }
      });
    });

    if (assignableUsers.length === 0) {
      dispatch(OrganisationSettingsSlice.updateCalendarResources({ data: [], status: 'success' }));
      return;
    }

    let subscription: IOrganisationFirestoreCacheSubscription = {
      key: 'calendarResources',
      type: 'collection',
      firestoreAction: UsersApiService.onCollectionSnapshot,
      reducer: OrganisationSettingsSlice.updateCalendarResources,
      dataManipulator: (data) => convertUserToCalendarResource(data, appointmentTypes.data),
      condition: userData?.organisationUid !== undefined,
    };

    if (
      userPermissions?.includes(Permission.APPOINTMENTS_READ_ALL) ||
      userPermissions?.includes(Permission.ORGANISATION_OWNER)
    ) {
      unsubscribe = handleCollectionSubscription({
        ...subscription,
        queryConstraints: [
          where('organisationUid', '==', userData?.organisationUid),
          where('uid', 'in', assignableUsers),
        ],
      });
    } else if (userPermissions?.includes(Permission.APPOINTMENTS_READ_MY_CLINICS)) {
      unsubscribe = handleCollectionSubscription({
        ...subscription,
        queryConstraints: [
          where('organisationUid', '==', userData?.organisationUid),
          where('uid', 'in', assignableUsers),
          where('clinics', 'array-contains-any', userData?.clinics),
        ],
      });
    } else if (userPermissions?.includes(Permission.APPOINTMENTS_READ_MY_CALENDAR)) {
      unsubscribe = handleDocumentSubscription({
        ...subscription,
        type: 'document',
        actionArgs: [userData?.uid],
        firestoreAction: UsersApiService.onDocSnapshot,
        dataManipulator: (data) => [convertUserToCalendarResource(data, appointmentTypes.data)],
        condition: userData?.uid !== undefined,
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [
    appointmentTypes,
    convertUserToCalendarResource,
    dispatch,
    handleCollectionSubscription,
    handleDocumentSubscription,
    userData?.clinics,
    userData?.organisationUid,
    userData?.uid,
    userPermissions,
  ]);

  return <OrganisationFirestoreCacheContext.Provider value={{}}>{children}</OrganisationFirestoreCacheContext.Provider>;
};
