import { IGroupDao } from 'core/api/types';
import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import AddGroupDialog from './add-edit-group-dialog';
import { useNavigate } from 'react-router-dom';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { useMemo } from 'react';
import { IQueryOrder } from 'core/common/types';

const GroupsTableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'groups.groups_list.table.header.group',
    key: 'group',
    contentTemplateId: 'group',
    width: 300,
  },
  {
    labelKey: 'groups.groups_list.table.header.members',
    key: 'members',
    contentTemplateId: 'members',
  },
  {
    labelKey: 'groups.groups_list.table.header.permissions',
    key: 'permissions',
    contentTemplateId: 'permissions',
  },
  { key: 'action', contentTemplateId: 'actions' },
];

const GroupsList = () => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const dialog = useDialog();
  const navigate = useNavigate();

  const groupTemplate = (group: IGroupDao) => {
    return (
      <div className='flex flex-col body-sm'>
        <p>{group.name}</p>
        <p className='text-gray-400'>{group.description ?? t('groups.groups_list.table.group_cell.no_description')}</p>
      </div>
    );
  };

  const membersTemplate = (group: IGroupDao) => {
    return <p>{group.members.length}</p>;
  };

  const permissionsTemplate = (group: IGroupDao) => {
    return <p>{group.permissions.length}</p>;
  };

  const actionTemplate = (group: IGroupDao) => {
    return (
      <div className='w-full flex justify-end'>
        <SharedButton
          onClick={() => navigate(group.uid)}
          type='button'
          appearance='link'
          labelKey='common.view'
          primaryOverride
        />
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: groupTemplate,
      id: 'group',
    },
    {
      template: membersTemplate,
      id: 'members',
    },
    {
      template: permissionsTemplate,
      id: 'permissions',
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.GROUPS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='groups.groups_list.header.add_group'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddGroupDialog />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addGroup',
    },
  ];

  const queryOrder = useMemo((): IQueryOrder => ['updated.at', 'desc'], []);

  const queryConstraints = useMemo(
    () => [where('organisationUid', '==', userData?.organisationUid)],
    [userData?.organisationUid],
  );

  return (
    <>
      <SharedPageHeader title={t('navigation.groups')} actions={headerActions} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.GROUPS}
            queryConstraints={queryConstraints}
            queryOrder={queryOrder}
            tableConfig={{
              columns: GroupsTableColumns,
              contentTemplates,
            }}
            errorMessageKey='groups.groups_list.table.error.description'
            tableKey='groups.groups_list.table'
          />
        )}
      </SharedCard>
    </>
  );
};

export default GroupsList;
