import { useEffect } from 'react';

const useKeyPress = (
  key: string,
  action: () => void,
  options: { ctrl?: boolean; meta?: boolean; shift?: boolean } = {}
) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { ctrl = false, meta = false, shift = false } = options;

      if (
        event.key.toLowerCase() === key.toLowerCase() &&
        (ctrl ? event.ctrlKey : true) &&
        (meta ? event.metaKey : true) &&
        (shift ? event.shiftKey : true)
      ) {
        event.preventDefault();
        action();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [key, action, options]);
};

export default useKeyPress;
