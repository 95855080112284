import { MarketingApiService } from 'core/api';
import { IMarketingQueryDao } from 'core/api/types/marketing-query.interface';
import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn } from 'shared/table/table.interface';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Alert } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { IQueryOrder } from 'core/common/types';
import { Sparkles } from 'lucide-react';

const MarketingHub = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const tableKey = 'marketing.marketing_hub.table';
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { marketing: featureEnabled } = useFlags();
  const [userIntroSetting, setUserIntroSetting] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const marketingHubIntroString = localStorage.getItem('marketingHubUserIntroSetting');
    if (marketingHubIntroString) {
      const marketingHubIntro = JSON.parse(marketingHubIntroString);
      setUserIntroSetting(marketingHubIntro);
    }
  }, [userData?.uid]);

  const tableColumns: ISharedTableColumn[] = [
    {
      labelKey: 'marketing.marketing_hub.table.header.description',
      key: 'description',
      width: 500,
    },
    {
      labelKey: 'marketing.marketing_hub.table.header.created',
      key: 'created',
      contentTemplateId: 'createdAt',
    },
    {
      labelKey: 'marketing.marketing_hub.table.header.updated',
      key: 'updated',
      contentTemplateId: 'updatedAt',
    },
    {
      key: 'actions',
      contentTemplateId: 'actions',
    },
  ];

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.MARKETING_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='marketing.marketing_hub.header.create_new_query'
            onClick={() => navigate('create')}
            appearance='primary'
          />
        </SharedElementPermissionGuard>
      ),
      key: 'createNewQuery',
    },
  ];

  const createdAtTemplate = (query: IMarketingQueryDao) => {
    return (
      <p className='whitespace-pre-wrap body-sm'>
        {t('common.at_by', {
          at: dayjs(query.created.at.toDate()).format('DD/MM/YYYY, HH:mm'),
          by: query.created.by.fullName,
        })}
      </p>
    );
  };

  const updatedAtTemplate = (query: IMarketingQueryDao) => {
    return (
      <p className='whitespace-pre-wrap body-sm'>
        {t('common.at_by', {
          at: dayjs(query.updated.at.toDate()).format('DD/MM/YYYY, HH:mm'),
          by: query.updated.by.fullName,
        })}
      </p>
    );
  };

  const actionsTemplate = (query: IMarketingQueryDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.MARKETING_READ], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => navigate(query.uid)}
            type='button'
            appearance='link'
            labelKey='common.view'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.MARKETING_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              openDialog(
                <ConfirmActionDialog
                  action={() => MarketingApiService.permDelete(query.uid)}
                  componentPortalKeys={[tableKey]}
                  title={t('marketing.marketing_hub.table.delete_query.title')}
                  textContent={t('marketing.marketing_hub.table.delete_query.content')}
                  successMessage={t('marketing.marketing_hub.table.delete_query.success')}
                  errorMessage={t('marketing.marketing_hub.table.delete_query.error')}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                />
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const queryOrder = useMemo((): IQueryOrder => ['updated.at', 'desc'], []);

  const queryConstraints = useMemo(
    () => [where('organisationUid', '==', userData?.organisationUid)],
    [userData?.organisationUid]
  );

  return (
    <>
      <SharedPageHeader title={t('navigation.marketing')} actions={featureEnabled ? headerActions : []} />
      {featureEnabled ? (
        <>
          {userIntroSetting[userData?.uid!] !== false && (
            <div className='mb-4 shadow-md bg-gray-800 text-white p-3 rounded-md'>
              <div className='animated-border rounded-sm'>
                <div className='p-4'>
                  <p className='header-md mb-2'>{t('marketing.marketing_hub.intro.title')}</p>
                  <p className='text-gray-300'>
                    We're excited to launch the first phase of our marketing module, powered by{' '}
                    <span className='font-semibold text-white'>Tonee, HearLink's AI assistant</span>. Tonee will help
                    you indentify and target patients within your database and return them in a convienient CSV file
                    that can be used for your marketing campaigns.
                  </p>
                  <div className='mt-4 flex space-x-4'>
                    <SharedButton
                      labelKey={t('marketing.marketing_hub.intro.get_started')}
                      ghost
                      onClick={() => navigate('create')}
                      icon={<Sparkles size={18} />}
                    />
                    <SharedButton
                      labelKey={t('marketing.marketing_hub.intro.dismiss')}
                      additionalStyle={{ color: '#FFFFFF' }}
                      appearance='link'
                      onClick={() => {
                        setUserIntroSetting((prev) => {
                          const updated = { ...prev, [userData?.uid!]: false };
                          localStorage.setItem('marketingHubUserIntroSetting', JSON.stringify(updated));
                          return updated;
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <SharedCard>
            {userData && (
              <SharedPaginatedTable
                collectionId={CollectionID.MARKETING_QUERIES}
                queryConstraints={queryConstraints}
                queryOrder={queryOrder}
                tableConfig={{
                  columns: tableColumns,
                  contentTemplates: [
                    {
                      id: 'createdAt',
                      template: createdAtTemplate,
                    },
                    {
                      id: 'actions',
                      template: actionsTemplate,
                    },
                    {
                      id: 'updatedAt',
                      template: updatedAtTemplate,
                    },
                  ],
                }}
                errorMessageKey='marketing.marketing_hub.table.error'
                tableKey={tableKey}
              />
            )}
          </SharedCard>
        </>
      ) : (
        <Alert className='mt-4' showIcon message='Module not enabled' />
      )}
    </>
  );
};

export default MarketingHub;
