import { Alert } from 'antd';
import { Permission } from 'core/constants/permission';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';

const ReportingHub = () => {
  const { t } = useTranslation();
  const reportingModules = [
    {
      key: 'appointments',
      labelKey: 'reporting.hub.appointment_reports',
      requiredPermissions: [[Permission.REPORTS_APPOINTMENTS], [Permission.ORGANISATION_OWNER]],
      reports: [
        { key: 'count', path: 'appointments/total', labelKey: 'reporting.hub.appointment_reports.totals' },
        {
          key: 'conversion',
          path: 'appointments/conversion-rate',
          labelKey: 'reporting.hub.appointment_reports.conversion',
        },
      ],
    },
    {
      key: 'sales',
      labelKey: 'reporting.hub.sales_reports',
      reports: [],
    },
    {
      key: 'orders',
      labelKey: 'reporting.hub.orders_reports',
      reports: [],
    },
    {
      key: 'stock',
      labelKey: 'reporting.hub.stock_reports',
      reports: [],
    },
    {
      key: 'marketing',
      labelKey: 'reporting.hub.marketing_reports',
      reports: [],
    },
    {
      key: 'patient',
      labelKey: 'reporting.hub.patient_reports',
      reports: [],
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('reporting.hub')} />
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 h-max'>
        {reportingModules.map((section) => (
          <SharedCard key={section.key} title={t(section.labelKey)} outerClassName='h-fit'>
            {section.reports.length === 0 ? (
              <Alert showIcon className='m-4' message={t('reporting.hub.no_reports')} type='info' />
            ) : (
              <SharedElementPermissionGuard
                requiredPermissions={section.requiredPermissions ?? []}
                replacement={<Alert showIcon className='m-4' message={t('reporting.hub.no_permission')} type='error' />}
              >
                {section.reports.map((report) => (
                  <div
                    key={report.key}
                    className='px-4 flex items-center justify-between min-h-[50px] border-b last:border-b-0'
                  >
                    <p>{t(report.labelKey)}</p>
                    <Link to={report.path}>
                      <SharedButton appearance='link' labelKey='common.view' primaryOverride />
                    </Link>
                  </div>
                ))}
              </SharedElementPermissionGuard>
            )}
          </SharedCard>
        ))}
      </div>
    </>
  );
};

export default ReportingHub;
