import { IAuditLogDao } from 'core/api/types';
import { CollectionID } from 'core/constants/collection-id';
import { useUserState } from 'core/providers/user-provider';
import { where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import dayjs from 'dayjs';
import SharedAuditLogActionTemplate from 'shared/audit-log/audit-log-action-template';
import SharedAuditLogResource from 'shared/audit-log/audit-log-resource';
import { useMemo } from 'react';
import { IQueryOrder } from 'core/common/types';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'audit_log.table.header.user',
    key: 'user',
    contentTemplateId: 'user',
  },
  {
    labelKey: 'audit_log.table.header.resource',
    key: 'resource',
    contentTemplateId: 'resource',
  },
  {
    labelKey: 'audit_log.table.header.action',
    key: 'action',
    contentTemplateId: 'action',
  },
  {
    labelKey: 'audit_log.table.header.associated_resources',
    key: 'associations',
    contentTemplateId: 'associations',
  },
  {
    labelKey: 'audit_log.table.header.timestamp',
    key: 'timestamp',
    contentTemplateId: 'timestamp',
  },
];

const AuditLog = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const tableKey = 'audit_log';

  const userTemplate = (log: IAuditLogDao) => log.created.by.fullName;

  const resourceTemplate = (log: IAuditLogDao) => <SharedAuditLogResource {...log.resource} />;

  const associationsTemplate = (log: IAuditLogDao) => (
    <div>
      {!log.associations || log.associations.length === 0 ? (
        <p className='text-gray-300'>{t('common.na')}</p>
      ) : (
        log.associations.map((association) => <SharedAuditLogResource key={association.uid} {...association} />)
      )}
    </div>
  );

  const timestampTemplate = (log: IAuditLogDao) => dayjs(log.updated.at.toDate()).format('DD/MM/YYYY, HH:mm');

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: userTemplate,
      id: 'user',
    },
    {
      template: resourceTemplate,
      id: 'resource',
    },
    {
      template: (log: IAuditLogDao) => <SharedAuditLogActionTemplate {...log} />,
      id: 'action',
    },
    {
      template: associationsTemplate,
      id: 'associations',
    },
    {
      template: timestampTemplate,
      id: 'timestamp',
    },
  ];

  const queryOrder = useMemo((): IQueryOrder => ['updated.at', 'desc'], []);

  const queryConstraints = useMemo(
    () => [where('organisationUid', '==', userData?.organisationUid)],
    [userData?.organisationUid]
  );

  return (
    <>
      <SharedPageHeader title={t('navigation.audit_log')} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.AUDIT_LOG}
            queryConstraints={queryConstraints}
            queryOrder={queryOrder}
            tableConfig={{
              columns: tableColumns,
              contentTemplates,
            }}
            errorMessageKey='audit_log.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default AuditLog;
