import SharedForm from 'shared/form/shared-form';
import {
  IAppointmentConversionRateReportFilterValues,
  IAppointmentConversionRateReportSessionValues,
} from './appointment-conversion-report';
import { Drawer, Form, FormInstance } from 'antd';
import { ControlType } from 'core/enums/control-type';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useEffect, useMemo, useState } from 'react';
import SharedButton from 'shared/button/button';
import { PanelLeftClose } from 'lucide-react';
import clsx from 'clsx';
import { useResponsiveState } from 'core/providers/responsive-provider';

interface IAppointmentConversionRateReportFilters {
  filters: IAppointmentConversionRateReportSessionValues;
}

const AppointmentConversionRateReportFilters = ({ filters }: IAppointmentConversionRateReportFilters) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isMobile } = useResponsiveState();

  const defaultFilters = useMemo(
    () => ({
      dateRange: [dayjs().subtract(1, 'month'), dayjs()] as [dayjs.Dayjs, dayjs.Dayjs],
      groupBy: 'appointmentType',
    }),
    []
  );

  useEffect(() => {
    if (filters) {
      form.setFieldsValue({
        ...filters,
        dateRange: [dayjs(filters.startDate), dayjs(filters.endDate)],
      });
    }
  }, [defaultFilters, filters, form]);

  const shouldIndicatorShow = useMemo(() => {
    const keys = [
      'clinics',
      'assignees',
      'appointmentTypes',
      'referralsPatient',
      'referralsAppointment',
    ] as (keyof IAppointmentConversionRateReportSessionValues)[];
    return keys.some((key) => filters[key]?.length);
  }, [filters]);

  return (
    <>
      <Drawer
        title={t('filters.title')}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        extra={
          <SharedButton
            appearance='primary'
            ghost
            labelKey='filters.reset'
            onClick={() => {
              sessionStorage.setItem(
                'reporting.appointments.conversion.filters',
                JSON.stringify({
                  ...defaultFilters,
                  startDate: defaultFilters.dateRange[0].format('YYYY-MM-DD'),
                  endDate: defaultFilters.dateRange[1].format('YYYY-MM-DD'),
                })
              );
              form.resetFields();
              setOpenDrawer(false);
            }}
          />
        }
      >
        <AppointmentConversionRateReportFiltersForm form={form} />
      </Drawer>
      <div className='flex justify-between items-end border-b px-4 pt-4'>
        {!isMobile ? <AppointmentConversionRateReportFiltersForm form={form} onlyKeyItems /> : <div />}
        <div className='relative'>
          {shouldIndicatorShow && <div className='h-2 w-2 bg-red-500 rounded-full top-1 z-10 right-0 absolute' />}
          <SharedButton
            labelKey='filters.all_filters'
            className='mb-4'
            appearance='link'
            primaryOverride
            icon={<PanelLeftClose />}
            iconPosition='end'
            onClick={() => setOpenDrawer(true)}
          />
        </div>
      </div>
    </>
  );
};

export default AppointmentConversionRateReportFilters;

interface IAppointmentConversionRateReportFiltersForm {
  form: FormInstance<IAppointmentConversionRateReportFilterValues>;
  onlyKeyItems?: boolean;
}

const AppointmentConversionRateReportFiltersForm = ({
  onlyKeyItems = false,
  form,
}: IAppointmentConversionRateReportFiltersForm) => {
  const { t } = useTranslation();
  const { clinics, appointmentTypes, calendarResources, leadTypes } = useSelector(
    OrganisationSettingsSlice.selectMultiple(['clinics', 'calendarResources', 'appointmentTypes', 'leadTypes'])
  );

  return (
    <SharedForm<IAppointmentConversionRateReportFilterValues>
      requiredMark={false}
      className={clsx(onlyKeyItems && 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 md:gap-2')}
      formInstance={form}
      fields={[
        {
          fieldKey: 'groupBy',
          control: ControlType.Select,
          options: ['appointmentType', 'clinic', 'assignee', 'patientReferral', 'appointmentReferral'].map((key) => ({
            label: t(`reporting.hub.appointment_reports.conversion_rate.filters.groupBy.${key}`),
            value: key,
          })),
          label: t('reporting.hub.appointment_reports.conversion_rate.filters.groupBy'),
          required: true,
        },
        {
          fieldKey: 'dateRange',
          control: ControlType.DateRangePicker,
          label: t('reporting.hub.appointment_reports.conversion_rate.filters.dateRange'),
          presets: [
            {
              label: t('reporting.hub.appointment_reports.conversion_rate.filters.dateRange.30_days'),
              value: [dayjs().subtract(30, 'days'), dayjs()],
            },
            {
              label: t('reporting.hub.appointment_reports.conversion_rate.filters.dateRange.90_days'),
              value: [dayjs().subtract(90, 'days'), dayjs()],
            },
          ],
          required: true,
          fullWidth: true,
          allowClear: false,
        },
        {
          fieldKey: 'clinics',
          control: ControlType.Select,
          options:
            clinics?.data.map((clinic) => ({
              label: clinic.name,
              value: clinic.uid,
            })) ?? [],
          label: t('reporting.hub.appointment_reports.conversion_rate.filters.clinics'),
          required: false,
          mode: 'multiple',
          placeholder: t('reporting.hub.appointment_reports.conversion_rate.filters.no_filter'),
          hidden: onlyKeyItems,
        },
        {
          fieldKey: 'assignees',
          control: ControlType.Select,
          options:
            calendarResources?.data.map((resource) => ({
              label: resource.fullName,
              value: resource.uid,
            })) ?? [],
          label: t('reporting.hub.appointment_reports.conversion_rate.filters.assignees'),
          required: false,
          mode: 'multiple',
          placeholder: t('reporting.hub.appointment_reports.conversion_rate.filters.no_filter'),
          hidden: onlyKeyItems,
        },
        {
          fieldKey: 'appointmentTypes',
          control: ControlType.Select,
          options:
            appointmentTypes?.data.map((appointmentType) => ({
              label: appointmentType.name,
              value: appointmentType.uid,
            })) ?? [],
          label: t('reporting.hub.appointment_reports.conversion_rate.filters.appointmentTypes'),
          required: false,
          mode: 'multiple',
          placeholder: t('reporting.hub.appointment_reports.conversion_rate.filters.no_filter'),
          hidden: onlyKeyItems,
        },
        {
          fieldKey: 'referralsPatient',
          control: ControlType.Select,
          options:
            leadTypes?.data.map((lt) => ({
              label: lt.name,
              value: lt.uid,
            })) ?? [],
          label: t('reporting.hub.appointment_reports.conversion_rate.filters.patientReferrals'),
          required: false,
          mode: 'multiple',
          placeholder: t('reporting.hub.appointment_reports.conversion_rate.filters.no_filter'),
          hidden: onlyKeyItems,
        },
        {
          fieldKey: 'referralsAppointment',
          control: ControlType.Select,
          options:
            leadTypes?.data.map((lt) => ({
              label: lt.name,
              value: lt.uid,
            })) ?? [],
          label: t('reporting.hub.appointment_reports.conversion_rate.filters.appointmentReferrals'),
          required: false,
          mode: 'multiple',
          placeholder: t('reporting.hub.appointment_reports.conversion_rate.filters.no_filter'),
          hidden: onlyKeyItems,
        },
      ]}
      name={`reporting.appointments.conversion.filters.${onlyKeyItems ? 'key' : 'full'}`}
      buttonsOverride={[]}
      onChange={(_, all) =>
        sessionStorage.setItem(
          'reporting.appointments.conversion.filters',
          JSON.stringify({
            ...all,
            startDate: all.dateRange[0].format('YYYY-MM-DD'),
            endDate: all.dateRange[1].format('YYYY-MM-DD'),
          })
        )
      }
    />
  );
};
