import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { Drawer, Select } from 'antd';
import { Filter } from 'react-feather';
import { IFilter } from './filter';

interface IDrawerFilter {
  filters: IFilter[];
  onFilterChange: (filters: { [key: string]: string[] }) => void;
}

const DrawerFilter = ({ filters, onFilterChange }: IDrawerFilter) => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string[] }>(() => {
    const storedFilters = localStorage.getItem('drawerFilters');
    return storedFilters ? JSON.parse(storedFilters) : {};
  });

  useEffect(() => {
    onFilterChange(activeFilters);
    localStorage.setItem('drawerFilters', JSON.stringify(activeFilters));
  }, [activeFilters, onFilterChange]);

  const handleFilterChange = (key: string, value: string[]) => {
    setActiveFilters((prev) => {
      const newFilters = { ...prev };
      if (value.length === 0) {
        delete newFilters[key];
      } else {
        newFilters[key] = value;
      }
      return newFilters;
    });
  };

  const clearFilters = () => {
    setActiveFilters({});
    localStorage.removeItem('drawerFilters');
  };

  return (
    <>
      <SharedButton appearance='default' icon={<Filter size={20} />} onClick={() => setShowFilters(true)} />
      <Drawer
        title={t('filters.title')}
        extra={<SharedButton onClick={clearFilters} labelKey='filters.clear' primaryOverride appearance='link' />}
        onClose={() => setShowFilters(false)}
        open={showFilters}
      >
        <div className='space-y-4'>
          {filters.map((filter) => (
            <Select
              maxCount={filter.maxCount}
              value={activeFilters[filter.key] || []}
              key={filter.key}
              placeholder={filter.label}
              options={filter.options}
              mode={filter.mode}
              style={{ width: '100%' }}
              onChange={(value) => handleFilterChange(filter.key, value)}
            />
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default DrawerFilter;
