import { Alert, App } from 'antd';
import clsx from 'clsx';
import { AppointmentsApiService, HolidayAndUnavailabilityApiService, PatientApiService } from 'core/api';
import { IPatientDao, IUserDao } from 'core/api/types';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { AppointmentLocation } from 'core/constants/appointment-location';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { Unsubscribe } from 'firebase/auth';
import { and, FirestoreError, or, orderBy, where } from 'firebase/firestore';
import {
  IDomainCalendarResource,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCalendar from 'shared/calendar/calendar';
import SharedForm from 'shared/form/shared-form';
import {
  getAppointment60MinuteTimeSlots,
  getCalendarHours,
  getTimestampFromDateAndTimeString,
} from 'shared/helpers/appointment-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import * as turf from '@turf/turf';
import { IAddressDao } from 'shared/interfaces/address.interface';
import { useDialog } from 'core/providers/dialog-provider';
import TimelinePreviewDialog from 'shared/dialog/timeline-preview-dialog';
import ProgressBar from 'shared/progress-bar/progress-bar';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import { IAddEditAppointmentFormOutput } from './types';
import { getFormFields } from './form-fields';
import { useAppointmentForm } from './hooks/useAppointmentForm';

const AddEditAppointment = () => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const { message } = App.useApp();
  const { userData, organisationData } = useUserState();
  const navigate = useNavigate();

  const appointmentTypeState = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const calendarResourcesState = useSelector(OrganisationSettingsSlice.selectCalendarResources);
  const leadTypeState = useSelector(OrganisationSettingsSlice.selectLeadTypes);

  // State
  const [selectedAssigneeData, setSelectedAssigneeData] = useState<IDomainCalendarResource>();
  const [recommendedResources, setRecommendedResources] = useState<IDomainCalendarResource[]>([]);
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const [patientData, setPatientData] = useState<IPatientDao>();
  const [appointments, setAppointments] = useState<IAppointmentDao[]>([]);
  const [appointment, setAppointment] = useState<IAppointmentDao>();
  const [loadingCalendarAppointments, setLoadingCalendarAppointments] = useState(true);
  const [unavailability, setUnavailability] = useState<IHolidayAndUnavailabilityDao[]>([]);
  const [loadingCalendarUnavailability, setLoadingCalendarUnavailability] = useState(true);
  const [mobileShowCalendar, setMobileShowCalendar] = useState(false);
  const [showSendConfirmationSwitch, setShowSendConfirmationSwitch] = useState(true);

  // Variables
  const calendarStart = dayjs(organisationData?.calendar.startTime.toDate());
  const startHour = calendarStart.hour();
  const startMinute = calendarStart.minute();
  const calendarEnd = dayjs(organisationData?.calendar.endTime.toDate());
  const endHour = calendarEnd.hour();
  const endMinute = calendarEnd.minute();
  const calendarHours = getCalendarHours(startHour, endHour);

  // Memos
  const appointmentTypes = useMemo(() => appointmentTypeState?.data ?? [], [appointmentTypeState]);
  const clinics = useMemo(() => clinicsState?.data ?? [], [clinicsState]);
  const leadTypes = useMemo(() => leadTypeState?.data ?? [], [leadTypeState]);
  const calendarResources = useMemo(() => calendarResourcesState?.data ?? [], [calendarResourcesState]);

  const {
    form,
    loading,
    submitting,
    watchedFormValues: { type: selectedType, clinic, location, assignee, date, startTime, endTime, referral },
    appointmentUid,
    patientParam,
    timeParam,
    assigneeParam,
    clinicParam,
    selectedDay,
    selectedStartDate,
    setLoading,
    setWatchedFormValues,
    handleSubmit,
    handleChanges,
  } = useAppointmentForm({
    userData: userData!,
    patientData: patientData!,
    appointments,
    appointmentTypes,
    selectedAssigneeData,
    calendarEnd,
  });

  const assigneeList = useMemo(() => {
    if (selectedType && clinic && calendarResources) {
      return calendarResources.filter(
        (resource) => resource.assignableAppointmentTypes.includes(selectedType) && resource.clinics.includes(clinic)
      );
    }
    return undefined;
  }, [calendarResources, clinic, selectedType]);

  const referralSubTypeOptions = useMemo(
    () =>
      leadTypes
        .find((leadType) => leadType.uid === referral)
        ?.subTypes?.filter((s) => !s.deleted)
        .map((s) => ({
          label: s.name,
          value: s.uid,
        })),
    [leadTypes, referral]
  );

  useEffect(() => {
    if (assignee && assigneeList) {
      const assigneeMatch = assigneeList.find((resource) => resource.uid === assignee);
      if (!assigneeMatch) {
        form.setFieldsValue({ assignee: undefined });
        setWatchedFormValues((prev) => ({ ...prev, assignee: undefined }));
      }
    }
  }, [assignee, assigneeList, form, setWatchedFormValues]);

  useEffect(() => {
    const fetchPatientData = async (uid: string) => {
      try {
        const snap = await PatientApiService.get(uid);
        if (snap.exists()) {
          setPatientData(snap.data());
        } else {
          message.error(t('calendar.add_edit_appointment.patient_not_found'));
          sentryCaptureException(
            new Error(`Tried to fetch patient: ${uid} but they don't exist`),
            'Fetching patient data for appointment'
          );
          navigate(-1);
        }
      } catch (error) {
        message.error(t('calendar.add_edit_appointment.fetch_patient_error'));
        sentryCaptureException(error, 'Fetching patient data for appointment');
        navigate(-1);
      }
    };

    if (!patientParam || patientParam === 'undefined') {
      navigate(-1);
      message.info(t('calendar.add_edit_appointment.patient_not_selected'));
    } else {
      fetchPatientData(patientParam);
    }
  }, [patientParam, message, navigate, t]);

  useEffect(() => {
    const fetchAppointmentData = async () => {
      setLoading(true);
      try {
        const snap = await AppointmentsApiService.get(appointmentUid!);
        if (snap.exists()) {
          const data = snap.data();
          setAppointment(data);
          setWatchedFormValues({
            type: data.type,
            clinic: data.clinic,
            location: data.location,
            assignee: data.assignee.uid,
            date: dayjs(data.startDateTime.toDate()),
            startTime: dayjs(data.startDateTime.toDate()),
            endTime: dayjs(data.endDateTime.toDate()),
            referral: data.referral,
          });
        } else {
          message.error(t('calendar.add_edit_appointment.appointment_not_found'));
          sentryCaptureException(
            new Error(`Tried to fetch appointment: ${appointmentUid} but it doesn't exist`),
            'Fetching appointment data for editing appointment',
            userData
          );
        }
      } catch (error) {
        message.error(t('calendar.add_edit_appointment.fetch_appointment_error'));
        sentryCaptureException(error, 'Fetching appointment data for editing appointment');
      } finally {
        setLoading(false);
      }
    };

    if (appointmentUid) {
      fetchAppointmentData();
    } else {
      setLoading(false);
    }
  }, [appointmentUid, message, userData, t, setLoading, setWatchedFormValues]);

  const getRecommendedResources = useCallback(async (resources: IDomainCalendarResource[], address?: IAddressDao) => {
    if (!address || !address.lat || !address.lng) {
      setRecommendedResources([]);
    } else {
      const recommended = resources.filter((resource) => {
        if (!resource.workingAreas) {
          return false;
        }
        const polys = resource.workingAreas
          .map((areaString) =>
            areaString.split('##').map((point) => {
              const { lat, lng } = JSON.parse(point) as google.maps.LatLngLiteral;
              return [lng, lat];
            })
          )
          .map((positions) => {
            const first = positions[0];
            return turf.polygon([[...positions, first]]);
          });

        return polys.some((poly) => turf.booleanPointInPolygon([address.lng!, address.lat!], poly));
      });
      setRecommendedResources(recommended);
    }
  }, []);

  useEffect(() => {
    if (clinic && location) {
      let address = patientData?.address;
      if (location === AppointmentLocation.CLINIC) {
        const clinicData = clinicsState?.data.find((clinicData) => clinicData.uid === clinic);
        address = clinicData?.address;
      }
      getRecommendedResources(assigneeList ?? [], address);
    }
  }, [assigneeList, clinic, clinicsState?.data, getRecommendedResources, location, patientData?.address]);

  const handleSubscriptionError = useCallback(
    (error: FirestoreError, userData?: IUserDao) => {
      message.error(t('appointments.appointments_calendar.get_appointments_error'));
      sentryCaptureException(error, 'Appointments calendar fetching appointments', userData);
    },
    [message, t]
  );

  useEffect(() => {
    const subscriptions: Unsubscribe[] = [];
    if (assigneeList && assigneeList.length > 0 && selectedStartDate) {
      setLoadingCalendarAppointments(true);
      setLoadingCalendarUnavailability(true);
      const baseConstraints = [
        where('organisationUid', '==', userData?.organisationUid),
        where(
          'assignee.uid',
          'in',
          assigneeList.map((resource) => resource.uid)
        ),
      ];
      subscriptions.push(
        AppointmentsApiService.onCollectionSnapshot(
          (snap) => {
            setAppointments(snap.docs.map((doc) => doc.data()));
            setLoadingCalendarAppointments(false);
          },
          (error) => handleSubscriptionError(error, userData),
          [
            ...baseConstraints,
            where('startDateTime', '>=', dayjs(selectedStartDate).startOf('week').startOf('day').toDate()),
            where('startDateTime', '<=', dayjs(selectedStartDate).endOf('week').endOf('day').toDate()),
          ]
        ),
        HolidayAndUnavailabilityApiService.onCollectionSnapshot(
          (snap) => {
            setUnavailability(snap.docs.map((doc) => doc.data()));
            setLoadingCalendarUnavailability(false);
          },
          (error) => handleSubscriptionError(error, userData),
          [],
          {
            filter: and(
              ...baseConstraints,
              or(
                and(
                  where('repeat.isRepeating', '==', false),
                  or(
                    and(
                      where('startDateTime', '>=', dayjs(selectedStartDate).startOf('week').startOf('day').toDate()),
                      where('startDateTime', '<=', dayjs(selectedStartDate).endOf('week').endOf('day').toDate())
                    ),
                    and(
                      where('endDateTime', '>=', dayjs(selectedStartDate).startOf('week').startOf('day').toDate()),
                      where('endDateTime', '<=', dayjs(selectedStartDate).endOf('week').endOf('day').toDate())
                    )
                  )
                ),
                and(
                  where('repeat.isRepeating', '==', true),
                  where('startDateTime', '<=', dayjs(selectedStartDate).endOf('week').endOf('day').toDate()),
                  where('endDateTime', '>=', dayjs(selectedStartDate).startOf('week').startOf('day').toDate())
                )
              )
            ),
            orderBy: orderBy('startDateTime'),
          }
        )
      );
    }

    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe());
    };
  }, [message, t, userData, selectedStartDate, assigneeList, handleSubscriptionError]);

  useEffect(() => {
    if (assignee) {
      setSelectedAssigneeData(calendarResources?.find((resource) => resource.uid === assignee));
    }
  }, [assignee, calendarResources]);

  const checkTimeIsWithinCalendarHours = (time: Dayjs) => {
    const d = date ?? selectedDay;
    const selectedTime = getTimestampFromDateAndTimeString(d, time.format('HH:mm'));
    const start = getTimestampFromDateAndTimeString(d, dayjs().hour(startHour).minute(startMinute).format('HH:mm'));
    const end = getTimestampFromDateAndTimeString(d, dayjs().hour(endHour).minute(endMinute).format('HH:mm'));
    return selectedTime >= start && selectedTime <= end;
  };

  useEffect(() => {
    const appointmentTypeFullDetail = appointmentTypeState?.data.find((type) => type.uid === selectedType);
    const confirmationsEnabled =
      appointmentTypeFullDetail?.confirmation.sms.enabled ||
      appointmentTypeFullDetail?.confirmation.email.enabled ||
      false;
    setShowSendConfirmationSwitch(confirmationsEnabled);
    if (confirmationsEnabled && appointment) {
      form.setFieldValue('sendConfirmation', appointment.sendConfirmation);
    } else {
      form.setFieldValue('sendConfirmation', confirmationsEnabled);
    }
  }, [appointment, appointmentTypeState?.data, form, selectedType]);

  const formFields = getFormFields({
    t,
    organisationData: organisationData!,
    selectedType,
    clinic,
    showSendConfirmationSwitch,
    assigneeList,
    recommendedResources,
    appointmentTypes,
    clinics,
    leadTypes,
    referralSubTypeOptions,
    calendarHours,
    checkTimeIsWithinCalendarHours,
    startTime,
    endTime,
    referral,
  });

  return !loading &&
    (appointment || !appointmentUid) &&
    formFields.length &&
    clinics?.length &&
    appointmentTypes?.length &&
    leadTypes?.length &&
    calendarResources?.length ? (
    <>
      <SharedPageHeader
        title={t(
          appointmentUid ? 'calendar.add_edit_appointment.edit.title' : 'calendar.add_edit_appointment.create.title'
        )}
        showBack
      />
      <div className='rounded-md bg-white shadow-md mb-4 grow flex flex-col md:flex-row overflow-hidden'>
        <div
          className={clsx(
            'w-full grow md:basis-[300px] flex flex-col justify-between md:grow-0 md:shrink-0 md:border-r overflow-y-auto',
            mobileShowCalendar && 'hidden md:block'
          )}
        >
          <SharedForm<IAddEditAppointmentFormOutput>
            onChange={handleChanges}
            formInstance={form}
            onFinish={handleSubmit}
            fields={formFields}
            submitting={submitting}
            name='add-edit-appointment-form'
            existingValue={{
              ...appointment,
              assignee: appointment?.assignee.uid ?? assigneeParam ?? undefined,
              startTime: (() => {
                if (appointment) {
                  return dayjs(appointment.startDateTime.toDate());
                }
                if (timeParam) {
                  return selectedDay.hour(parseInt(timeParam.split(':')[0])).minute(parseInt(timeParam.split(':')[1]));
                }
                return undefined;
              })(),
              endTime: appointment ? dayjs(appointment.endDateTime.toDate()) : undefined,
              date: appointment ? dayjs(appointment.startDateTime.toDate()) : selectedDay,
              clinic: appointment?.clinic ?? clinicParam ?? undefined,
            }}
          />
          {selectedType && date && clinic && assignee && startTime && endTime && location && selectedAssigneeData && (
            <div className='px-4 py-2 border-t'>
              <SharedButton
                labelKey='calendar.add_edit_appointment.preview_route'
                onClick={() => {
                  const newAppointmentAddress =
                    location === AppointmentLocation.HOME
                      ? patientData?.address
                      : clinicsState?.data.find((c) => c.uid === clinic)?.address;
                  if (newAppointmentAddress) {
                    dialog?.openDialog(
                      <TimelinePreviewDialog
                        appointments={appointments}
                        resource={selectedAssigneeData}
                        newAppointment={{
                          isNew: true,
                          uid: appointmentUid ?? 'new',
                          patientName: patientData!.fullName,
                          appointmentAddress: newAppointmentAddress,
                          startDateTime: getTimestampFromDateAndTimeString(date, startTime.format('HH:mm')),
                          endDateTime: getTimestampFromDateAndTimeString(date, endTime.format('HH:mm')),
                          clinic,
                          location,
                        }}
                      />
                    );
                  } else {
                    message.error(t('calendar.add_edit_appointment.timeline_preview.no_address'));
                  }
                }}
                appearance='link'
                fullWidth
              />
            </div>
          )}
        </div>

        <div
          className={clsx('grow flex overflow-hidden', !mobileShowCalendar && 'hidden md:flex')}
          ref={calendarWrapperRef}
        >
          {!selectedType || !date || !clinic ? (
            <div className='p-4 w-full grow'>
              <Alert
                className='self-start'
                message={t('calendar.add_edit_appointment.select_initial_fields')}
                type='info'
                showIcon
              />
            </div>
          ) : (
            <SharedCalendar
              filters={{}}
              calendarWrapperRef={calendarWrapperRef}
              startHour={startHour}
              loading={loadingCalendarAppointments || loadingCalendarUnavailability}
              timeSlots={getAppointment60MinuteTimeSlots(startHour, endHour)}
              appointments={appointments}
              unavailability={unavailability}
              people={assigneeList ?? []}
              currentDate={date}
              changeDate={(newDate) => {
                form.setFieldValue('date', dayjs(newDate));
                setWatchedFormValues((prev) => ({ ...prev, date: dayjs(newDate) }));
              }}
              newAppointment={
                date && startTime && endTime && startTime < endTime
                  ? { date, start: startTime, end: endTime }
                  : undefined
              }
              highlightedPerson={assignee}
              highlightedClinic={clinic}
              minDate={selectedDay}
              showAppointmentMenu={false}
            />
          )}
        </div>

        <div className='p-4 border-t md:hidden'>
          <SharedButton
            onClick={() => setMobileShowCalendar(!mobileShowCalendar)}
            fullWidth
            labelKey={
              mobileShowCalendar
                ? 'calendar.add_edit_appointment.hide_calendar'
                : 'calendar.add_edit_appointment.show_calendar'
            }
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <ProgressBar />
      <div className='flex py-4 h-full'>
        <SkeletonElement width='30%' height='100%' />
        <SkeletonElement width='70%' height='100%' className='ml-4' />
      </div>
    </>
  );
};

export default AddEditAppointment;
