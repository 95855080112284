import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { IResourceDao } from 'core/api/types';
import { ResourcesApiService } from 'core/api';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { useAComponentPortal } from 'core/providers/component-portal-provider';
import { App, Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';
import { useSelector } from 'react-redux';

interface IAddResourcesFormOutput {
  name: string;
  isClinicResource: boolean;
  [key: `quantity_${string}`]: number;
}

interface IAddEditResourcesDialog {
  existing?: IResourceDao;
  tableKey: string;
}

const AddEditResourcesDialog = ({ existing, tableKey }: IAddEditResourcesDialog) => {
  const { userData } = useUserState();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const creating = !existing;
  const table = useAComponentPortal(tableKey);
  const isClinicResource = useWatch('isClinicResource', form);
  const clinicState = useSelector(OrganisationSettingsSlice.selectClinics);

  const clinics = clinicState?.data.filter((clinic) => !clinic.deleted);

  const AddResourcesFormFields: ISharedField[] = [
    {
      fieldKey: 'name',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('dialog.add_edit_resources.name'),
      required: true,
    },
    {
      fieldKey: 'isClinicResource',
      control: ControlType.Switch,
      label: t('dialog.add_edit_resources.is_clinic_resource'),
      required: true,
    },
    {
      fieldKey: `quantity_${userData?.organisationUid}`,
      control: ControlType.NumberField,
      label: t('dialog.add_edit_resources.quantity'),
      required: true,
      hidden: isClinicResource,
      min: 0,
    },
    ...(clinics?.map<ISharedField>((clinic) => ({
      fieldKey: `quantity_${clinic.uid}`,
      control: ControlType.NumberField,
      label: t('dialog.add_edit_resources.clinic_quantity', { clinic: clinic.name }),
      required: true,
      hidden: !isClinicResource,
      min: 0,
    })) ?? []),
  ];

  const submit = async (data: IAddResourcesFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        name: data.name,
        isClinicResource: data.isClinicResource,
        quantity: {
          [userData.organisationUid]: data[`quantity_${userData.organisationUid}`] || 0,
          ...((data.isClinicResource &&
            clinics?.reduce(
              (acc, clinic) => ({
                ...acc,
                [clinic.uid]: data[`quantity_${clinic.uid}`],
              }),
              {}
            )) ??
            {}),
        },
        updated: userTimestamp,
        organisationUid: userData.organisationUid,
      };
      if (creating) {
        await ResourcesApiService.set({
          ...basePayload,
          uid: uuidv4(),
          created: userTimestamp,
        });
        message.success(t('dialog.add_edit_resources.create.success.description'));
      } else {
        await ResourcesApiService.update(existing.uid, {
          ...basePayload,
        });
        message.success(t('dialog.add_edit_resources.edit.success.description'));
      }
      dialog?.closeDialog();
      table?.exposedFunction();
    } catch (err) {
      message.error(
        t(
          existing
            ? 'dialog.add_edit_resources.edit.error.description'
            : 'dialog.add_edit_resources.create.error.description'
        )
      );
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddResourcesFormOutput>
        formInstance={form}
        onFinish={submit}
        fields={AddResourcesFormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-resources'
        existingValue={{
          ...existing,
          isClinicResource: existing?.isClinicResource || false,
          ...(userData?.organisationUid && {
            [`quantity_${userData?.organisationUid}`]: existing?.quantity[userData?.organisationUid],
          }),
          ...(clinics?.reduce(
            (acc, clinic) => ({
              ...acc,
              [`quantity_${clinic.uid}`]: existing?.quantity[`${clinic.uid}`],
            }),
            {}
          ) ?? {}),
        }}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'dialog.add_edit_resources.create.title' : 'dialog.add_edit_resources.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditResourcesDialog;
