import { ControlType } from 'core/enums/control-type';
import { Checkbox, ColorPicker, DatePicker, Input, InputNumber, Radio, Select, Switch, TimePicker } from 'antd';
import { ISharedField } from './shared-fields.interface';
import AddressSearch from './address/address-search';
import { InputType } from 'core/enums/input-type';
import SharedFileUpload from './file-upload';
import clsx from 'clsx';
import ReactQuill from 'react-quill';
import FormUserSearch from './form-user-search';
import SharedSignatureField from './signature';
import TextAreaWithSnippets from './text-area-with-snippets';
const { RangePicker } = DatePicker;

export const getField = (field: ISharedField) => {
  switch (field.control) {
    case ControlType.TextField:
      return field.type === InputType.Password ? <Input.Password /> : <Input placeholder={field.placeholder} />;
    case ControlType.TextArea:
      return <TextAreaWithSnippets {...field} />;
    case ControlType.NumberField:
      return (
        <InputNumber<number>
          min={field.min}
          max={field.max}
          parser={field.parser}
          formatter={field.formatter}
          className={clsx(field.fullWidth && 'w-full')}
        />
      );
    case ControlType.ColorPicker:
      return (
        <ColorPicker
          showText={field.showText}
          defaultValue={field.defaultValue}
          format={'hex'}
          disabledAlpha={field.disabledAlpha}
        />
      );
    case ControlType.Address:
      return <AddressSearch placeholder={field.placeholder} showManualMode={field.showManualMode} />;
    case ControlType.Select:
      return (
        <Select
          placeholder={field.placeholder}
          options={field.options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))}
          allowClear={field.allowClear}
          mode={field.mode}
          disabled={field.disabled}
          showSearch={field.showSearch ?? true}
          filterOption={(input, option) => option?.label.toLowerCase().includes(input.toLowerCase()) ?? false}
          notFoundContent={field.notFoundContent}
        />
      );
    case ControlType.File:
      return (
        <SharedFileUpload
          acceptedFileTypes={field.acceptedFileTypes}
          maxCount={field.maxCount}
          maxFileSize={field.maxFileSize}
        />
      );
    case ControlType.DatePicker:
      return (
        <DatePicker
          presets={field.presets}
          minDate={field.minDate}
          maxDate={field.maxDate}
          className={clsx(field.fullWidth && 'w-full')}
          format={'DD/MM/YYYY'}
          disabledDate={field.disabledDate}
        />
      );
    case ControlType.CheckboxGroup:
      return <Checkbox.Group options={field.options} className={field.className} />;
    case ControlType.SelectMultipleTags:
      return <Select mode='tags' onChange={field.onChange} />;
    case ControlType.Switch:
      return <Switch />;
    case ControlType.RadioButton:
      return <Radio.Group options={field.options} optionType='button' buttonStyle='solid' />;
    case ControlType.DateRangePicker:
      return (
        <RangePicker
          minDate={field.minDate}
          maxDate={field.maxDate}
          className={clsx(field.fullWidth && 'w-full')}
          format={'DD/MM/YYYY'}
          allowClear={field.allowClear}
          minuteStep={field.minuteStep}
          showTime={field.showTime}
          disabledDate={field.disabledDate}
          disabledTime={field.disabledTime}
          showSecond={false}
          placeholder={field.placeholder}
          presets={field.presets}
        />
      );
    case ControlType.RichText:
      return <ReactQuill />;
    case ControlType.UserSearch:
      return <FormUserSearch />;
    case ControlType.Signature:
      return <SharedSignatureField disabled={field.disabled} dataUrl={field.dataUrl} onClear={field.onClear} />;
    case ControlType.TimePicker:
      return (
        <TimePicker
          format={'HH:mm'}
          {...field}
          hideDisabledOptions
          className={clsx(field.fullWidth && 'w-full')}
          needConfirm={false}
          showNow={false}
        />
      );
    default:
      throw new Error('Control type not found');
  }
};
