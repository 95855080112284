import { IStockDao } from 'core/api/types/stock.interface';
import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { Outlet } from 'react-router-dom';

interface IStockSelectionContext {
  selectedStock: IStockDao[];
  setSelectedStock: Dispatch<SetStateAction<IStockDao[]>>;
}

export const StockSelectionContext = createContext<IStockSelectionContext>({
  selectedStock: [],
  setSelectedStock: () => {},
});

export const StockSelectionProvider = () => {
  const [selectedStock, setSelectedStock] = useState<IStockDao[]>([]);
  return (
    <StockSelectionContext.Provider value={{ selectedStock, setSelectedStock }}>
      <Outlet />
    </StockSelectionContext.Provider>
  );
};
