import { App, Tag } from 'antd';
import { AppointmentTypesApiService } from 'core/api';
import { IAppointmentTypeDao } from 'core/api/types';
import { Unsubscribe } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedSpinner from 'shared/spinner/spinner';
import AppointmentTypeDetailOutcomes from './appointment-type-outcomes';
import AppointmentTypeAssignableUsers from './appointment-type-assignable-users';
import AppointmentTypeReminders from './appointment-type-reminders';
import AppointmentTypeCommunicationsDetails from './appointment-type-communication-details';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import SharedButton from 'shared/button/button';
import AddEditAppointmentTypeCommunicationDialog from './add-edit-appointment-type-communication-dialog';
import { useDialog } from 'core/providers/dialog-provider';
import AddEditAppointmentTypeDialog from '../add-edit-appointment-type-dialog';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';

const AppointmentTypeDetail = () => {
  const { uid } = useParams();
  const [appointmentType, setAppointmentType] = useState<IAppointmentTypeDao>();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dialog = useDialog();
  const { userData } = useUserState();
  const resources = useSelector(OrganisationSettingsSlice.selectResources);

  const handleError = useCallback(() => {
    message.error(t('appointment_types.appointment_type_detail.error'));
  }, [message, t]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (!uid) {
      navigate('/organisation-settings/appointment-types');
    } else {
      unsubscribe = AppointmentTypesApiService.onDocSnapshot(
        uid,
        (snap) => {
          if (snap.exists()) {
            setAppointmentType(snap.data());
          } else {
            handleError();
          }
        },
        () => handleError()
      );
    }
    return () => {
      unsubscribe();
    };
  }, [handleError, navigate, uid]);

  const details = [
    {
      key: 'name',
      value: appointmentType?.name,
      label: t('appointment_types.appointment_type_detail.name'),
    },
    {
      key: 'colour',
      value: appointmentType?.colour,
      label: t('appointment_types.appointment_type_detail.colour'),
    },
    {
      key: 'duration',
      value: appointmentType?.duration,
      label: t('appointment_types.appointment_type_detail.duration'),
    },
    {
      key: 'salesOpportunity',
      value: appointmentType?.salesOpportunity ? t('common.yes') : t('common.no'),
      label: t('appointment_types.appointment_type_detail.sales_opportunity'),
    },
    {
      key: 'showInBookingWidget',
      value: appointmentType?.showInBookingWidget ? t('common.yes') : t('common.no'),
      label: t('appointment_types.appointment_type_detail.show_in_booking_widget'),
    },
    {
      key: 'requiredResources',
      value: appointmentType?.requiredResources,
      label: t('appointment_types.appointment_type_detail.resources'),
    },
  ];

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.APPOINTMENT_TYPES_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='appointment_types.appointment_type_detail.header.delete'
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={async () => {
                    await AppointmentTypesApiService.update(appointmentType?.uid!, {
                      deleted: true,
                      updated: getActionTimestampFromUser(userData),
                    });
                    navigate('/organisation-settings/appointment-types');
                  }}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('appointment_types.appointment_type_detail.delete.title')}
                  textContent={t('appointment_types.appointment_type_detail.delete.content')}
                  successMessage={t('appointment_types.appointment_type_detail.delete.success')}
                  errorMessage={t('appointment_types.appointment_type_detail.delete.error')}
                />
              )
            }
            danger
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addUser',
    },
  ];

  return (
    <>
      {!appointmentType ? (
        <div className='py-8 flex justify-center items-center h-[306px]'>
          <SharedSpinner />
        </div>
      ) : (
        <>
          <SharedPageHeader title={appointmentType.name} showBack actions={headerActions} />
          <SharedCard
            title={t('appointment_types.appointment_type_detail.details')}
            innerClassName='flex flex-col space-y-4 p-4'
            extra={
              <SharedElementPermissionGuard
                requiredPermissions={[[Permission.APPOINTMENT_TYPES_UPDATE], [Permission.ORGANISATION_OWNER]]}
              >
                <SharedButton
                  appearance='link'
                  primaryOverride
                  labelKey='common.edit'
                  onClick={() =>
                    dialog?.openDialog(<AddEditAppointmentTypeDialog appointmentTypeData={appointmentType} />)
                  }
                />
              </SharedElementPermissionGuard>
            }
          >
            {details.map((detail) => {
              const label = <p className='text-xs opacity-70'>{detail.label}</p>;
              switch (detail.key) {
                case 'colour':
                  return (
                    <div key={detail.key}>
                      {label}
                      <div className='flex space-x-1.5 items-center'>
                        <div className='h-3 w-3 rounded-full' style={{ backgroundColor: detail.value as string }} />
                        <p>{detail.value}</p>
                      </div>
                    </div>
                  );
                case 'requiredResources':
                  return (
                    <div key={detail.key}>
                      {label}
                      <ul className='flex flex-wrap pt-1'>
                        {detail.value && (detail.value as string[]).length ? (
                          (detail.value as string[]).map((resource) => (
                            <Tag className='mr-1 mb-1' key={resource}>
                              {resources?.data.find((res) => res.uid === resource)?.name}
                            </Tag>
                          ))
                        ) : (
                          <p className='text-gray-400'>
                            {t('appointment_types.appointment_type_detail.resources.no_resources')}
                          </p>
                        )}
                      </ul>
                    </div>
                  );
                default:
                  return (
                    <div key={detail.key}>
                      {label}
                      <p>{detail.value}</p>
                    </div>
                  );
              }
            })}
          </SharedCard>
          <AppointmentTypeDetailOutcomes {...appointmentType} />
          <AppointmentTypeAssignableUsers {...appointmentType} />
          <AppointmentTypeReminders {...appointmentType} />
          <SharedCard
            title={t('appointment_types.appointment_type_detail.confirmation')}
            innerClassName='flex space-x-4 p-4'
            extra={
              <SharedElementPermissionGuard
                requiredPermissions={[[Permission.APPOINTMENT_TYPES_UPDATE], [Permission.ORGANISATION_OWNER]]}
              >
                <SharedButton
                  appearance='link'
                  primaryOverride
                  labelKey='common.edit'
                  onClick={() =>
                    dialog?.openDialog(
                      <AddEditAppointmentTypeCommunicationDialog
                        appointmentType={appointmentType}
                        type='confirmation'
                        confirmation={appointmentType.confirmation}
                      />
                    )
                  }
                />
              </SharedElementPermissionGuard>
            }
          >
            <AppointmentTypeCommunicationsDetails {...appointmentType.confirmation} />
          </SharedCard>
        </>
      )}
    </>
  );
};

export default AppointmentTypeDetail;
