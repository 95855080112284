import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { useEffect, useState } from 'react';
import { useDialog } from 'core/providers/dialog-provider';
import { ControlType } from 'core/enums/control-type';
import { App, Form } from 'antd';
import { getAppointment15MinuteTimeSlots, getTimestampFromDateAndTimeString } from 'shared/helpers/appointment-helpers';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { useWatch } from 'antd/es/form/Form';
import { RepeatFrequency, RepeatFrequencyData, RepeatFrequencyOptions } from 'core/constants/repeat-frequency';
import {
  UnavailabilityType,
  UnavailabilityTypeData,
  UnavailabilityTypeOptions,
} from 'core/constants/unavailability-type';
import { InputType } from 'core/enums/input-type';
import { HolidayAndUnavailabilityApiService } from 'core/api';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { IUserSearchResult } from 'core/api/types';
import { v4 as uuidv4 } from 'uuid';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { deleteField } from 'firebase/firestore';
import { useAComponentPortal } from 'core/providers/component-portal-provider';

interface IAddEditHolidayUnavailabilityDialog {
  tableKey: string;
  existingItemUid?: string;
  initialDate?: Dayjs;
}

interface IAddEditHolidayUnavailabilityFormOutput {
  assignee: IUserSearchResult;
  type: UnavailabilityType;
  description?: string;
  dateRange: [Dayjs, Dayjs];
  repeats: boolean;
  repeatFrequency?: RepeatFrequency;
  startTime: string;
  endTime: string;
}

const AddEditHolidayUnavailabilityDialog = ({
  tableKey,
  initialDate,
  existingItemUid,
}: IAddEditHolidayUnavailabilityDialog) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const [form] = Form.useForm();
  const { organisationData, userData } = useUserState();
  const repeats = useWatch('repeats', form);
  const startTime = useWatch('startTime', form);
  const endTime = useWatch('endTime', form);
  const { message } = App.useApp();
  const table = useAComponentPortal(tableKey);
  const calendarStart = dayjs(organisationData?.calendar.startTime.toDate());
  const calendarEnd = dayjs(organisationData?.calendar.endTime.toDate());
  const allTimeOptions = getAppointment15MinuteTimeSlots(calendarStart, calendarEnd).map((slot) => ({
    value: slot,
    label: slot,
  }));
  const startTimeOptions = allTimeOptions.filter(({ value }) => !(endTime && value >= endTime));
  const endTimeOptions = allTimeOptions.filter(({ value }) => !(startTime && value <= startTime));
  const [loadingData, setLoadingData] = useState(true);
  const [existingData, setExistingData] = useState<IHolidayAndUnavailabilityDao>();

  const fields: ISharedField[] = [
    {
      fieldKey: 'assignee',
      control: ControlType.UserSearch,
      label: t('holidays_and_unavailability.add_edit_dialog.form.assignee'),
      required: true,
    },
    {
      fieldKey: 'type',
      control: ControlType.Select,
      options: UnavailabilityTypeOptions.map((option) => {
        const data = UnavailabilityTypeData[option];
        return { value: data.value, label: t(data.translationLabelKey) };
      }),
      label: t('holidays_and_unavailability.add_edit_dialog.form.type'),
      required: true,
    },
    {
      fieldKey: 'description',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('holidays_and_unavailability.add_edit_dialog.form.description'),
      required: false,
    },
    {
      fieldKey: 'repeats',
      control: ControlType.Switch,
      label: t('holidays_and_unavailability.add_edit_dialog.form.repeats'),
      required: true,
    },
    {
      fieldKey: 'repeatFrequency',
      control: ControlType.Select,
      options: RepeatFrequencyOptions.map((option) => {
        const data = RepeatFrequencyData[option];
        return { value: data.value, label: t(data.translationLabelKey) };
      }),
      label: t('holidays_and_unavailability.add_edit_dialog.form.repeat_frequency'),
      required: true,
      hidden: !repeats,
    },
    {
      fieldKey: 'dateRange',
      control: ControlType.DateRangePicker,
      label: repeats
        ? t('holidays_and_unavailability.add_edit_dialog.form.repeat_date_range')
        : t('holidays_and_unavailability.add_edit_dialog.form.date_range'),
      placeholder: repeats
        ? [
            t('holidays_and_unavailability.add_edit_dialog.form.date_range.repeat_start'),
            t('holidays_and_unavailability.add_edit_dialog.form.date_range.repeat_until'),
          ]
        : undefined,
      required: true,
      fullWidth: true,
      minDate: dayjs(),
    },
    {
      fieldKey: 'startTime',
      control: ControlType.Select,
      options: startTimeOptions,
      label: t('holidays_and_unavailability.add_edit_dialog.form.start_time'),
      required: true,
    },
    {
      fieldKey: 'endTime',
      control: ControlType.Select,
      options: endTimeOptions,
      label: t('holidays_and_unavailability.add_edit_dialog.form.end_time'),
      required: true,
    },
  ];

  useEffect(() => {
    const getExistingData = async (uid: string) => {
      try {
        setLoadingData(true);
        const snap = await HolidayAndUnavailabilityApiService.get(uid);
        const data = snap.data();
        if (!data) {
          throw new Error('No data found');
        }
        setExistingData(data);
      } catch (error) {
        sentryCaptureException(error, 'Get existing data for holiday/unavailability dialog', userData);
      } finally {
        setLoadingData(false);
      }
    };
    if (existingItemUid) {
      getExistingData(existingItemUid);
    } else {
      setLoadingData(false);
    }
  }, [existingItemUid, userData]);

  const submit = async (data: IAddEditHolidayUnavailabilityFormOutput) => {
    try {
      setSubmitting(true);
      const userTimestamp = getActionTimestampFromUser(userData);
      const [startDate, endDate] = data.dateRange;
      const basePayload = {
        startDateTime: getTimestampFromDateAndTimeString(startDate, data.startTime),
        endDateTime: getTimestampFromDateAndTimeString(endDate, data.endTime),
        type: data.type,
        assignee: {
          fullName: data.assignee.fullName,
          uid: data.assignee.objectID,
        },
        repeat: {
          isRepeating: data.repeats,
          ...(data.repeats && {
            frequency: data.repeatFrequency,
            until: getTimestampFromDateAndTimeString(endDate, '23:59'),
          }),
        },
        updated: userTimestamp,
      };
      if (existingItemUid) {
        await HolidayAndUnavailabilityApiService.update(existingItemUid, {
          ...basePayload,
          description: data.description ? data.description : deleteField(),
        });
      } else {
        await HolidayAndUnavailabilityApiService.set({
          ...basePayload,
          organisationUid: userData?.organisationUid!,
          uid: uuidv4(),
          created: userTimestamp,
          ...(data.description && { description: data.description }),
        });
      }
      dialog?.closeDialog();
      message.success(
        t(
          existingItemUid
            ? 'holidays_and_unavailability.add_edit_dialog.edit.success'
            : 'holidays_and_unavailability.add_edit_dialog.create.success'
        )
      );
      table?.exposedFunction();
    } catch (error) {
      setSubmitting(false);
      message.error(
        t(
          existingItemUid
            ? 'holidays_and_unavailability.add_edit_dialog.edit.error'
            : 'holidays_and_unavailability.add_edit_dialog.create.error'
        )
      );
      sentryCaptureException(error, 'Add/edit holiday/unavailability dialog submit', userData);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddEditHolidayUnavailabilityFormOutput>
        initializing={loadingData}
        className='p-4 overflow-y-auto'
        formInstance={form}
        onFinish={submit}
        fields={fields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-holiday-unavailability-form'
        existingValue={
          existingData
            ? {
                repeats: existingData.repeat.isRepeating,
                repeatFrequency: existingData.repeat.frequency,
                assignee: {
                  fullName: existingData.assignee.fullName,
                  objectID: existingData.assignee.uid,
                },
                type: existingData.type,
                description: existingData.description,
                dateRange: [dayjs(existingData.startDateTime.toDate()), dayjs(existingData.endDateTime.toDate())],
                startTime: dayjs(existingData.startDateTime.toDate()).format('HH:mm'),
                endTime: dayjs(existingData.endDateTime.toDate()).format('HH:mm'),
              }
            : { repeats: false, dateRange: [initialDate, initialDate] }
        }
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(
        existingItemUid
          ? 'holidays_and_unavailability.add_edit_dialog.edit.title'
          : 'holidays_and_unavailability.add_edit_dialog.create.title'
      )}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditHolidayUnavailabilityDialog;
