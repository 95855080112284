import { IUserDao } from 'core/api/types';
import { CollectionID } from 'core/constants/collection-id';
import { useDialog } from 'core/providers/dialog-provider';
import { useTheme } from 'core/providers/theme-provider';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import AdminAddEditUserDialog from './admin-add-edit-user-dialog';
import { useTranslation } from 'react-i18next';
import AdminDeleteUserDialog from './admin-delete-user-dialog';
import { OrganisationsSlice } from '../organisations/admin-organisations-slice';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { IQueryOrder } from 'core/common/types';

const UsersTableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'admin.users_list.table.header.user',
    key: 'user',
    contentTemplateId: 'user',
    width: 600,
  },
  { labelKey: 'admin.users_list.table.header.user_type', key: 'userType', contentTemplateId: 'userType' },
  { labelKey: 'common.organisation', key: 'organisationUid', contentTemplateId: 'organisation' },
  { key: 'action', contentTemplateId: 'actions' },
];

const AdminUsersList = () => {
  const { primary } = useTheme();
  const dialog = useDialog();
  const { t } = useTranslation();
  const tableKey = 'admin.users_list.table';
  const { data: organisations } = useSelector(OrganisationsSlice.selectState);

  const userTemplate = (user: IUserDao) => {
    return (
      <div className='flex items-center'>
        <div
          className='h-10 w-10 rounded-full mr-3 flex items-center justify-center text-white body-sm'
          style={{ backgroundColor: primary.bg }}
        >
          {user.fullName.charAt(0).toUpperCase()}
        </div>
        <div className='flex flex-col body-sm'>
          <p>{user.fullName}</p>
          <p className='text-gray-400'>{user.emailAddress}</p>
        </div>
      </div>
    );
  };

  const organisationTemplate = (user: IUserDao) => {
    const organisation = organisations.find((org) => org.uid === user.organisationUid);
    return organisation ? organisation.name : t('common.na');
  };

  const userTypeTemplate = (user: IUserDao) => {
    let userTypeTranslationKey = 'common.user_type.member';
    if (user.isHearLinkAdmin) {
      userTypeTranslationKey = 'common.user_type.hearlink_admin';
    }
    if (user.isOrgOwner) {
      userTypeTranslationKey = 'common.user_type.organisation_owner';
    }
    return t(userTypeTranslationKey);
  };

  const actionTemplate = (user: IUserDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedButton
          onClick={() => dialog?.openDialog(<AdminAddEditUserDialog user={user} tableKey={tableKey} />)}
          type='button'
          appearance='link'
          labelKey='common.edit'
          primaryOverride
        />
        <SharedButton
          onClick={() => dialog?.openDialog(<AdminDeleteUserDialog user={user} tableKey={tableKey} />)}
          type='button'
          appearance='link'
          danger
          labelKey='common.delete'
        />
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: userTemplate,
      id: 'user',
    },
    {
      template: userTypeTemplate,
      id: 'userType',
    },
    {
      template: organisationTemplate,
      id: 'organisation',
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  const queryOrder = useMemo((): IQueryOrder => ['created.at', 'desc'], []);

  return (
    <>
      <SharedPageHeader
        title={t('common.users')}
        actions={[
          {
            element: (
              <SharedButton
                labelKey='admin.users_list.header.add_user'
                appearance='primary'
                onClick={() => dialog?.openDialog(<AdminAddEditUserDialog tableKey={tableKey} />)}
              />
            ),
            key: 'addUser',
          },
        ]}
      />
      <SharedCard>
        <SharedPaginatedTable
          collectionId={CollectionID.USERS}
          queryOrder={queryOrder}
          tableConfig={{
            columns: UsersTableColumns,
            contentTemplates,
          }}
          errorMessageKey='admin.users_list.table.error.description'
          tableKey={tableKey}
        />
      </SharedCard>
    </>
  );
};

export default AdminUsersList;
