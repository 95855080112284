import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { App } from 'antd';
import { INoteSnippetDao } from 'core/api/types/organisations.interface';
import { InputType } from 'core/enums/input-type';
import { OrganisationsApiService } from 'core/api';
import { arrayUnion } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

interface IAddEditNoteSnippetDialogFormOutput {
  trigger: string;
  snippet: string;
}

interface IAddEditNoteSnippetDialog {
  existing?: INoteSnippetDao;
}
const AddEditNoteSnippetDialog = ({ existing }: IAddEditNoteSnippetDialog) => {
  const { userData, organisationData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const creating = !existing;

  const submit = async (data: IAddEditNoteSnippetDialogFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        ...data,
        updated: userTimestamp,
      };
      if (!creating) {
        await OrganisationsApiService.update(userData.organisationUid, {
          noteSnippets: (organisationData?.noteSnippets ?? []).map((snippet) =>
            snippet.uid === existing?.uid ? { ...basePayload, uid: existing.uid } : snippet
          ),
          updated: userTimestamp,
        });
      } else {
        await OrganisationsApiService.update(userData.organisationUid, {
          noteSnippets: arrayUnion({ ...basePayload, created: userTimestamp, uid: uuidv4() }),
          updated: userTimestamp,
        });
      }
      message.success(
        t(
          existing
            ? 'form_settings.note_snippets.add_edit_snippet.edit.success'
            : 'form_settings.note_snippets.add_edit_snippet.create.success'
        )
      );
      dialog?.closeDialog();
    } catch (err) {
      message.error(
        t(
          existing
            ? 'form_settings.note_snippets.add_edit_snippet.edit.error'
            : 'form_settings.note_snippets.add_edit_snippet.create.error'
        )
      );
      setSubmitting(false);
    }
  };

  const formFields: ISharedField[] = [
    {
      fieldKey: 'trigger',
      control: ControlType.TextField,
      type: InputType.Text,
      required: true,
      label: t('form_settings.note_snippets.add_edit_snippet.form.trigger'),
      customRules: [
        {
          validator: async (rule, value?: string) => {
            if (value && /\s/g.test(value)) {
              throw new Error(t('form_settings.note_snippets.add_edit_snippet.form.trigger.no_spaces'));
            }
          },
        },
      ],
    },
    {
      fieldKey: 'snippet',
      control: ControlType.TextArea,
      rows: 4,
      required: true,
      label: t('form_settings.note_snippets.add_edit_snippet.form.snippet'),
    },
  ];

  const customContent = () => {
    return (
      <SharedForm<IAddEditNoteSnippetDialogFormOutput>
        className='p-4 overflow-y-auto'
        onFinish={submit}
        fields={formFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='form_settings.note_snippets.add_edit_snippet'
        existingValue={existing}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(
        creating
          ? 'form_settings.note_snippets.add_edit_snippet.create.title'
          : 'form_settings.note_snippets.add_edit_snippet.edit.title'
      )}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditNoteSnippetDialog;
