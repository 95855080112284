import { AnimationState } from 'core/constants/animation-state';
import { createContext, ReactElement, useCallback, useContext, useState } from 'react';
import { motion } from 'framer-motion';
import FadeWrapper from 'shared/animation/fade-wrapper';

interface IDialogContext {
  showDialog: boolean;
  openDialog: (dialogTemplate: ReactElement) => void;
  closeDialog: () => void;
  replaceDialog: (dialogTemplate: ReactElement) => void;
  setDialogTemplate: (dialogTemplate: ReactElement) => void;
}

export const DialogContext = createContext<IDialogContext>({
  showDialog: false,
  openDialog: () => {},
  closeDialog: () => {},
  replaceDialog: () => {},
  setDialogTemplate: () => {},
});

export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }: any) => {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTemplate, setDialogTemplate] = useState<ReactElement | null>(null);
  const [animationState, setAnimationState] = useState(AnimationState.ENTERING);

  const openDialog = useCallback((dialogTemplate: ReactElement) => {
    setShowDialog(true);
    setDialogTemplate(dialogTemplate);
  }, []);

  const replaceDialog = useCallback(
    (dialogTemplate: ReactElement) => {
      setAnimationState(AnimationState.EXITING);
      setTimeout(() => {
        setShowDialog(false);
        setDialogTemplate(null);
        setAnimationState(AnimationState.ENTERING);
        setTimeout(() => {
          openDialog(dialogTemplate);
        }, 10);
      }, 200);
    },
    [openDialog]
  );

  const closeDialog = useCallback(() => {
    setAnimationState(AnimationState.EXITING);
    setTimeout(() => {
      setShowDialog(false);
      setDialogTemplate(null);
      setAnimationState(AnimationState.ENTERING);
    }, 200);
  }, []);

  return (
    <DialogContext.Provider value={{ showDialog, openDialog, closeDialog, replaceDialog, setDialogTemplate }}>
      <div className='z-40'>
        {showDialog && (
          <FadeWrapper
            animationState={animationState}
            className='w-full h-full fixed top-0 left-0 bg-black bg-opacity-25 z-50 flex justify-center items-center p-4'
          >
            <motion.div
              className='max-h-full flex justify-center w-full'
              initial={{ translateY: 20 }}
              animate={{ translateY: animationState === AnimationState.EXITING ? 20 : 0 }}
              transition={{ duration: 0.2 }}
            >
              {dialogTemplate}
            </motion.div>
          </FadeWrapper>
        )}
      </div>
      {children}
    </DialogContext.Provider>
  );
};
