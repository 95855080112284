export enum StockStatus {
  NEEDS_ORDERING = 'needsOrdering',
  ORDERED = 'ordered',
  IN_STOCK = 'inStock',
  RETURNED_TO_SUPPLIER = 'returnedToSupplier',
  ON_TRIAL = 'onTrial',
  SOLD = 'sold',
  LOST = 'lost',
}

export const StockStatusData = {
  [StockStatus.NEEDS_ORDERING]: {
    value: StockStatus.NEEDS_ORDERING,
    translationLabelKey: 'stock_management.stock_status.needs_ordering',
    color: 'red',
  },
  [StockStatus.ORDERED]: {
    value: StockStatus.ORDERED,
    translationLabelKey: 'stock_management.stock_status.ordered',
    color: 'orange',
  },
  [StockStatus.IN_STOCK]: {
    value: StockStatus.IN_STOCK,
    translationLabelKey: 'stock_management.stock_status.in_stock',
    color: 'green',
  },
  [StockStatus.ON_TRIAL]: {
    value: StockStatus.ON_TRIAL,
    translationLabelKey: 'stock_management.stock_status.on_trial',
    color: 'blue',
  },
  [StockStatus.SOLD]: {
    value: StockStatus.SOLD,
    translationLabelKey: 'stock_management.stock_status.allocated',
    color: 'green',
  },
  [StockStatus.RETURNED_TO_SUPPLIER]: {
    value: StockStatus.RETURNED_TO_SUPPLIER,
    translationLabelKey: 'stock_management.stock_status.returned_to_supplier',
    color: 'red',
  },
  [StockStatus.LOST]: {
    value: StockStatus.LOST,
    translationLabelKey: 'stock_management.stock_status.lost',
    color: 'red',
  },
};

export const StockStatusOptions = [StockStatus.NEEDS_ORDERING, StockStatus.ORDERED, StockStatus.IN_STOCK];
