import { Permission } from 'core/constants/permission';
import { INavbarOption } from '../../core/config/navbar-options';

export const OrganisationSettingsNavbarOptions: INavbarOption[] = [
  {
    labelKey: 'navigation.users',
    route: 'organisation-settings/users',
    requiredPermissions: [[Permission.USERS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.groups',
    route: 'organisation-settings/groups',
    requiredPermissions: [[Permission.GROUPS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.calendar',
    route: 'organisation-settings/calendar',
    requiredPermissions: [[Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.booking_widget_settings',
    route: 'organisation-settings/booking-widget-settings',
    requiredPermissions: [[Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.holidays_and_unavailability',
    route: 'organisation-settings/holidays-and-unavailability',
    requiredPermissions: [[Permission.HOLIDAYS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.finance',
    route: 'organisation-settings/finance',
    requiredPermissions: [[Permission.FINANCE_SETTINGS_UPDATE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.documents_and_images',
    route: 'organisation-settings/documents-and-images',
    requiredPermissions: [[Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.clinics',
    route: 'organisation-settings/clinics',
    requiredPermissions: [[Permission.CLINICS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.appointment_types',
    route: 'organisation-settings/appointment-types',
    requiredPermissions: [[Permission.APPOINTMENT_TYPES_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.lead_types',
    route: 'organisation-settings/lead-types',
    requiredPermissions: [[Permission.LEAD_TYPES_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.form_settings',
    route: 'organisation-settings/form-settings',
    requiredPermissions: [[Permission.FORM_SETTINGS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.products_and_services',
    route: 'organisation-settings/products-and-services',
    requiredPermissions: [[Permission.PRODUCTS_AND_SERVICES_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.audiometers',
    route: 'organisation-settings/audiometers',
    requiredPermissions: [[Permission.AUDIOMETERS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.resources',
    route: 'organisation-settings/resources',
    requiredPermissions: [[Permission.RESOURCES_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.workflow',
    route: 'organisation-settings/workflow',
    requiredPermissions: [[Permission.WORKFLOW_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.audit_log',
    route: 'organisation-settings/audit-log',
    requiredPermissions: [[Permission.AUDIT_LOG_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
];
