import { App, Tag } from 'antd';
import { CommunicationApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { ICommunicationDao } from 'core/api/types/communication.interface';
import { CommunicationMethodData } from 'core/constants/communication-method';
import { CommunicationStatusData } from 'core/constants/communication-status';
import { CommunicationTypeData } from 'core/constants/communication-type';
import { Permission } from 'core/constants/permission';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { Unsubscribe } from 'firebase/auth';
import { where, limit, orderBy } from 'firebase/firestore';
import { t } from 'i18next';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import AccessDenied from 'shared/permissions/denied';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const PatientOverviewLastCommunicationsWidget = (patient: IPatientDao) => {
  const { t } = useTranslation();
  return (
    <SharedCard title={t('patients.patient.patient_overview.last_communications_widget.title')}>
      <SharedElementPermissionGuard
        requiredPermissions={[[Permission.TRANSACTIONS_READ], [Permission.ORGANISATION_OWNER]]}
        replacement={<AccessDenied />}
      >
        <Widget {...patient} />
      </SharedElementPermissionGuard>
    </SharedCard>
  );
};

const Widget = (patient: IPatientDao) => {
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const [communications, setCommunications] = useState<ICommunicationDao[]>([]);
  const { userData } = useUserState();

  useEffect(() => {
    setCommunications([]);
  }, [patient.uid]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = CommunicationApiService.onCollectionSnapshot(
      (snap) => {
        setCommunications(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.patient_overview.last_communications_widget.error'));
        sentryCaptureException(error, 'Patient overview fetching last communications for widget', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('patientUid', '==', patient.uid),
        limit(3),
        orderBy('dateSent', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, userData]);
  return (
    <div>
      {loading ? (
        <div className='border-b last:border-0 p-4 flex items-center justify-between'>
          <div>
            <SkeletonElement height='16px' width='80px' />
            <SkeletonElement height='20px' width='180px' className='mt-1' />
          </div>
          <SkeletonElement height='22px' width='65px' />
        </div>
      ) : (
        <div>
          {communications.map((communication) => (
            <CommunicationTemplate key={communication.uid} {...communication} />
          ))}
          {communications.length === 0 && (
            <p className='text-center p-4 text-gray-300'>
              {t('patients.patient.patient_overview.last_communications_widget.no_communications')}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const CommunicationTemplate = (communication: ICommunicationDao) => {
  const { t } = useTranslation();
  const method = CommunicationMethodData[communication.method];
  const type = CommunicationTypeData[communication.type];
  const status = CommunicationStatusData[communication.status];
  return (
    <div className='border-b last:border-0 p-4 flex items-center justify-between'>
      <div>
        <p className='body-xs text-gray-400 col-span-3'>
          {dayjs(communication.dateSent.toDate()).format('DD/MM/YYYY')}
        </p>
        <p className='body-sm'>{`${t(method ? method.translationLabelKey : 'common.unknown')} - ${t(
          type ? type.translationLabelKey : 'common.unknown'
        )}`}</p>
      </div>

      <Tag color={status?.color ?? 'default'}>{t(status?.translationLabelKey ?? 'common.unknown')}</Tag>
    </div>
  );
};

export default PatientOverviewLastCommunicationsWidget;
