import { IAuditLogResource } from 'core/api/types';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SharedAuditLogResource = (resource: IAuditLogResource) => {
  const { t } = useTranslation();

  const linkWrappedContent = (link: string, innerContent: ReactNode) => {
    return <Link to={link}>{innerContent}</Link>;
  };

  const innerContent = (
    <>
      <p className='text-xs opacity-70'>{t(`audit_log.resource_type.${resource.type}`)}</p>
      <p>{resource.label}</p>
    </>
  );

  switch (resource.type) {
    case 'appointmentType':
      return linkWrappedContent('/organisation-settings/appointment-types', innerContent);
    case 'user':
      return linkWrappedContent('/organisation-settings/users', innerContent);
    case 'clinic':
      return linkWrappedContent('/organisation-settings/clinics', innerContent);
    case 'leadType':
      return linkWrappedContent('/organisation-settings/lead-types', innerContent);
    case 'patient':
      return linkWrappedContent(`/patients/${resource.uid}`, innerContent);
    case 'group':
      return linkWrappedContent(`/organisation-settings/groups/${resource.uid}`, innerContent);
    case 'repair':
    case 'order':
    case 'hearingTest':
    case 'appointment':
    case 'organisation':
    case 'stock':
    case 'transaction':
      return innerContent;
  }
};

export default SharedAuditLogResource;
