import { App } from 'antd';
import { PatientNotesApiService } from 'core/api';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { IPatientNoteDao } from 'core/api/types/patient-notes.interface';
import { IPatientDao } from 'core/api/types';

interface IAddEditPatientNoteDialog {
  patient: IPatientDao;
  patientNote?: IPatientNoteDao;
}

interface IAddEditPatientNoteFormOutput {
  note: string;
}

const AddEditPatientNoteDialog = ({ patient, patientNote }: IAddEditPatientNoteDialog) => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const { message } = App.useApp();

  const [submitting, setSubmitting] = useState(false);

  const AddEditPatientNoteDialogFields: ISharedField[] = [
    {
      fieldKey: 'note',
      control: ControlType.TextArea,
      rows: 6,
      label: t('patients.patient_note_dialog.note'),
      required: true,
    },
    {
      fieldKey: 'alert',
      control: ControlType.Switch,
      label: t('patients.patient_note_dialog.alert'),
      tooltip: t('patients.patient_note_dialog.alert_popover'),
      required: true,
    },
  ];

  const submit = async (data: IAddEditPatientNoteFormOutput) => {
    setSubmitting(true);

    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }

      const userTimestamp = getActionTimestampFromUser(userData);

      const basePayload = {
        ...data,
        updated: userTimestamp,
      };

      if (!patientNote) {
        await PatientNotesApiService.set({
          uid: uuidv4(),
          created: userTimestamp,
          patientUid: patient.uid,
          organisationUid: userData.organisationUid,
          ...basePayload,
        });
        message.success(t('dialog.add_edit_patient_note.create.success.description'));
      } else {
        await PatientNotesApiService.update(patientNote.uid, {
          ...basePayload,
        });
        message.success(t('dialog.add_edit_patient_note.edit.success.description'));
      }
      dialog?.closeDialog();
    } catch (error) {
      message.error(
        t(
          !patientNote
            ? 'dialog.add_edit_patient_note.create.error.description'
            : 'dialog.add_edit_patient_note.edit.error.description'
        )
      );
      setSubmitting(false);
      sentryCaptureException(error, !patientNote ? 'Create Patient Note' : 'Edit Patient Note', userData);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddEditPatientNoteFormOutput>
        onFinish={submit}
        fields={AddEditPatientNoteDialogFields}
        submitting={submitting}
        cancelButton={{
          onClick: () => dialog?.closeDialog(),
          appearance: 'text',
          labelKey: 'common.cancel',
        }}
        name='add-edit-patient-note-form'
        existingValue={{ ...patientNote, alert: patientNote?.alert ?? false }}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(!patientNote ? 'dialog.add_edit_patient_note.create.title' : 'dialog.add_edit_patient_note.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditPatientNoteDialog;
