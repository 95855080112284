import { App } from 'antd';
import { ReportingApiService } from 'core/api';
import { AppointmentConversionRateRow } from 'core/api/types/reporting.interface';
import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { stringToColour } from 'shared/helpers/color-helpers';
import ProgressBar from 'shared/progress-bar/progress-bar';
import AppointmentConversionRateReportTable from './appointment-conversation-report-table';
import AppointmentConversionRateReportFilters from './appointment-conversion-report-filters';
import useSessionStorageListener from 'shared/hooks/use-session-storage';
import SkeletonElement from 'shared/skeleton/skeleton-element';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface IAppointmentConversionRateReportFilterValues {
  groupBy: string;
  dateRange: [dayjs.Dayjs, dayjs.Dayjs];
  clinics?: string[];
  assignees?: string[];
  appointmentTypes?: string[];
  referralsPatient?: string[];
  referralsAppointment?: string[];
}

export type IAppointmentConversionRateReportSessionValues = Omit<
  IAppointmentConversionRateReportFilterValues,
  'dateRange'
> & {
  startDate: string;
  endDate: string;
};

export interface IGroup {
  label: string;
  value: string;
  color: string;
}

const AppointmentConversionRateReport = () => {
  const { t } = useTranslation();
  const filters = useSessionStorageListener<IAppointmentConversionRateReportSessionValues, true>(
    'reporting.appointments.conversion.filters',
    true
  );

  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const { clinics, appointmentTypes, leadTypes } = useSelector(
    OrganisationSettingsSlice.selectMultiple(['clinics', 'appointmentTypes', 'leadTypes'])
  );
  const [rows, setRows] = useState<AppointmentConversionRateRow[]>();
  const [filteredRows, setFilteredRows] = useState<AppointmentConversionRateRow[]>();
  const [includeCancelled, setIncludeCancelled] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!filters?.startDate || !filters?.endDate) return;
    const dateRange = [dayjs(filters.startDate), dayjs(filters.endDate)];
    try {
      const rows = await ReportingApiService.reportingAppointmentsConversionRate({
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
      });
      setRows(rows);
      setLoading(false);
    } catch (error) {
      message.error(t('reporting.hub.appointment_reports.conversion_rate.fetch_error'));
    }
  }, [filters?.endDate, filters?.startDate, message, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const defaultFilters = useMemo(
    () => ({
      dateRange: [dayjs().subtract(1, 'month'), dayjs()] as [dayjs.Dayjs, dayjs.Dayjs],
      groupBy: 'appointmentType',
    }),
    []
  );

  useEffect(() => {
    if (!filters) {
      sessionStorage.setItem(
        'reporting.appointments.conversion.filters',
        JSON.stringify({
          ...defaultFilters,
          startDate: defaultFilters.dateRange[0].format('YYYY-MM-DD'),
          endDate: defaultFilters.dateRange[1].format('YYYY-MM-DD'),
        })
      );
    } else {
      const filtered = rows?.filter((row) => {
        if (filters.clinics?.length && !filters.clinics.includes(row.clinic)) return false;
        if (filters.assignees?.length && !filters.assignees.includes(row.assignee.uid)) return false;
        if (filters.appointmentTypes?.length && !filters.appointmentTypes.includes(row.type)) return false;
        if (filters.referralsPatient?.length && !filters.referralsPatient.includes(row.patientReferral)) return false;
        if (filters.referralsAppointment?.length && !filters.referralsAppointment.includes(row.referral ?? 'unknown'))
          return false;
        return true;
      });
      setFilteredRows(filtered);
    }
  }, [defaultFilters, filters, rows]);

  const group = useMemo(() => {
    switch (filters?.groupBy) {
      case 'appointmentType':
        return appointmentTypes?.data.map((a) => ({
          label: a.name,
          value: a.uid,
          color: a.colour,
        }));
      case 'clinic':
        return clinics?.data.map((c) => ({
          label: c.name,
          value: c.uid,
          color: stringToColour(c.name),
        }));
      case 'assignee':
        return filteredRows?.reduce((acc: IGroup[], row) => {
          if (!acc.some((a) => a.value === row.assignee.uid)) {
            acc.push({
              label: row.assignee.fullName,
              value: row.assignee.uid,
              color: stringToColour(row.assignee.fullName),
            });
          }
          return acc;
        }, []);
      case 'patientReferral':
      case 'appointmentReferral':
        return leadTypes?.data.map((l) => ({
          label: l.name,
          value: l.uid,
          color: stringToColour(l.name),
        }));
    }
    return [];
  }, [appointmentTypes?.data, clinics?.data, filters?.groupBy, leadTypes?.data, filteredRows]);

  const groupFilter = useCallback(
    (row: AppointmentConversionRateRow, key: string) => {
      switch (filters?.groupBy) {
        case 'appointmentType':
          return row.type === key;
        case 'clinic':
          return row.clinic === key;
        case 'assignee':
          return row.assignee.uid === key;
        case 'patientReferral':
          return row.patientReferral === key;
        case 'appointmentReferral':
          return row.referral === key;
      }
      return true;
    },
    [filters?.groupBy]
  );

  const xValue = useMemo(() => {
    if (!filters?.startDate || !filters?.endDate) return { type: 'day', values: [] };
    const dateRange = [dayjs(filters.startDate), dayjs(filters.endDate)];
    if (dateRange[1].diff(dateRange[0], 'days') > 30) {
      const months: Dayjs[] = [];
      let current = dateRange[0].startOf('month');
      while (current?.isBefore(dateRange[1].startOf('month').add(1, 'month'), 'month')) {
        months.push(current);
        current = current.add(1, 'month');
      }
      return {
        type: 'month',
        values: months,
      };
    } else {
      const days: Dayjs[] = [];
      let current = dateRange[0];
      while (current?.isBefore(dateRange[1].add(1, 'day'), 'day')) {
        days.push(current);
        current = current.add(1, 'day');
      }
      return {
        type: 'day',
        values: days,
      };
    }
  }, [filters]);

  const options: ChartOptions<'bar'> = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        filter: (tooltipItem) => {
          return tooltipItem.raw !== 0;
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest', // Ensures the tooltip follows the cursor closely
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
    },
  };

  const getTableData = () => {
    return (group ?? []).flatMap((g) => {
      const appointments = filteredRows?.filter((row) => groupFilter?.(row, g.value));
      return ['Opportunities', 'Sold'].map((label, index) => {
        return {
          label: `${g.label} - ${label}`,
          data: xValue.values?.map((x) => {
            const matchingAppointments = appointments?.filter((a) =>
              dayjs(a.startDateTime.value).isSame(x, (xValue.type as OpUnitType) ?? 'day')
            );
            return index === 0
              ? matchingAppointments?.length
              : matchingAppointments?.filter((a) => a.sold && (includeCancelled || !a.orderCancelled)).length;
          }),
          backgroundColor: g.color,
          stack: index.toString(),
        };
      });
    });
  };

  return (
    <>
      <SharedPageHeader title={t('reporting.hub.appointment_reports.conversion.title')} showBack />
      {!filters ? (
        <SkeletonElement width='100%' height='526px' />
      ) : (
        <SharedCard>
          <AppointmentConversionRateReportFilters filters={filters} />
          {loading && <ProgressBar />}
          <div className='border-b overflow-x-auto'>
            <div className='h-[300px] p-4 min-w-[1200px]'>
              <Bar
                options={options}
                data={{
                  labels: xValue.values?.map((x) =>
                    xValue.type === 'month' ? x.format('MMM YYYY') : x.format('DD MMM')
                  ),
                  datasets: getTableData(),
                }}
              />
            </div>
          </div>
          <AppointmentConversionRateReportTable
            rows={filteredRows ?? []}
            loading={loading}
            group={group}
            groupFilter={groupFilter}
            includeCancelled={includeCancelled}
            setIncludeCancelled={setIncludeCancelled}
            filters={filters}
          />
        </SharedCard>
      )}
    </>
  );
};

export default AppointmentConversionRateReport;
