import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedPageHeader from 'shared/page-header/page-header';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import SharedCard from 'shared/card/card';
import { CollectionID } from 'core/constants/collection-id';
import { where } from 'firebase/firestore';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { useUserState } from 'core/providers/user-provider';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import { IAccessoryDao } from 'core/api/types';
import { useDialog } from 'core/providers/dialog-provider';
import AddEditAccessoriesDialog from './add-edit-accessories-dialog';
import DeleteAccessoriesDialog from './delete-accessories-dialog';
import BulkAddAccessoriesDialog from './bulk-add-accessories-dialog';
import { useMemo } from 'react';
import { IQueryOrder } from 'core/common/types';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'products_and_services.accessories_list.table.header.manufacturer',
    key: 'manufacturer',
  },
  {
    labelKey: 'products_and_services.accessories_list.table.header.accessory_name',
    key: 'accessoryName',
  },
  {
    labelKey: 'products_and_services.accessories_list.table.header.unit_cost',
    key: 'unitCost',
  },
  {
    labelKey: 'products_and_services.accessories_list.table.header.rrp',
    key: 'rrp',
  },
  { key: 'action', contentTemplateId: 'actions' },
];

const AccessoriesList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const tableKey = 'products_and_services.accessories_list.table';

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PRODUCTS_AND_SERVICES_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='products_and_services.accessories_list.header.bulk_add_accessory'
            appearance='primary'
            onClick={() => dialog?.openDialog(<BulkAddAccessoriesDialog tableKey={tableKey} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'bulkAddAccessory',
    },
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PRODUCTS_AND_SERVICES_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='products_and_services.accessories_list.header.add_accessory'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddEditAccessoriesDialog tableKey={tableKey} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addAccessory',
    },
  ];

  const actionTemplate = (accessory: IAccessoryDao) => {
    return (
      <div className='w-full flex justify-end space-x-2'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PRODUCTS_AND_SERVICES_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditAccessoriesDialog existing={accessory} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PRODUCTS_AND_SERVICES_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<DeleteAccessoriesDialog existing={accessory} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  const queryOrder = useMemo((): IQueryOrder => ['updated.at', 'desc'], []);

  const queryConstraints = useMemo(
    () => [where('organisationUid', '==', userData?.organisationUid), where('deleted', '==', false)],
    [userData?.organisationUid],
  );

  return (
    <>
      <SharedPageHeader showBack title={t('products_and_services.accessories')} actions={headerActions} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.ACCESSORIES}
            queryConstraints={queryConstraints}
            queryOrder={queryOrder}
            tableConfig={{
              columns: tableColumns,
              contentTemplates: contentTemplates,
            }}
            errorMessageKey='products_and_services.accessories_list.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default AccessoriesList;
