import { FirebaseFunctionName } from 'core/constants/firebase-function-name';
import createService from './factory';
import {
  IOrganisationDao,
  ILeadTypeDao,
  IAccessoryDao,
  IAppointmentTypeDao,
  IAudiometerDao,
  IClinicDao,
  IFormSettingsDao,
  IGroupDao,
  IHearingAidDao,
  IServiceDao,
  IPatientDao,
  IUserDao,
  ICreateUserRequestData,
  ICreateGroupRequestData,
  IUserSearchResult,
  IAlgoliaSearchRequestData,
  IPatientSearchResult,
  IUploadTypeDao,
  IPatientDocumentDao,
  IAuditLogDao,
  IResourceDao,
} from './types';
import { CollectionID } from 'core/constants/collection-id';
import { getDocs, query, Timestamp, where } from 'firebase/firestore';
import { IWorkflowDao } from './types/workflow.interface';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'core/config/firebase';
import { IMetaDao } from './types/meta.interface';
import {
  IAppointmentDao,
  IAppointmentsImportRequestData,
  IFetchPatientAppointmentRequestData,
  IPrintAppointmentScheduleRequestData,
} from './types/appointment.interface';
import { IPatientNoteDao } from './types/patient-notes.interface';
import { IHearingTestDao } from './types/hearing-test.interface';
import { IStockDao } from './types/stock.interface';
import { IGenerateFittingReceiptRequestData, IOrderDao } from './types/order.interface';
import { IExportTransactionsRequestData, ITransactionDao } from './types/transaction.interface';
import { ICommunicationDao } from './types/communication.interface';
import { ICustomFormTemplateDao } from './types/custom-form-templates.interface';
import { ICustomFormDao, IGetCustomFormResponse } from './types/custom-form.interface';
import {
  IBookingWidgetAppointmentSlot,
  IBookingWidgetConfigResponse,
  IConfirmBookingWidgetRequest,
  IGetBookingWidgetAppointmentSlotsRequest,
} from './types/booking-widget.interface';
import { IHolidayAndUnavailabilityDao } from './types/holiday-and-unavailability.interface';
import { IPatientAlertDao } from './types/patient-alert.interface';
import { IExportPatientsRequestData } from './types/patients.interface';
import { IResetPasswordRequestData } from './types/users.interface';
import { INoahPatientRecordDao } from './types/noah-patient-record.interface';
import { INoahSyncActionDao } from './types/noah-sync-action.interface';
import { IRepairDao } from './types/repairs.interface';
import { IEmailPatientDocumentRequestData } from './types/patient-documents.interface';
import { IMarketingQueryDao } from './types/marketing-query.interface';
import { AppointmentConversionRateRow, IAppointmentConversionRateRequestData } from './types/reporting.interface';

export const OrganisationsApiService = {
  ...createService<IOrganisationDao>(CollectionID.ORGANISATIONS),
  resetOrganisationSettings: (uid: string) => {
    return callFunction(FirebaseFunctionName.RESET_ORGANISATION_SETTINGS, {
      organisationUid: uid,
    });
  },
};

export const GroupApiService = {
  ...createService<IGroupDao>(CollectionID.GROUPS),
  createGroup: (payload: ICreateGroupRequestData) => {
    return callFunction(FirebaseFunctionName.CREATE_GROUP, payload);
  },
};
export const ClinicApiService = createService<IClinicDao>(CollectionID.CLINICS);
export const HearingAidsApiService = {
  ...createService<IHearingAidDao>(CollectionID.HEARING_AIDS),
  import: (uid: string, csv: string) => {
    return callFunction(FirebaseFunctionName.IMPORT_PRODUCTS_AND_SERVICES, {
      type: CollectionID.HEARING_AIDS,
      organisationUid: uid,
      csv,
    });
  },
};
export const AccessoriesApiService = {
  ...createService<IAccessoryDao>(CollectionID.ACCESSORIES),
  import: (uid: string, csv: string) => {
    return callFunction(FirebaseFunctionName.IMPORT_PRODUCTS_AND_SERVICES, {
      type: CollectionID.ACCESSORIES,
      organisationUid: uid,
      csv,
    });
  },
};
export const ServicesApiService = {
  ...createService<IServiceDao>(CollectionID.SERVICES),
  import: (uid: string, csv: string) => {
    return callFunction(FirebaseFunctionName.IMPORT_PRODUCTS_AND_SERVICES, {
      type: CollectionID.SERVICES,
      organisationUid: uid,
      csv,
    });
  },
};
export const LeadTypesApiService = createService<ILeadTypeDao>(CollectionID.LEAD_TYPES);
export const AppointmentTypesApiService = createService<IAppointmentTypeDao>(CollectionID.APPOINTMENT_TYPES);
export const AudiometersApiService = createService<IAudiometerDao>(CollectionID.AUDIOMETERS);
export const ResourcesApiService = createService<IResourceDao>(CollectionID.RESOURCES);
export const UploadTypesApiService = createService<IUploadTypeDao>(CollectionID.UPLOAD_TYPES);
export const OrderFormSettingsApiService = createService<IFormSettingsDao>(CollectionID.ORDER_FORM_SETTINGS);
export const PatientFormSettingsApiService = createService<IFormSettingsDao>(CollectionID.PATIENT_FORM_SETTINGS);
export const WorkflowApiService = createService<IWorkflowDao>(CollectionID.PATIENT_WORKFLOWS);
export const MetaApiService = createService<IMetaDao>(CollectionID.META);
export const AppointmentsApiService = {
  ...createService<IAppointmentDao>(CollectionID.APPOINTMENTS),
  importAppointments: (data: IAppointmentsImportRequestData) => {
    return callFunction(FirebaseFunctionName.IMPORT_APPOINTMENTS, data);
  },
  fetchPatientAppointments: async (patientUid: string, date?: string) => {
    type rawAppointmentDao = Omit<IAppointmentDao, 'created' | 'updated' | 'startDateTime' | 'endDateTime'> & {
      created: {
        at: { _seconds: number; _nanoseconds: number };
        by: {
          fullName: string;
          uid: string;
        };
      };
      updated: {
        at: { _seconds: number; _nanoseconds: number };
        by: {
          fullName: string;
          uid: string;
        };
      };
      startDateTime: { _seconds: number; _nanoseconds: number };
      endDateTime: { _seconds: number; _nanoseconds: number };
    };
    const response = await callFunction<IFetchPatientAppointmentRequestData, rawAppointmentDao[]>(
      FirebaseFunctionName.FETCH_PATIENT_APPOINTMENTS,
      { patientUid, date }
    );
    return response.map((appointment) => {
      return {
        ...appointment,
        created: {
          ...appointment.created,
          at: new Timestamp(appointment.created.at._seconds, appointment.created.at._nanoseconds),
        },
        updated: {
          ...appointment.updated,
          at: new Timestamp(appointment.updated.at._seconds, appointment.updated.at._nanoseconds),
        },
        startDateTime: new Timestamp(appointment.startDateTime._seconds, appointment.startDateTime._nanoseconds),
        endDateTime: new Timestamp(appointment.endDateTime._seconds, appointment.endDateTime._nanoseconds),
      };
    });
  },
  printAppointmentSchedule: (payload: IPrintAppointmentScheduleRequestData) => {
    return callFunction<IPrintAppointmentScheduleRequestData, string>(
      FirebaseFunctionName.PRINT_APPOINTMENT_SCHEDULE,
      payload
    );
  },
};
export const HearingTestsApiService = createService<IHearingTestDao>(CollectionID.HEARING_TESTS);
export const StockApiService = createService<IStockDao>(CollectionID.STOCK);
export const PatientNotesApiService = createService<IPatientNoteDao>(CollectionID.PATIENT_NOTES);
export const AuditLogService = createService<IAuditLogDao>(CollectionID.AUDIT_LOG);
export const OrderApiService = {
  ...createService<IOrderDao>(CollectionID.ORDERS),
  generateFittingReceipt: (payload: IGenerateFittingReceiptRequestData) => {
    return callFunction<IGenerateFittingReceiptRequestData, string>(
      FirebaseFunctionName.GENERATE_FITTING_RECEIPT,
      payload
    );
  },
};
export const TransactionApiService = {
  ...createService<ITransactionDao>(CollectionID.TRANSACTIONS),
  exportTransactions: (dates: IExportTransactionsRequestData) => {
    return callFunction<IExportTransactionsRequestData, string>(FirebaseFunctionName.EXPORT_TRANSACTIONS, dates);
  },
};
export const CommunicationApiService = createService<ICommunicationDao>(CollectionID.COMMUNICATIONS);
export const CustomFormTemplatesApiService = createService<ICustomFormTemplateDao>(CollectionID.CUSTOM_FORMS_TEMPLATES);
export const CustomFormService = {
  ...createService<ICustomFormDao>(CollectionID.CUSTOM_FORMS),
  getCustomForm: (uid: string) => {
    return callFunction<{ uid: string }, IGetCustomFormResponse>(FirebaseFunctionName.GET_CUSTOM_FORM, { uid });
  },
};
export const PatientDocumentsApiService = {
  ...createService<IPatientDocumentDao>(CollectionID.PATIENT_DOCUMENTS),
  getFileSignedUrl: (expiry: number, path: string) => {
    return callFunction<{ expiry: number; path: string }, string>(FirebaseFunctionName.GET_SIGNED_FILE_URL, {
      expiry,
      path,
    });
  },
  emailDocument: (payload: IEmailPatientDocumentRequestData) => {
    return callFunction(FirebaseFunctionName.EMAIL_PATIENT_DOCUMENT, payload);
  },
};

export const PatientApiService = {
  ...createService<IPatientDao>(CollectionID.PATIENTS),
  search: (query: string) => {
    return callFunction<IAlgoliaSearchRequestData, IPatientSearchResult[]>(FirebaseFunctionName.SEARCH_PATIENT, {
      query,
    });
  },
  importPatients: (files: string[], sourceSystem: string, start?: number, finish?: number) => {
    return callFunction(FirebaseFunctionName.PROCESS_PATIENT_IMPORT_CSV, { files, sourceSystem, start, finish });
  },
  exportPatients: (dates: IExportPatientsRequestData) => {
    return callFunction<IExportPatientsRequestData, string>(FirebaseFunctionName.EXPORT_PATIENTS, dates);
  },
};
export const UsersApiService = {
  ...createService<IUserDao>(CollectionID.USERS),
  createAdmin: (payload: ICreateUserRequestData): Promise<string> => {
    return callFunction<ICreateUserRequestData, string>(FirebaseFunctionName.CREATE_ADMIN, payload);
  },
  createOrganisationOwner: (payload: ICreateUserRequestData): Promise<string> => {
    return callFunction<ICreateUserRequestData, string>(FirebaseFunctionName.CREATE_ORGANISATION_OWNER, payload);
  },
  createOrganisationMember: (payload: ICreateUserRequestData): Promise<string> => {
    return callFunction<ICreateUserRequestData, string>(FirebaseFunctionName.CREATE_MEMBER, payload);
  },
  getOrgOwner: (orgUid: string) => {
    const q = query(
      UsersApiService.collectionRef,
      where('organisation', '==', orgUid),
      where('isOrgOwner', '==', true)
    );
    return getDocs(q.withConverter(UsersApiService.converter<IUserDao>()));
  },
  remove: (user: IUserDao) => {
    return callFunction<IUserDao, void>(FirebaseFunctionName.DELETE_USER, user);
  },
  adminRemove: (user: IUserDao) => {
    return callFunction<IUserDao, void>(FirebaseFunctionName.ADMIN_DELETE_USER, user);
  },
  search: (query: string) => {
    return callFunction<IAlgoliaSearchRequestData, IUserSearchResult[]>(FirebaseFunctionName.SEARCH_USER, { query });
  },
  resetPassword: (uid: string): Promise<string> => {
    return callFunction<IResetPasswordRequestData, string>(FirebaseFunctionName.RESET_USER_PASSWORD, { uid });
  },
};
export const BookingWidgetApiService = {
  getBookingWidgetConfig: (id: string) => {
    return callFunction<{ id: string }, IBookingWidgetConfigResponse>(FirebaseFunctionName.GET_BOOKING_WIDGET_CONFIG, {
      id,
    });
  },
  getAvailableAppointmentSlots: (payload: IGetBookingWidgetAppointmentSlotsRequest) => {
    return callFunction<IGetBookingWidgetAppointmentSlotsRequest, Record<string, IBookingWidgetAppointmentSlot>>(
      FirebaseFunctionName.GET_AVAILABLE_BOOKING_WIDGET_SLOTS,
      payload
    );
  },
  confirmBooking: (payload: IConfirmBookingWidgetRequest) => {
    return callFunction<IConfirmBookingWidgetRequest, void>(
      FirebaseFunctionName.CONFIRM_BOOKING_WIDGET_APPOINTMENT,
      payload
    );
  },
};
export const HolidayAndUnavailabilityApiService = createService<IHolidayAndUnavailabilityDao>(
  CollectionID.HOLIDAYS_AND_UNAVAILABILITY
);
export const PatientAlertsApiService = createService<IPatientAlertDao>(CollectionID.PATIENT_ALERTS);
export const NoahPatientExportsApiService = createService<INoahPatientRecordDao>(CollectionID.NOAH_PATIENT_RECORDS);
export const NoahSyncActionApiService = createService<INoahSyncActionDao>(CollectionID.NOAH_SYNC_ACTIONS);
export const PatientNoahRecordsApiService = createService<INoahPatientRecordDao>(CollectionID.NOAH_PATIENT_RECORDS);
export const RepairsApiService = {
  ...createService<IRepairDao>(CollectionID.REPAIRS),
  regenerateRepairFormPdf: (repairUid: string) => {
    return callFunction<{ repairUid: string }, void>(FirebaseFunctionName.REGENERATE_REPAIR_FORM_PDF, { repairUid });
  },
};
export const MarketingApiService = {
  ...createService<IMarketingQueryDao>(CollectionID.MARKETING_QUERIES),
  generateMarketingQueryResponse: (queryUid: string) => {
    return callFunction(FirebaseFunctionName.GENERATE_MARKETING_QUERY_RESPONSE, { queryUid });
  },
  createMarketingQuery: (prompt: string) => {
    return callFunction<{ prompt: string }, string>(FirebaseFunctionName.CREATE_NEW_MARKETING_QUERY, { prompt });
  },
};
export const ReportingApiService = {
  reportingAppointmentsConversionRate: (payload: IAppointmentConversionRateRequestData) => {
    return callFunction<IAppointmentConversionRateRequestData, AppointmentConversionRateRow[]>(
      FirebaseFunctionName.REPORTING_APPOINTMENTS_CONVERSION_RATE,
      payload
    );
  },
};

const callFunction = async <T, K>(name: FirebaseFunctionName, payload: T) => {
  const fbFunction = httpsCallable<T, K>(functions, name);
  const result = await fbFunction(payload);
  return result.data;
};
