import { Navigate, Route, Routes } from 'react-router-dom';
import StockManagementOverview from './stock-management-overview';
import StockManagementHearingAidList from './stock-management-hearing-aid-list';
import StockManagementAccessoriesList from './stock-management-accessories-list';
import { StockSelectionProvider } from './providers/stock-management-stock-table-selection-provider';

const StockManagementRouting = () => {
  return (
    <Routes>
      <Route element={<StockSelectionProvider />}>
        <Route path='' element={<StockManagementOverview />} />
        <Route path='hearing-aids' element={<StockManagementHearingAidList />} />
        <Route path='accessories' element={<StockManagementAccessoriesList />} />
        <Route path='*' element={<Navigate replace to='' />} />
      </Route>
    </Routes>
  );
};

export default StockManagementRouting;
