import { App } from 'antd';
import { AppointmentConversionRateRow } from 'core/api/types/reporting.interface';
import { useState } from 'react';
import { Download } from 'react-feather';
import SharedButton from 'shared/button/button';
import { json2csv } from 'json-2-csv';

import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { IAppointmentConversionRateReportSessionValues } from './appointment-conversion-report';

interface IAppointmentConversionRateReportDownloadButton {
  rows: AppointmentConversionRateRow[];
  filters: IAppointmentConversionRateReportSessionValues;
}

const AppointmentConversionRateReportDownloadButton = ({
  rows,
  filters,
}: IAppointmentConversionRateReportDownloadButton) => {
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();
  const { appointmentTypes, clinics, leadTypes } = useSelector(
    OrganisationSettingsSlice.selectMultiple(['clinics', 'appointmentTypes', 'leadTypes'])
  );

  const downloadReport = () => {
    try {
      setLoading(true);
      setTimeout(() => {
        const data = rows.map((row) => {
          const {
            organisationUid,
            createdBy,
            updatedBy,
            type,
            clinic,
            assignee,
            patientUid,
            outcome,
            outcomeHistory,
            referral,
            referralSubType,
            operation,
            operationCompletedAt,
            externalId,
            row_num,
            orderUid,
            patientReferral,
            ...rest
          } = row;
          const appType = appointmentTypes?.data.find((t) => t.uid === type);
          const appointmentLeadType = leadTypes?.data.find((l) => l.uid === referral);
          return {
            ...rest,
            assignee: assignee.fullName,
            type: appType?.name ?? type,
            clinic: clinics?.data.find((c) => c.uid === clinic)?.name ?? clinic,
            outcome: appType?.outcomes.find((o) => o.key === outcome)?.bannerLabel ?? outcome,
            referral: appointmentLeadType?.name ?? referral,
            referralSubType:
              appointmentLeadType?.subTypes?.find((s) => s.uid === referralSubType)?.name ?? referralSubType,
            patientReferral: leadTypes?.data.find((l) => l.uid === patientReferral)?.name ?? patientReferral,
          };
        });
        const csv = json2csv(data);
        const blob = new Blob(['\ufeff', csv]);
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `hearlink_appointment_conversion_rate_report_${filters?.startDate}_${filters?.endDate}.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setLoading(false);
      }, 2000);
    } catch (error) {
      message.error('reporting.hub.appointment_reports.conversion.download_error');
    }
  };

  return (
    <SharedButton
      className='body-xs h-[16px] hidden md:flex'
      appearance='link'
      primaryOverride
      icon={<Download size={16} />}
      labelKey='common.download_csv'
      loading={loading}
      onClick={downloadReport}
    />
  );
};

export default AppointmentConversionRateReportDownloadButton;
