import { LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Alert, App, Spin } from 'antd';
import clsx from 'clsx';
import { IMarketingQueryDaoMessage } from 'core/api/types/marketing-query.interface';
import { storage } from 'core/config/firebase';
import dayjs from 'dayjs';
import { getDownloadURL, ref } from 'firebase/storage';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IMarketingQueryMessage {
  msg: IMarketingQueryDaoMessage;
}

const MarketingQueryMessage = ({ msg }: IMarketingQueryMessage) => {
  const [downloading, setDownloading] = useState(false);
  const { message } = App.useApp();
  const systemMessage = msg.sender.type !== 'user';
  const { t } = useTranslation();

  const downloadAttachment = async (path: string, name: string) => {
    try {
      setDownloading(true);
      const url = await getDownloadURL(ref(storage, path));
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${name}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      message.success(t('marketing.marketing_query.attachment.download.success'));
    } catch (error) {
      message.error(t('marketing.marketing_query.attachment.download.error'));
    } finally {
      setDownloading(false);
    }
  };

  return (
    <motion.div
      className={clsx(systemMessage ? 'justify-start' : 'justify-end', 'flex w-full mt-6 last:mt-0')}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className='flex flex-col md:max-w-[60%]'>
        <div className={clsx(!systemMessage && 'flex-row-reverse', 'flex items-end mb-1')}>
          <div className='flex items-center space-x-1'>
            <p className='font-semibold text-[15px]'>{msg.sender.name}</p>
            {systemMessage && <div className='hearlink-ai-mask' />}
          </div>

          <p className={clsx(systemMessage ? 'ml-3' : 'mr-3', 'text-[12px] text-gray-400')}>
            {dayjs(msg.createdAt.toDate()).format('DD/MM, HH:mm')}
          </p>
        </div>
        {msg.errorMessage ? (
          <Alert className='text-red-600' showIcon message={msg.publicMessage} type='error' />
        ) : (
          <div
            className={clsx(
              systemMessage ? 'bg-gray-200 rounded-tl-none' : 'bg-blue-400 text-white rounded-tr-none self-end',
              'p-2 px-3 rounded-xl w-fit whitespace-pre-wrap'
            )}
          >
            <p>{msg.publicMessage}</p>
            {msg.attachment && (
              <button
                className='mt-2 text-blue-500 bg-transparent flex items-center space-x-2 break-all text-left'
                onClick={() => downloadAttachment(msg.attachment!.path, msg.attachment!.name)}
              >
                {downloading ? (
                  <Spin className='text-blue-500' indicator={<LoadingOutlined spin />} size='small' />
                ) : (
                  <PaperClipOutlined />
                )}
                <span>{msg.attachment.name}</span>
              </button>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default MarketingQueryMessage;
