import { useEffect, useState } from 'react';
import SharedTableBody from './table-body';
import SharedTableHeader from './table-header';
import { ISharedTable } from './table.interface';

const SharedTable = <T extends object>({
  columns,
  headerTemplates = [],
  contentTemplates = [],
  rows,
  loading = false,
  headerBackgroundColor,
  showFooter,
}: ISharedTable<T>) => {
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    if (!loading && initializing) {
      setInitializing(false);
    }
  }, [initializing, loading]);

  const minColWidth = 200;
  const minTableWidth = columns.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.width ?? minColWidth);
  }, 0);

  return (
    <div className='w-full overflow-x-auto'>
      <table className='w-full table-fixed' style={{ minWidth: minTableWidth }}>
        <SharedTableHeader
          columns={columns}
          headerTemplates={headerTemplates}
          backgroundColor={headerBackgroundColor}
        />
        <SharedTableBody
          columns={columns}
          contentTemplates={contentTemplates}
          rows={rows}
          initializing={initializing}
          showFooter={showFooter}
        />
      </table>
      {!loading && !initializing && (showFooter ? rows.length === 1 : rows.length === 0) && (
        <div className='border-t w-full min-h-[56px] body-sm flex items-center justify-center text-gray-400'>
          No results
        </div>
      )}
    </div>
  );
};

export default SharedTable;
