import { App } from 'antd';
import { DialogProvider } from 'core/providers/dialog-provider';
import { PermissionsProvider } from 'core/providers/permissions-provider';
import { ResponsiveProvider } from 'core/providers/responsive-provider';
import { ComponentPortalProvider } from 'core/providers/component-portal-provider';
import { ThemeProvider } from 'core/providers/theme-provider';
import { UserProvider } from 'core/providers/user-provider';
import AppRouting from 'core/routing/app-routing';
import { LDProvider } from 'launchdarkly-react-client-sdk';

function HearLinkApp() {
  return (
    <App>
      <LDProvider
        clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID ?? ''}
        context={{
          kind: 'user',
          key: 'unauthenticated',
        }}
      >
        <ComponentPortalProvider>
          <UserProvider>
            <ThemeProvider>
              <DialogProvider>
                <PermissionsProvider>
                  <ResponsiveProvider>
                    <AppRouting />
                  </ResponsiveProvider>
                </PermissionsProvider>
              </DialogProvider>
            </ThemeProvider>
          </UserProvider>
        </ComponentPortalProvider>
      </LDProvider>
    </App>
  );
}

export default HearLinkApp;
