import { App, Input } from 'antd';
import { ISharedTextAreaField } from './shared-fields.interface';
import { useUserState } from 'core/providers/user-provider';
import { useTranslation } from 'react-i18next';

const TextAreaWithSnippets = ({ rows, showCount, maximumLength, value, onChange }: ISharedTextAreaField) => {
  const { organisationData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();

  const onContentChange = (content: string) => {
    let textUpdatedWithSnippets = content;
    organisationData?.noteSnippets?.forEach(({ trigger, snippet }) => {
      const trig = `/${trigger}`;
      if (textUpdatedWithSnippets.includes(trig)) {
        textUpdatedWithSnippets = textUpdatedWithSnippets.replace(new RegExp(trig, 'g'), snippet);
        message.success(t('text_area.snippet_added'));
      }
    });
    onChange?.(textUpdatedWithSnippets);
  };

  return (
    <Input.TextArea
      rows={rows}
      showCount={showCount ?? false}
      maxLength={maximumLength}
      value={value}
      onChange={(e) => onContentChange(e.target.value)}
    />
  );
};

export default TextAreaWithSnippets;
