import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedPageHeader from 'shared/page-header/page-header';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import SharedCard from 'shared/card/card';
import { CollectionID } from 'core/constants/collection-id';
import { where } from 'firebase/firestore';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { useUserState } from 'core/providers/user-provider';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import { IAudiometerDao } from 'core/api/types';
import { useDialog } from 'core/providers/dialog-provider';
import dayjs from 'dayjs';
import AddEditAudiometersDialog from './add-edit-audiometers-dialog';
import { AudiometersApiService } from 'core/api';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { useMemo } from 'react';
import { IQueryOrder } from 'core/common/types';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'audiometers_list.table.header.make',
    key: 'make',
  },
  {
    labelKey: 'audiometers_list.table.header.model',
    key: 'model',
  },
  {
    labelKey: 'audiometers_list.table.header.serial_number',
    key: 'serialNumber',
  },
  {
    labelKey: 'audiometers_list.table.header.calibration_date',
    key: 'calibrationDate',
    contentTemplateId: 'calibrationDate',
  },
  { key: 'updated', labelKey: 'audiometers_list.table.header.updated', contentTemplateId: 'updated' },
  { key: 'action', contentTemplateId: 'actions' },
];

const updatedTemplate = (data: IAudiometerDao) => {
  return (
    <div className='flex space-x-2 items-center'>
      <p>
        {dayjs(data.updated.at.toDate()).format('DD/MM/YYYY')} by {data.updated.by.fullName}
      </p>
    </div>
  );
};

const calibrationDateTemplate = (data: IAudiometerDao) => {
  return (
    <div className='flex space-x-2 items-center'>
      <p>{dayjs(data.calibrationDate.toDate()).format('DD/MM/YYYY')}</p>
    </div>
  );
};

const AudiometersList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const tableKey = 'audiometers_list.table';

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.AUDIOMETERS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='audiometers_list.header.add_audiometer'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddEditAudiometersDialog tableKey={tableKey} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addAudiometer',
    },
  ];

  const actionTemplate = (audiometer: IAudiometerDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.AUDIOMETERS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditAudiometersDialog existing={audiometer} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.AUDIOMETERS_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => AudiometersApiService.permDelete(audiometer.uid)}
                  componentPortalKeys={[tableKey]}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('audiometers_list.delete_audiometer.title', {
                    audiometer: `${audiometer.make} ${audiometer.model} / ${audiometer.serialNumber}`,
                  })}
                  textContent={t('audiometers_list.delete_audiometer.content')}
                  successMessage={t('audiometers_list.delete_audiometer.success')}
                  errorMessage={t('audiometers_list.delete_audiometer.error')}
                />,
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      id: 'calibrationDate',
      template: calibrationDateTemplate,
    },
    {
      id: 'updated',
      template: updatedTemplate,
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  const queryOrder = useMemo((): IQueryOrder => ['updated.at', 'desc'], []);

  const queryConstraints = useMemo(
    () => [where('organisationUid', '==', userData?.organisationUid), where('deleted', '==', false)],
    [userData?.organisationUid],
  );

  return (
    <>
      <SharedPageHeader title={t('audiometers')} actions={headerActions} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.AUDIOMETERS}
            queryConstraints={queryConstraints}
            queryOrder={queryOrder}
            tableConfig={{
              columns: tableColumns,
              contentTemplates: contentTemplates,
            }}
            errorMessageKey='audiometers_list.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default AudiometersList;
