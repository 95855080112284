import { useTranslation } from 'react-i18next';
import SharedPageHeader from 'shared/page-header/page-header';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import SharedCard from 'shared/card/card';
import { CollectionID } from 'core/constants/collection-id';
import { where } from 'firebase/firestore';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { useUserState } from 'core/providers/user-provider';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import SharedButton from 'shared/button/button';
import { useDialog } from 'core/providers/dialog-provider';
import AddEditResourcesDialog from './add-edit-resources-dialog';
import { IResourceDao } from 'core/api/types';
import { ResourcesApiService } from 'core/api';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { useMemo } from 'react';
import { IQueryOrder } from 'core/common/types';

const ResourcesList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();

  const tableColumns: ISharedTableColumn[] = [
    {
      labelKey: 'resources_list.table.header.name',
      key: 'name',
    },
    {
      labelKey: 'resources_list.table.header.quantity',
      key: `quantity`,
      contentTemplateId: 'quantity',
    },
    { key: 'action', contentTemplateId: 'actions' },
  ];

  const tableKey = 'resources_list.table';

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.RESOURCES_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='resources_list.header.add_resource'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddEditResourcesDialog tableKey={tableKey} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addResource',
    },
  ];

  const actionTemplate = (resource: IResourceDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.RESOURCES_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditResourcesDialog existing={resource} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.RESOURCES_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => ResourcesApiService.permDelete(resource.uid)}
                  componentPortalKeys={[tableKey]}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('resources_list.delete_resource.title', {
                    resource: `${resource.name}`,
                  })}
                  textContent={t('resources_list.delete_resource.content')}
                  successMessage={t('resources_list.delete_resource.success')}
                  errorMessage={t('resources_list.delete_resource.error')}
                />,
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const quantityTemplate = (resource: IResourceDao) => {
    return (
      <div className='flex items-center space-x-2'>
        {(userData?.organisationUid && resource.quantity[userData?.organisationUid]) || (
          <p>{Object.values(resource.quantity).reduce((sum, value) => sum + (value || 0), 0)}</p>
        )}
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: quantityTemplate,
      id: 'quantity',
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  const queryOrder = useMemo((): IQueryOrder => ['updated.at', 'desc'], []);

  const queryConstraints = useMemo(
    () => [where('organisationUid', '==', userData?.organisationUid)],
    [userData?.organisationUid],
  );

  return (
    <>
      <SharedPageHeader title={t('resources')} actions={headerActions} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.RESOURCES}
            queryConstraints={queryConstraints}
            queryOrder={queryOrder}
            tableConfig={{
              columns: tableColumns,
              contentTemplates: contentTemplates,
            }}
            errorMessageKey='resources_list.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default ResourcesList;
