import { AppointmentConversionRateRow } from 'core/api/types/reporting.interface';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn } from 'shared/table/table.interface';
import { IAppointmentConversionRateReportSessionValues, IGroup } from './appointment-conversion-report';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import { Switch } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserState } from 'core/providers/user-provider';
import AppointmentConversionRateReportDownloadButton from './appointment-conversion-report-download-button';

interface IAppointmentConversionRateReportTable {
  rows: AppointmentConversionRateRow[];
  loading: boolean;
  group?: IGroup[];
  groupFilter?: (row: AppointmentConversionRateRow, key: string) => boolean;
  includeCancelled: boolean;
  setIncludeCancelled: (value: boolean) => void;
  filters: IAppointmentConversionRateReportSessionValues;
}

const AppointmentConversionRateReportTable = ({
  rows,
  loading,
  group = [],
  groupFilter,
  includeCancelled,
  setIncludeCancelled,
  filters,
}: IAppointmentConversionRateReportTable) => {
  const { organisationData } = useUserState();
  const [showZero, setShowZero] = useState(false);
  const { t } = useTranslation();
  const columns: ISharedTableColumn[] = [
    {
      key: 'color',
      contentTemplateId: 'color',
      width: 20,
    },
    {
      key: 'label',
      labelKey: `reporting.hub.appointment_reports.conversion_rate.filters.groupBy.${filters?.groupBy}`,
    },
    {
      key: 'opportunities',
      labelKey: 'reporting.hub.appointment_reports.conversion_rate.table.header.opportunities',
    },
    {
      key: 'sold',
      labelKey: 'reporting.hub.appointment_reports.conversion_rate.table.header.sold',
    },
    {
      key: 'revenue',
      labelKey: 'reporting.hub.appointment_reports.conversion_rate.table.header.revenue',
    },
  ];

  const convertToPercentage = (value: number, total: number) => {
    return `${(total ? (value / total) * 100 : 0).toFixed(1)}%`;
  };

  const getTotalRow = () => {
    const sold = rows.filter((r) => r.sold && (includeCancelled || !r.orderCancelled));
    return {
      key: 'total',
      data: {
        label: t('reporting.hub.appointment_reports.conversion_rate.table.total'),
        opportunities: rows.length,
        sold: `${sold.length} (${convertToPercentage(sold.length, rows.length)})`,
        revenue: formatToCurrency(
          sold.reduce((acc, row) => acc + row.orderPrice, 0),
          organisationData?.finance.currency ?? 'GBP'
        ),
      },
    };
  };

  const toggles = [
    {
      onChange: (checked: boolean) => setIncludeCancelled(checked),
      checked: includeCancelled,
      label: 'reporting.hub.appointment_reports.conversion_rate.table.include_cancelled',
    },
    {
      onChange: (checked: boolean) => setShowZero(checked),
      checked: showZero,
      label: 'reporting.hub.appointment_reports.conversion_rate.table.show_zero',
    },
  ];

  return (
    <div>
      <SharedTable
        loading={loading}
        showFooter
        rows={[
          ...group.reduce((acc: any[], g) => {
            const filteredRows = groupFilter ? rows.filter((row) => groupFilter(row, g.value)) : rows;
            if (!showZero && filteredRows.length === 0) {
              return acc;
            }
            const sold = filteredRows.filter((r) => r.sold && (includeCancelled || !r.orderCancelled));
            acc.push({
              key: g.value,
              data: {
                color: g.color,
                label: g.label,
                opportunities: filteredRows.length,
                sold: `${sold.length} (${convertToPercentage(sold.length, filteredRows.length)})`,
                revenue: formatToCurrency(
                  sold.reduce((acc, row) => acc + row.orderPrice, 0),
                  organisationData?.finance.currency ?? 'GBP'
                ),
              },
            });

            return acc;
          }, []),
          getTotalRow(),
        ]}
        columns={columns}
        contentTemplates={[
          {
            id: 'color',
            template: (g: IGroup) => (
              <div
                className='rounded-full h-[10px] w-[10px]'
                style={{
                  backgroundColor: g.color,
                }}
              />
            ),
          },
        ]}
        headerBackgroundColor='#f8fafc'
      />
      <div className='border-t p-3 flex items-center space-x-4 justify-end bg-gray-50'>
        <AppointmentConversionRateReportDownloadButton rows={rows} filters={filters} />
        {toggles.map((toggle, index) => (
          <div key={index} className='flex items-center space-x-2'>
            <Switch checked={toggle.checked} onChange={toggle.onChange} size='small' />
            <p className='body-xs'>{t(toggle.label)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppointmentConversionRateReportTable;
