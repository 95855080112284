import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { IAppointmentTypeDao } from 'core/api/types';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { AppointmentTypesApiService } from 'core/api';
import { v4 as uuidv4 } from 'uuid';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { AggregationColor } from 'antd/es/color-picker/color';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';

const nameFieldKey = 'name';
const colourFieldKey = 'colour';
const durationFieldKey = 'duration';
const salesOpportunityKey = 'salesOpportunity';
const showInBookingWidgetKey = 'showInBookingWidget';
const resourcesKey = 'requiredResources';
interface IAddAppointmentTypeFormOutput {
  [nameFieldKey]: string;
  [colourFieldKey]: AggregationColor;
  [durationFieldKey]: number;
  [salesOpportunityKey]: boolean;
  [showInBookingWidgetKey]: boolean;
  [resourcesKey]: string[];
}
interface IAddEditAppointmentTypeDialog {
  appointmentTypeData?: IAppointmentTypeDao;
}
const AddEditAppointmentTypeDialog = ({ appointmentTypeData }: IAddEditAppointmentTypeDialog) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();
  const creating = !appointmentTypeData;
  const navigate = useNavigate();
  const resources = useSelector(OrganisationSettingsSlice.selectResources);

  const AddAppointmentTypeFormFields: ISharedField[] = [
    {
      fieldKey: nameFieldKey,
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('common.name'),
      required: true,
    },
    {
      fieldKey: colourFieldKey,
      control: ControlType.ColorPicker,
      label: t('appointment_types.add_edit_appointment_type.form.colour'),
      required: true,
    },
    {
      fieldKey: 'requiredResources',
      control: ControlType.Select,
      mode: 'multiple',
      options: resources?.data.map((resource) => ({ value: resource.uid, label: resource.name })) || [],
      label: t('appointment_types.add_edit_appointment_type.form.resources'),
      required: false,
    },
    {
      fieldKey: durationFieldKey,
      control: ControlType.NumberField,
      label: t('appointment_types.add_edit_appointment_type.form.duration'),
      required: true,
      min: 1,
    },
    {
      fieldKey: salesOpportunityKey,
      control: ControlType.Switch,
      label: t('appointment_types.add_edit_appointment_type.form.sales_opportunity'),
      required: true,
    },
    {
      fieldKey: showInBookingWidgetKey,
      control: ControlType.Switch,
      label: t('appointment_types.add_edit_appointment_type.form.show_in_booking_widget'),
      required: true,
    },
  ];

  const submit = async (data: IAddAppointmentTypeFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        updated: userTimestamp,
        name: data[nameFieldKey],
        colour: data[colourFieldKey].toHexString(),
        duration: data[durationFieldKey],
        salesOpportunity: data[salesOpportunityKey],
        showInBookingWidget: data[showInBookingWidgetKey],
        requiredResources: data[resourcesKey] ?? [],
      };

      if (creating) {
        const uid = uuidv4();
        await AppointmentTypesApiService.set({
          uid,
          created: userTimestamp,
          deleted: false,
          outcomes: [],
          assignableUsers: [],
          organisationUid: userData.organisationUid,
          reminders: [],
          confirmation: {
            email: {
              enabled: false,
            },
            sms: {
              enabled: false,
            },
          },
          ...basePayload,
        });
        navigate(`/organisation-settings/appointment-types/${uid}`);
        dialog?.closeDialog();
        message.success(t('appointment_types.add_edit_appointment_type.create.success'));
      } else {
        await AppointmentTypesApiService.update(appointmentTypeData.uid, {
          ...basePayload,
        });
        message.success(t('appointment_types.add_edit_appointment_type.edit.success'));
        dialog?.closeDialog();
      }
    } catch (err) {
      message.error(t('appointment_types.add_edit_appointment_type.create.error'));
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddAppointmentTypeFormOutput>
        onFinish={submit}
        fields={AddAppointmentTypeFormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-appointment-type-form'
        existingValue={
          creating
            ? { [salesOpportunityKey]: false, [showInBookingWidgetKey]: false }
            : {
                ...appointmentTypeData,
                [colourFieldKey]: new AggregationColor(appointmentTypeData.colour),
                [showInBookingWidgetKey]: appointmentTypeData.showInBookingWidget ?? false,
              }
        }
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(
        creating
          ? 'appointment_types.add_edit_appointment_type.create.title'
          : 'appointment_types.add_edit_appointment_type.edit.title'
      )}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditAppointmentTypeDialog;
