import { useFlags } from 'launchdarkly-react-client-sdk';

const MarketingSidebarNewBadge = () => {
  const { marketing: featureEnabled } = useFlags();
  return (
    <>
      {featureEnabled && (
        <div className='grow flex justify-end'>
          <div className='rounded-md border px-1 py-px text-xs'>New</div>
        </div>
      )}
    </>
  );
};

export default MarketingSidebarNewBadge;
