import { StringIndexedAnyValue } from 'shared/helpers/interface.helpers';
import { ISharedTableBody, ISharedTableCustomTemplate } from './table.interface';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import { IDaoBase } from 'core/api/types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const SharedTableBody = <T extends StringIndexedAnyValue>({
  columns,
  contentTemplates,
  rows,
  initializing,
  showFooter,
}: ISharedTableBody<T>) => {
  const { t } = useTranslation();

  const textCellTemplate = (data: string) => {
    return <p className='whitespace-pre-wrap body-sm break-words'>{data ?? t('common.na')}</p>;
  };

  const lastUpdatedTemplate = (data: IDaoBase, format: string = 'DD/MM/YYYY, HH:mm') => {
    return <p className='whitespace-pre-wrap body-sm'>{dayjs(data.updated.at.toDate()).format(format)}</p>;
  };

  const createdTemplate = <T extends IDaoBase>(data: T, format: string = 'DD/MM/YYYY, HH:mm') => {
    return <p className='whitespace-pre-wrap body-sm'>{dayjs(data.created.at.toDate()).format(format)}</p>;
  };

  const templates: ISharedTableCustomTemplate[] = [
    {
      template: textCellTemplate,
      id: 'text',
    },
    {
      template: lastUpdatedTemplate,
      id: 'lastUpdated',
    },
    {
      template: createdTemplate,
      id: 'created',
    },
    ...contentTemplates,
  ];

  const findTemplate = (rowData: T, templateId: string, columnKey: string) => {
    const contentTemplate = templates.find((template) => template.id === templateId);
    return contentTemplate?.template(templateId === 'text' ? rowData[columnKey] : rowData);
  };

  return (
    <>
      <tbody>
        {initializing ? (
          <tr className='border-t min-h-[56px]'>
            {columns.map(({ key }) => (
              <td className='p-3' key={key}>
                <SkeletonElement width='50%' height='1.25rem' />
              </td>
            ))}
          </tr>
        ) : (
          <>
            {rows.slice(0, showFooter ? rows.length - 1 : rows.length).map((row) => (
              <tr key={row.key} className='border-t hover:bg-gray-50 min-h-[56px]'>
                {columns.map(({ key, contentTemplateId = 'text' }) => (
                  <td className='p-3 body-sm' key={key}>
                    {findTemplate(row.data, contentTemplateId ?? 'text', key)}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}
      </tbody>
      {showFooter && rows.length > 1 && !initializing && (
        <tfoot>
          <tr className='border-t bg-gray-50 font-semibold min-h-[56px]'>
            {columns.map(({ key, contentTemplateId = 'text' }) => (
              <td className='p-3 body-sm' key={key}>
                {findTemplate(rows[rows.length - 1].data, contentTemplateId ?? 'text', key)}
              </td>
            ))}
          </tr>
        </tfoot>
      )}
    </>
  );
};

export default SharedTableBody;
