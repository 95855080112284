import { App } from 'antd';
import { OrganisationsApiService } from 'core/api';
import { ControlType } from 'core/enums/control-type';
import { useUserState } from 'core/providers/user-provider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedCard from 'shared/card/card';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';

interface IBookingWidgetSettingsFormOutput {
  enabledDays: number[];
  mode: 'clinics' | 'organisation';
  emailAddress: string[];
  [key: `clinics.${string}`]: number[];
}

const BookingWidgetSettings = () => {
  const { t } = useTranslation();
  const { userData, organisationData } = useUserState();
  const { message } = App.useApp();
  const [updating, setUpdating] = useState(false);
  const [mode, setMode] = useState<'clinics' | 'organisation'>(
    organisationData?.bookingWidgetSettings?.mode ?? 'organisation'
  );
  const clinicState = useSelector(OrganisationSettingsSlice.selectClinics);
  const clinicLists = useMemo(() => clinicState?.data.filter((clinic) => !clinic.deleted) ?? [], [clinicState]);

  const clinicFields = useMemo<ISharedField[]>(
    () =>
      clinicLists.map((clinic) => ({
        control: ControlType.CheckboxGroup,
        options: [1, 2, 3, 4, 5, 6, 0].map((day) => ({
          label: t(`day.${day}`),
          value: day,
        })),
        fieldKey: `clinics.${clinic.uid}`,
        label: clinic.name,
        required: true,
        hidden: mode === 'organisation',
      })),
    [clinicLists, mode, t]
  );

  const fields: ISharedField[] = [
    {
      control: ControlType.RadioButton,
      options: [
        { label: t('booking_widget_settings.form.organisation'), value: 'organisation' },
        { label: t('booking_widget_settings.form.clinics'), value: 'clinics' },
      ],
      fieldKey: 'mode',
      label: t(`booking_widget_settings.form.mode`),
      required: true,
    },
    {
      control: ControlType.CheckboxGroup,
      options: [1, 2, 3, 4, 5, 6, 0].map((day) => ({
        label: t(`day.${day}`),
        value: day,
      })),
      fieldKey: 'enabledDays',
      required: true,
      hidden: mode === 'clinics',
    },
    ...clinicFields,
    {
      control: ControlType.SelectMultipleTags,
      fieldKey: 'emailAddress',
      required: true,
      label: t('booking_widget_settings.form.email_address'),
      customRules: [
        {
          validator: async (_, value?: string[]) => {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (value && value.length > 0 && value.some((email) => !regex.test(email))) {
              throw new Error(t('booking_widget_settings.form.email_address.invalid_format'));
            }
          },
        },
      ],
      extra: <p className='-mt-6 body-xs text-gray-400'>{t('booking_widget_settings.form.email_address.extra')}</p>,
    },
  ];

  const submit = async (data: IBookingWidgetSettingsFormOutput) => {
    try {
      setUpdating(true);
      const clinicsValue =
        data.mode === 'organisation'
          ? undefined
          : clinicLists.reduce((acc: Record<string, number[]>, clinic) => {
              acc[clinic.uid] = data[`clinics.${clinic.uid}`];
              return acc;
            }, {});

      await OrganisationsApiService.update(organisationData?.uid!, {
        bookingWidgetSettings: {
          mode: data.mode,
          ...(data.enabledDays && { enabledDays: data.enabledDays }),
          ...(clinicsValue && { clinics: clinicsValue }),
          emailAddress: data.emailAddress.join(','),
        },
        updated: getActionTimestampFromUser(userData),
      });
      message.success(t('booking_widget_settings.update.success'));
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      message.error(t('booking_widget_settings.update.error'));
      sentryCaptureException(error, 'booking-widget-settings-update', userData);
    }
  };

  return (
    <>
      <SharedPageHeader title={t('navigation.booking_widget_settings')} />
      <SharedCard innerClassName='p-4'>
        <p className='mb-2 font-semibold'>{t(`booking_widget_settings.form.enabled_days`)}</p>
        <SharedForm<IBookingWidgetSettingsFormOutput>
          initializing={clinicState?.status !== 'success'}
          onChange={(change) => {
            const [key, value] = Object.entries(change)[0];
            if (key === 'mode') {
              setMode(value as 'clinics' | 'organisation');
            }
          }}
          onFinish={submit}
          className=''
          name='booking-widget-settings'
          submitting={updating}
          fields={fields}
          existingValue={{
            enabledDays: organisationData?.bookingWidgetSettings?.enabledDays ?? [1, 2, 3, 4, 5],
            ...clinicLists.reduce((acc: Record<string, number[]>, clinic) => {
              acc[`clinics.${clinic.uid}`] = organisationData?.bookingWidgetSettings?.clinics?.[clinic.uid] ?? [
                1, 2, 3, 4, 5,
              ];
              return acc;
            }, {}),
            mode,
            emailAddress: organisationData?.bookingWidgetSettings?.emailAddress?.split(',') ?? [],
          }}
        />
      </SharedCard>
    </>
  );
};

export default BookingWidgetSettings;
