import { FormInstance } from 'antd';
import { IPatientDao } from 'core/api/types';
import { IOrderDao } from 'core/api/types/order.interface';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedCard from 'shared/card/card';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import FormItemMimic from 'shared/form/form-item-mimic';
import SharedForm from 'shared/form/shared-form';

export interface IAddEditOrderDetailsFormOutput {
  audiologist: string;
  orderDate: Dayjs;
  orderNumber?: string;
  additionalInformation?: string;
}

interface IAddEditOrderDetails {
  order?: IOrderDao;
  patient: IPatientDao;
  orderDetailsForm: FormInstance;
  submitting: boolean;
}

const AddEditOrderDetails = ({ order, patient, orderDetailsForm, submitting }: IAddEditOrderDetails) => {
  const orderFormSettings = useSelector(OrganisationSettingsSlice.selectOrderFormSettings);
  const { userData, organisationData } = useUserState();
  const { invoice } = organisationData?.finance ?? {};
  const { prefix, digits, next } = invoice ?? {};
  const { t } = useTranslation();
  const orderDisplayOnlyFields = [
    {
      key: 'name',
      labelKey: 'form_settings.order_form.patient_name',
      value: order?.patient.fullName ?? patient?.fullName,
    },
    {
      key: 'address',
      labelKey: 'form_settings.order_form.address',
      value: patient?.address?.formattedAddress ?? t('common.not_provided'),
    },
    {
      key: 'phoneNumber',
      labelKey: 'form_settings.order_form.phone_number',
      value: patient?.phoneNumber,
    },
    {
      key: 'emailAddress',
      labelKey: 'form_settings.order_form.email_address',
      value: patient?.emailAddress ? patient.emailAddress : t('common.not_provided'),
    },
  ];

  const testDetailFormFields: ISharedField[] = [
    {
      fieldKey: 'audiologist',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('form_settings.order_form.audiologist_name'),
      required: true,
    },
    {
      fieldKey: 'orderDate',
      control: ControlType.DatePicker,
      label: t('form_settings.order_form.order_date'),
      maxDate: dayjs(),
      required: true,
      fullWidth: true,
    },
    {
      fieldKey: 'orderNumber',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('form_settings.order_form.order_number'),
      required: true,
      hidden: orderFormSettings?.data?.disabledFields?.includes('orderNumber'),
    },
    {
      fieldKey: 'additionalInformation',
      control: ControlType.TextArea,
      rows: 4,
      label: t('form_settings.order_form.additional_information'),
      required: false,
      hidden: orderFormSettings?.data?.disabledFields?.includes('additionalInformation'),
    },
  ];

  return (
    <SharedCard title={t('orders.add_edit_order.order_details.title')} innerClassName='p-4'>
      {orderDisplayOnlyFields.map((field) => (
        <FormItemMimic key={field.key} label={t(field.labelKey)}>
          <p className='text-[15px]'>{field.value}</p>
        </FormItemMimic>
      ))}
      <SharedForm<IAddEditOrderDetailsFormOutput>
        formInstance={orderDetailsForm}
        className=''
        fields={testDetailFormFields}
        submitting={submitting}
        buttonsOverride={[]}
        name='add-edit-order-details-form'
        existingValue={{
          audiologist: order?.audiologist ?? userData?.fullName,
          orderDate: order?.orderDate ? dayjs(order?.orderDate.toDate()) : dayjs(),
          ...(invoice && {
            orderNumber: order?.orderNumber ?? `${prefix ?? ''}${String(next).padStart(digits ?? 1, '0')}`,
          }),
          additionalInformation: order?.additionalInformation,
        }}
      />
    </SharedCard>
  );
};

export default AddEditOrderDetails;
