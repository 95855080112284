import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { PatientAlertsApiService } from 'core/api';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { App } from 'antd';
import { IPatientAlertDao } from 'core/api/types/patient-alert.interface';
import { PatientAlertType, PatientAlertTypeData, PatientAlertTypeOptions } from 'core/constants/patient-alert-type';
import dayjs, { Dayjs } from 'dayjs';
import { deleteField, Timestamp } from 'firebase/firestore';
import { IPatientDao } from 'core/api/types';
import { isNotNullOrEmpty } from 'shared/helpers/null-checkers';

interface IAddEditPatientAlertDialogFormOutput {
  type: PatientAlertType;
  alertDateTime: Dayjs;
  note?: string;
}

interface IAddEditPatientAlertDialog {
  existing?: IPatientAlertDao;
  patient: IPatientDao;
}

const AddEditPatientAlertDialog = ({ existing, patient }: IAddEditPatientAlertDialog) => {
  const { userData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const creating = !existing;

  const fields: ISharedField[] = [
    {
      fieldKey: 'type',
      control: ControlType.Select,
      options: PatientAlertTypeOptions.map((op) => {
        const match = PatientAlertTypeData[op];
        return {
          label: t(match.translationLabelKey),
          value: op,
        };
      }),
      label: t('patients.patient.alerts.add_edit_alert.form.type'),
      required: true,
    },
    {
      fieldKey: 'alertDateTime',
      control: ControlType.DatePicker,
      label: t('patients.patient.alerts.add_edit_alert.form.alert_date_time'),
      presets: [
        {
          label: t('patients.patient.alerts.add_edit_alert.form.alert_date_time.30_days'),
          value: dayjs().add(30, 'days'),
        },
        {
          label: t('patients.patient.alerts.add_edit_alert.form.alert_date_time.3_months'),
          value: dayjs().add(3, 'months'),
        },
        {
          label: t('patients.patient.alerts.add_edit_alert.form.alert_date_time.6_months'),
          value: dayjs().add(6, 'months'),
        },
        {
          label: t('patients.patient.alerts.add_edit_alert.form.alert_date_time.12_months'),
          value: dayjs().add(12, 'months'),
        },
      ],
      required: true,
      minDate: dayjs(),
      fullWidth: true,
    },
    {
      fieldKey: 'note',
      control: ControlType.TextArea,
      rows: 4,
      label: t('patients.patient.alerts.add_edit_alert.form.notes'),
      required: false,
    },
  ];

  const submit = async (data: IAddEditPatientAlertDialogFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        type: data.type,
        alertDateTime: Timestamp.fromDate(data.alertDateTime.toDate()),
        updated: userTimestamp,
      };
      if (creating) {
        await PatientAlertsApiService.set({
          ...basePayload,
          organisationUid: userData.organisationUid,
          uid: uuidv4(),
          created: userTimestamp,
          acknowledged: false,
          patient,
          ...(data.note && { note: data.note }),
        });
      } else {
        await PatientAlertsApiService.update(existing.uid, {
          ...basePayload,
          note: isNotNullOrEmpty(data.note) ? data.note : deleteField(),
        });
      }
      message.success(
        t(
          existing
            ? 'patients.patient.alerts.add_edit_alert.edit.success'
            : 'patients.patient.alerts.add_edit_alert.create.success'
        )
      );
      dialog?.closeDialog();
    } catch (err) {
      message.error(
        t(
          existing
            ? 'patients.patient.alerts.add_edit_alert.edit.error'
            : 'patients.patient.alerts.add_edit_alert.create.error'
        )
      );
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddEditPatientAlertDialogFormOutput>
        className='overflow-y-auto p-4'
        onFinish={submit}
        fields={fields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-patient-alert'
        existingValue={
          existing && {
            type: existing.type,
            alertDateTime: dayjs(existing.alertDateTime.toDate()),
            note: existing.note,
          }
        }
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(
        creating
          ? 'patients.patient.alerts.add_edit_alert.create.title'
          : 'patients.patient.alerts.add_edit_alert.edit.title'
      )}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditPatientAlertDialog;
