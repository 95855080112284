import { App, Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IAppointmentTypeDao, IPatientDao, IUserDao } from 'core/api/types';
import { IAddEditAppointmentFormOutput } from '../types';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import {
  IDomainCalendarResource,
  IDomainOrganisationDataType,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useSelector } from 'react-redux';
import { useDialog } from 'core/providers/dialog-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { getTimestampFromDateAndTimeString } from 'shared/helpers/appointment-helpers';
import { v4 as uuidv4 } from 'uuid';
import { deleteField } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { AppointmentsApiService } from 'core/api';
import { sentryCaptureException, sentryCaptureMessage } from 'shared/helpers/sentry-helpers';
import { useTranslation } from 'react-i18next';

export const useAppointmentForm = ({
  userData,
  appointments,
  appointmentTypes,
  selectedAssigneeData,
  patientData,
  calendarEnd,
}: {
  userData: IUserDao;
  patientData: IPatientDao;
  appointments: IAppointmentDao[];
  appointmentTypes: IDomainOrganisationDataType<IAppointmentTypeDao>[];
  selectedAssigneeData?: IDomainCalendarResource;
  calendarEnd: Dayjs;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const dialog = useDialog();
  const { message } = App.useApp();
  const navigate = useNavigate();

  const allResourcesState = useSelector(OrganisationSettingsSlice.selectResources);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>();

  const appointmentUid = searchParams.get('appointment');
  const patientParam = searchParams.get('patient');
  const dateParam = searchParams.get('date');
  const timeParam = searchParams.get('time');
  const assigneeParam = searchParams.get('assignee');
  const clinicParam = searchParams.get('clinic');
  const selectedDay = useMemo(() => (dateParam ? dayjs(dateParam, 'YYYY-MM-DD') : dayjs().startOf('day')), [dateParam]);
  const [watchedFormValues, setWatchedFormValues] = useState<Partial<IAddEditAppointmentFormOutput>>({
    date: selectedDay,
    startTime:
      dateParam && timeParam
        ? selectedDay.hour(parseInt(timeParam.split(':')[0])).minute(parseInt(timeParam.split(':')[1]))
        : undefined,
    assignee: assigneeParam ?? undefined,
  });
  const allResources = useMemo(() => allResourcesState?.data ?? [], [allResourcesState]);

  useEffect(() => {
    const { date } = watchedFormValues;

    if (date) {
      const newSelectedStartDate = dayjs(date).startOf('week').startOf('day').toDate();
      if (!selectedStartDate || (selectedStartDate && newSelectedStartDate.getTime() !== selectedStartDate.getTime())) {
        setSelectedStartDate(newSelectedStartDate);
      }
    }
  }, [watchedFormValues, selectedStartDate]);

  const handleSaveAppointment = async (data: IAddEditAppointmentFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }

      const userTimestamp = getActionTimestampFromUser(userData);

      const createPayload = {
        uid: uuidv4(),
        organisationUid: userData?.organisationUid,
        created: userTimestamp,
      };

      const payload = {
        updated: userTimestamp,
        type: data.type,
        clinic: data.clinic,
        location: data.location,
        assignee: {
          fullName: selectedAssigneeData?.fullName ?? '',
          uid: data.assignee,
        },
        startDateTime: getTimestampFromDateAndTimeString(data.date, data.startTime.format('HH:mm')),
        endDateTime: getTimestampFromDateAndTimeString(data.date, data.endTime.format('HH:mm')),
        patient: patientData,
        cancelled: false,
        outcomeHistory: [],
        confirmed: false,
        ...(data.additionalNote && { additionalNote: data.additionalNote }),
        ...(data.referral && { referral: data.referral }),
        ...(data.referralSubType && { referralSubType: data.referralSubType }),
        sendConfirmation: data.sendConfirmation ?? false,
      };

      appointmentUid
        ? await AppointmentsApiService.update(appointmentUid, {
            ...payload,
            referral: data.referral ? data.referral : deleteField(),
            referralSubType: data.referralSubType ? data.referralSubType : deleteField(),
          })
        : await AppointmentsApiService.set({
            ...createPayload,
            ...payload,
          });
      message.success(
        t(
          appointmentUid ? 'calendar.add_edit_appointment.edit.success' : 'calendar.add_edit_appointment.create.success'
        )
      );
      navigate(-1);
    } catch (error) {
      message.error(
        t(appointmentUid ? 'calendar.add_edit_appointment.edit.error' : 'calendar.add_edit_appointment.create.error')
      );
      sentryCaptureException(error, appointmentUid ? 'Editing appointment' : 'Creating new appointment', userData);
      setSubmitting(false);
    }
  };

  const handleSubmit = async (
    data: IAddEditAppointmentFormOutput,
    ignoreResourceWarning: boolean = false,
    ignoreLocationWarning: boolean = false
  ) => {
    const override = selectedAssigneeData?.workingLocationOverrides?.[dayjs().format('YYYY-MM-DD')];
    const todaysLocation = override || selectedAssigneeData?.workingLocations?.[data.date.day()];

    if (
      !ignoreLocationWarning &&
      todaysLocation &&
      !(todaysLocation === 'domiciliary' && data.location === 'home') &&
      todaysLocation !== data.clinic
    ) {
      return dialog?.replaceDialog(
        <ConfirmActionDialog
          action={() => handleSubmit(data, ignoreResourceWarning, true)}
          actionButtonProps={{
            labelKey: 'common.confirm',
          }}
          title={t(
            todaysLocation === 'nonWorking'
              ? 'calendar.add_edit_appointment.form.confirm-non-working.title'
              : 'calendar.add_edit_appointment.form.confirm-location-difference.title'
          )}
          textContent={t(
            todaysLocation === 'nonWorking'
              ? 'calendar.add_edit_appointment.form.confirm-non-working.content'
              : 'calendar.add_edit_appointment.form.confirm-location-difference.content'
          )}
        />
      );
    }

    const { startTime, endTime, clinic, type: selectedType } = data;
    const appointmentTypeFullDetail = appointmentTypes.find((type) => type.uid === selectedType);
    const requiredResources = allResources.filter((resource) =>
      appointmentTypeFullDetail?.requiredResources?.includes(resource.uid)
    );

    if (requiredResources.length > 0 && !ignoreResourceWarning) {
      const startDateTime = selectedDay.hour(startTime.hour()).minute(startTime.minute());
      const endDateTime = selectedDay.hour(endTime.hour()).minute(endTime.minute());
      sentryCaptureMessage(
        `Appointment for ${patientData.fullName} at ${startDateTime.format('DD/MM/YYYY HH:mm')} needs ${
          requiredResources.length
        } resources`,
        userData
      );
      const overlappingAppointments = appointments.filter(
        (appointment) =>
          appointment.uid !== appointmentUid &&
          appointment.startDateTime.toDate() < endDateTime.toDate() &&
          appointment.endDateTime.toDate() > startDateTime.toDate()
      );
      sentryCaptureMessage(`Found ${overlappingAppointments.length} overlapping appointments`, userData);
      const requiredResourceCount = overlappingAppointments
        .map((app) => ({
          clinic: app.clinic,
          resources: appointmentTypes.find((type) => type.uid === app.type)?.requiredResources,
        }))
        .reduce<Record<string, Record<string, number>>>((acc, { clinic, resources }) => {
          resources?.forEach((resource) => {
            acc[clinic] = {
              ...acc[clinic],
              [resource]: (acc[clinic]?.[resource] || 0) + 1,
            };
          });
          return acc;
        }, {});

      sentryCaptureMessage(`Required resources count: ${JSON.stringify(requiredResourceCount)}`, userData);

      const showWarning = requiredResources.some((resource) => {
        if (clinic && resource.isClinicResource) {
          return (
            resource.quantity[clinic] === 0 ||
            requiredResourceCount[clinic]?.[resource.uid] >= resource.quantity[clinic]
          );
        }
        const totalResourceUsage = Object.values(requiredResourceCount).reduce(
          (sum, clinicResources) => sum + (clinicResources[resource.uid] || 0),
          0
        );
        return totalResourceUsage >= resource.quantity[userData?.organisationUid ?? ''];
      });

      if (showWarning) {
        sentryCaptureMessage(`Showing resource warning`, userData);
        return dialog?.replaceDialog(
          <ConfirmActionDialog
            action={() => handleSubmit(data, true, ignoreLocationWarning)}
            actionButtonProps={{
              labelKey: 'common.confirm',
            }}
            title={t('calendar.add_edit_appointment.form.confirm-over-resource-limit.title')}
            textContent={t('calendar.add_edit_appointment.form.confirm-over-resource-limit.content', {
              resources: requiredResources.map((resource) => resource.name).join(', '),
            })}
          />
        );
      }
    }

    handleSaveAppointment(data);
  };

  const handleChanges = (
    change: Partial<IAddEditAppointmentFormOutput>,
    all: Partial<IAddEditAppointmentFormOutput>
  ) => {
    const { type: selectedType, startTime, date } = watchedFormValues;
    const newWatchedValues = { ...all };
    const [key, value] = Object.entries(change)[0];
    if (key !== 'endTime') {
      const start = (() => {
        if (key === 'startTime') {
          return value as Dayjs;
        }
        return newWatchedValues.startTime ?? startTime ?? undefined;
      })();
      const end = newWatchedValues.endTime;
      if (start && !end) {
        const appointmentTypeFullDetail = appointmentTypes.find((type) => type.uid === selectedType);
        let newEndTime = start.add(appointmentTypeFullDetail?.duration ?? 15, 'minute');
        const calendarEndDateTime = date?.hour(calendarEnd.hour()).minute(calendarEnd.minute());
        if (newEndTime.isAfter(calendarEndDateTime, 'minute')) {
          newEndTime = calendarEnd;
        }
        form.setFieldValue('endTime', newEndTime);
        newWatchedValues.endTime = newEndTime;
        form.validateFields(['endTime']);
      }
    }
    setWatchedFormValues((prevState) => ({ ...prevState, ...newWatchedValues }));
  };

  return {
    form,
    loading,
    submitting,
    watchedFormValues,
    appointmentUid,
    patientParam,
    dateParam,
    timeParam,
    assigneeParam,
    clinicParam,
    selectedDay,
    selectedStartDate,
    setLoading,
    setWatchedFormValues,
    handleSubmit,
    handleChanges,
  };
};
