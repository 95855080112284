import clsx from 'clsx';
import { TransactionApiService } from 'core/api';
import { ITransactionDao } from 'core/api/types/transaction.interface';
import { CollectionID } from 'core/constants/collection-id';
import { PaymentMethodData } from 'core/constants/payment-method';
import { Permission } from 'core/constants/permission';
import { TransactionTypeData } from 'core/constants/transaction-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useTheme } from 'core/providers/theme-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { where } from 'firebase/firestore';
import AddEditTransactionDialog from 'modules/patients/patient/transactions/add-edit-transaction-dialog';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import SharedForm from 'shared/form/shared-form';
import { ControlType } from 'core/enums/control-type';
import FinanceOverviewSnapshots from './finance-overview-snapshots';
import ExportTransactionsDialog from './export-transactions-dialog';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'patients.patient.transactions.table.header.date',
    key: 'date',
    contentTemplateId: 'date',
    width: 120,
  },
  {
    labelKey: 'orders.orders_list.table.header.patient',
    key: 'patient',
    contentTemplateId: 'patient',
    width: 250,
  },
  {
    labelKey: 'patients.patient.transactions.table.header.type',
    key: 'type',
    contentTemplateId: 'type',
  },
  {
    labelKey: 'patients.patient.transactions.table.header.amount',
    key: 'amount',
    contentTemplateId: 'amount',
  },
  {
    labelKey: 'patients.patient.transactions.table.header.method',
    key: 'method',
    contentTemplateId: 'method',
  },
  {
    labelKey: 'patients.patient.transactions.table.header.created',
    key: 'created',
    contentTemplateId: 'created',
    width: 300,
  },
  { key: 'action', contentTemplateId: 'actions', width: 120 },
];

type IFinanceSnapShotDates = [Dayjs | null, Dayjs | null] | null;

interface IFinanceOverviewFilterFormOutput {
  dateRange: IFinanceSnapShotDates;
}

const FinanceOverview = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const tableKey = 'finance.overview.table';
  const { organisationData } = useUserState();
  const dialog = useDialog();
  const { primary } = useTheme();
  const [selectedDateRange, setSelectedDateRange] = useState<IFinanceSnapShotDates>([dayjs().add(-7, 'day'), dayjs()]);

  const dateTemplate = (transaction: ITransactionDao) => {
    return <p className='body-sm'>{dayjs(transaction.date.toDate()).format('DD/MM/YYYY')}</p>;
  };

  const patientTemplate = (transaction: ITransactionDao) => {
    const { patient } = transaction;
    if (!patient) {
      return;
    }
    const firstLine = patient.address?.formattedAddress.split(',')[0];
    const postCode = patient.address?.postcode;
    return (
      <div>
        <Link to={`/patients/${patient.uid}`} style={{ color: primary.bg }}>
          {patient.fullName}
        </Link>
        <p className={clsx('body-xs', !patient?.address ? 'text-gray-300' : 'text-gray-500')}>
          {firstLine && postCode ? `${firstLine}, ${postCode}` : t('patients.patient.information.address_not_defined')}
        </p>
      </div>
    );
  };

  const typeTemplate = (transaction: ITransactionDao) => {
    const typeData = TransactionTypeData[transaction.transactionType];
    if (!typeData) {
      return <></>;
    }
    const Icon = typeData?.icon;
    return (
      <div className='flex items-center space-x-2'>
        <div
          style={{ backgroundColor: `${typeData.color}19`, borderColor: typeData.color }}
          className='rounded-full p-1 border'
        >
          <Icon color={typeData.color} size={12} />
        </div>
        <p>{t(typeData.translationLabelKey)}</p>
      </div>
    );
  };

  const amountTemplate = (transaction: ITransactionDao) => {
    const modifier = transaction.transactionType !== 'charge' ? '-' : '+';
    return (
      <p className='body-sm'>
        {modifier} {formatToCurrency(transaction.amount, organisationData?.finance.currency)}
      </p>
    );
  };

  const methodTemplate = (transaction: ITransactionDao) => {
    const methodData = PaymentMethodData[transaction.method];
    return (
      <p className={clsx('body-sm', !methodData && 'text-gray-300')}>
        {t(methodData?.translationLabelKey ?? 'common.na')}
      </p>
    );
  };

  const createdTemplate = (transaction: ITransactionDao) => {
    return (
      <p className='body-sm'>
        {t('common.at_by', {
          at: dayjs(transaction.created.at.toDate()).format('DD/MM/YYYY, HH:mm'),
          by: transaction.created.by.fullName,
        })}
      </p>
    );
  };

  const actionTemplate = (transaction: ITransactionDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.TRANSACTIONS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(<AddEditTransactionDialog patient={transaction.patient} transaction={transaction} />)
            }
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.TRANSACTIONS_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => TransactionApiService.permDelete(transaction.uid)}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('patients.patient.transactions.delete_transaction.title')}
                  textContent={t('patients.patient.transactions.delete_transaction.content')}
                  successMessage={t('patients.patient.transactions.delete_transaction.success')}
                  errorMessage={t('patients.patient.transactions.delete_transaction.error')}
                />,
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: dateTemplate,
      id: 'date',
    },
    {
      template: patientTemplate,
      id: 'patient',
    },
    {
      template: typeTemplate,
      id: 'type',
    },
    {
      template: amountTemplate,
      id: 'amount',
    },
    {
      template: methodTemplate,
      id: 'method',
    },
    {
      template: createdTemplate,
      id: 'created',
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[
            [Permission.TRANSACTIONS_BROWSE, Permission.TRANSACTIONS_READ],
            [Permission.ORGANISATION_OWNER],
          ]}
        >
          <SharedButton
            labelKey='finance.overview.export_transactions'
            onClick={() => dialog?.openDialog(<ExportTransactionsDialog />)}
            appearance='primary'
          />
        </SharedElementPermissionGuard>
      ),
      key: 'exportTransactions',
    },
  ];

  const queryOrder = useMemo((): IQueryOrder => ['date', 'desc'], []);

  const queryConstraints = useMemo(() => {
    return [
      where('organisationUid', '==', userData?.organisationUid),
      where('date', '>=', selectedDateRange?.[0]?.startOf('day').toDate() ?? null),
      where('date', '<=', selectedDateRange?.[1]?.endOf('day').toDate() ?? null),
    ];
  }, [selectedDateRange, userData?.organisationUid]);

  return (
    <>
      <SharedPageHeader title={t('navigation.finance')} actions={headerActions} />
      <SharedCard title={t('filters.title')}>
        <SharedForm<IFinanceOverviewFilterFormOutput>
          className='overflow-x-auto py-4 pl-4'
          buttonsOverride={[]}
          layout='inline'
          onChange={(_, allValues) => {
            setSelectedDateRange(allValues.dateRange);
          }}
          name='finance-overview-filters'
          fields={[
            {
              fieldKey: 'dateRange',
              control: ControlType.DateRangePicker,
              label: t('finance.overview.filters.form.date_range'),
              required: true,
              allowClear: false,
              maxDate: dayjs(),
            },
          ]}
          existingValue={{
            dateRange: selectedDateRange,
          }}
        />
      </SharedCard>

      {selectedDateRange && selectedDateRange[0] && selectedDateRange[1] && (
        <FinanceOverviewSnapshots selectedDateRange={selectedDateRange as [Dayjs, Dayjs]} />
      )}
      <SharedCard title={t('finance.overview.all_transactions')}>
        {userData && selectedDateRange && selectedDateRange[0] && selectedDateRange[1] && (
          <SharedPaginatedTable
            collectionId={CollectionID.TRANSACTIONS}
            queryConstraints={queryConstraints}
            queryOrder={queryOrder}
            tableConfig={{
              columns: tableColumns,
              contentTemplates,
              headerBackgroundColor: '#f8fafc',
            }}
            errorMessageKey='finance.overview.error'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default FinanceOverview;
