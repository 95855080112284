import { Permission } from 'core/constants/permission';
import { useUserState } from 'core/providers/user-provider';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { SearchOutlined } from '@ant-design/icons';
import { useDialog } from 'core/providers/dialog-provider';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import { useTranslation } from 'react-i18next';
import { auth } from 'core/config/firebase';
import { signOut } from 'firebase/auth';
import useKeyPress from 'shared/hooks/use-key-press';
import useOS from 'shared/hooks/use-os';
import { Button, Popover } from 'antd';
import { ChevronUp, Command } from 'react-feather';
const Header = () => {
  const { userData } = useUserState();
  const dialog = useDialog();
  const { t } = useTranslation();
  const os = useOS();

  useKeyPress(
    'k',
    () => {
      if (
        [[Permission.ORGANISATION_OWNER], [Permission.PATIENTS_READ]].some((pg) =>
          pg.every((p) => userData?.permissions.includes(p))
        ) &&
        !userData?.isHearLinkAdmin
      )
        dialog?.openDialog(<PatientSearchDialog />);
    },
    {
      ctrl: os === 'windows',
      meta: os === 'mac',
    }
  );

  return (
    <div className='basis-[60px] md:basis-[70px] grow-0 flex-shrink-0 border-b bg-white flex items-center justify-between md:justify-end px-4'>
      <SharedElementPermissionGuard requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.PATIENTS_READ]]}>
        {!userData?.isHearLinkAdmin && (
          <Button icon={<SearchOutlined />} onClick={() => dialog?.openDialog(<PatientSearchDialog />)}>
            <div className='flex'>
              <p>{t('patients.patient_search')}</p>
              <Popover
                className='hidden md:flex'
                content={
                  <p className='body-xs'>
                    {t(
                      os === 'mac'
                        ? 'patients.patient_search.shortcut_description.mac'
                        : 'patients.patient_search.shortcut_description.windows'
                    )}
                  </p>
                }
              >
                <div className='flex items-center border-l border-gray-300 pl-3 ml-3 space-x-px text-gray-400'>
                  {os === 'mac' ? <Command size={14} /> : <ChevronUp size={14} />} <span>K</span>
                </div>
              </Popover>
            </div>
          </Button>
        )}
      </SharedElementPermissionGuard>

      <div className='flex flex-col items-end text-gray-800 ml-6'>
        <p className='body-sm'>{userData?.fullName}</p>
        <button
          className='body-sm opacity-60 hover:underline bg-transparent'
          onClick={() => {
            signOut(auth);
            localStorage.clear();
          }}
        >
          {t('common.log_out')}
        </button>
      </div>
    </div>
  );
};

export default Header;
