import { Navigate, Route, Routes } from 'react-router-dom';
import ResourcesList from './resources-list';

const ResourcesRouting = () => {
  return (
    <Routes>
      <Route path='' element={<ResourcesList />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default ResourcesRouting;
