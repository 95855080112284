import { IStockAccessoryAllocationDao, IStockDao } from 'core/api/types/stock.interface';
import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { StockStatus } from 'core/constants/stock-status';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { QueryConstraint, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import AddEditStockDialog from './add-edit-stock/add-edit-stock-dialog';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { StockApiService } from 'core/api';
import { useContext, useMemo, useState } from 'react';
import { Checkbox, Input, Popover, Select } from 'antd';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import StockAllocationDialog from './stock-allocation-dialog/stock-allocation-dialog';
import { IPatientSearchResult } from 'core/api/types';
import { Link } from 'react-router-dom';
import { useTheme } from 'core/providers/theme-provider';
import SharedStockStatusTableTemplate from 'shared/stock-management/stock-status-table-template';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { StockSelectionContext } from './providers/stock-management-stock-table-selection-provider';

type SearchMode = 'serialNumber' | 'location';
interface IStockManagementStockTable {
  additionalColumns?: ISharedTableColumn[];
  additionalContentTemplates?: ISharedTableCustomTemplate[];
  tableKey: string;
  additionalConstraints?: QueryConstraint[];
  title?: string;
  showSerialNumberLookup?: boolean;
}

interface IStockSearchState {
  mode: SearchMode;
  term: string | undefined;
}

const StockManagementStockTable = ({
  additionalColumns = [],
  additionalContentTemplates = [],
  tableKey,
  additionalConstraints = [],
  title,
  showSerialNumberLookup = false,
}: IStockManagementStockTable) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const { primary } = useTheme();
  const [searchState, setSearchState] = useState<IStockSearchState>();

  const conditionalTemplate = (value?: string | null, fallback: string = 'common.not_provided') => {
    return <p className={clsx('body-sm whitespace-pre-wrap', !value && 'text-gray-400')}>{value ?? t(fallback)}</p>;
  };

  const additionalDetailsTemplate = (stock: IStockDao) => {
    return conditionalTemplate(stock.additionalDetails, 'common.not_specified');
  };

  const locationTemplate = (stock: IStockDao) => {
    return conditionalTemplate(stock.location);
  };

  const warrantyTemplate = (stock: IStockDao) => {
    return conditionalTemplate(stock.warranty);
  };

  const serialTemplate = (stock: IStockDao) => {
    return conditionalTemplate(stock.serialNumber);
  };

  const stockAddedTemplate = (data: IStockDao) => {
    const date = data.stockAddedDate ? dayjs(data.stockAddedDate.toDate()).format('DD/MM/YYYY') : undefined;
    return conditionalTemplate(date, 'common.not_specified');
  };

  const selectTemplate = (stock: IStockDao) => {
    return <StockSelectTemplate {...stock} />;
  };

  const statusTemplate = (stock: IStockDao) => {
    return <SharedStockStatusTableTemplate stock={stock} tableKey={tableKey} />;
  };

  const allocationTemplate = (stock: IStockDao) => {
    if (stock.type === 'accessory' && stock.allocation.patients.length > 0) {
      return (
        <Popover
          placement='topLeft'
          content={
            <div className='flex flex-col max-h-[500px] overflow-y-auto'>
              {stock.allocation.allocations
                .reduce((acc: { uid: string; fullName: string; allocations: IStockAccessoryAllocationDao[] }[], a) => {
                  const index = acc.findIndex((x) => x.uid === a.to.uid);
                  if (index === -1) {
                    acc.push({
                      uid: a.to.uid,
                      allocations: [a],
                      fullName: a.to.fullName,
                    });
                  } else {
                    acc[index].allocations.push(a);
                  }
                  return acc;
                }, [])
                .map((a) => (
                  <Link
                    className='border-b last:border-b-0 body-sm py-2 last:pb-0 first:pt-0'
                    key={a.uid}
                    to={`/patients/${a.uid}/products`}
                    style={{ color: primary.bg }}
                  >
                    <p>
                      {a.fullName} ({a.allocations.length})
                    </p>
                  </Link>
                ))}
            </div>
          }
          trigger='click'
        >
          <p className='cursor-pointer' style={{ color: primary.bg }}>
            {t('stock_management.overview.table.allocation.accessory', {
              count: stock.allocation.patients.length,
            })}
          </p>
        </Popover>
      );
    } else if (stock.type === 'hearingAid' && stock.allocated) {
      return (
        <Link to={`/patients/${stock.allocated.to.uid}/products`} style={{ color: primary.bg }}>
          <p className='body-sm whitespace-pre-wrap'>{stock.allocated.to.fullName}</p>
        </Link>
      );
    } else {
      return (
        <p className='body-sm whitespace-pre-wrap text-gray-400'>
          {t('stock_management.overview.table.allocation.unallocated')}
        </p>
      );
    }
  };

  const actionTemplate = (stock: IStockDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              openDialog(<AddEditStockDialog selectedType={stock.type} stock={stock} tableKey={tableKey} />)
            }
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              openDialog(
                <ConfirmActionDialog
                  action={() => StockApiService.permDelete(stock.uid)}
                  componentPortalKeys={[tableKey, 'stockManagementCounts']}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('stock_management.overview.delete_stock_dialog.title')}
                  textContent={t('stock_management.overview.delete_stock_dialog.content')}
                  successMessage={t('stock_management.overview.delete_stock_dialog.success')}
                  errorMessage={t('stock_management.overview.delete_stock_dialog.error')}
                />,
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const queryOrder = useMemo((): IQueryOrder => ['updated.at', 'desc'], []);

  const queryConstraints = useMemo(() => {
    return [
      where('organisationUid', '==', userData?.organisationUid),
      ...(searchState?.mode === 'location' && searchState.term ? [where('location', '==', searchState.term)] : []),
      searchState?.mode === 'serialNumber' && searchState.term
        ? where('serialNumber', '==', searchState.term)
        : where('status', 'not-in', [StockStatus.SOLD, StockStatus.RETURNED_TO_SUPPLIER, StockStatus.LOST]),
      ...additionalConstraints,
    ];
  }, [userData?.organisationUid, searchState, additionalConstraints]);

  return (
    <SharedCard
      title={title}
      extra={
        <div className='space-x-2 flex'>
          <HandleSelectedStock tableKey={tableKey} />
          {showSerialNumberLookup && <StockSearch setSearchState={setSearchState} searchState={searchState} />}
        </div>
      }
    >
      {userData && (
        <SharedPaginatedTable
          collectionId={CollectionID.STOCK}
          queryConstraints={queryConstraints}
          queryOrder={queryOrder}
          tableConfig={{
            headerBackgroundColor: '#f8fafc',
            columns: [
              {
                key: 'select',
                contentTemplateId: 'select',
                width: 28,
              },
              ...additionalColumns,
              {
                labelKey: 'stock_management.add_edit_stock.form.serial_number',
                key: 'serialNumber',
                contentTemplateId: 'serialNumber',
                width: 200,
              },
              {
                labelKey: 'stock_management.add_edit_stock.form.warranty',
                key: 'warranty',
                width: 200,
                contentTemplateId: 'warranty',
              },
              {
                labelKey: 'stock_management.add_edit_stock.form.location',
                key: 'location',
                contentTemplateId: 'location',
                width: 180,
              },
              {
                labelKey: 'stock_management.add_edit_stock.form.additional_details',
                key: 'additionalDetails',
                contentTemplateId: 'additionalDetails',
                width: 250,
              },
              {
                labelKey: 'stock_management.add_edit_stock.form.status',
                key: 'status',
                contentTemplateId: 'status',
                width: 120,
              },
              {
                labelKey: 'stock_management.overview.table.header.allocation',
                key: 'allocation',
                contentTemplateId: 'allocation',
              },
              {
                labelKey: 'common.stock_added',
                key: 'stockAddedDate',
                contentTemplateId: 'stockAddedDate',
              },
              {
                labelKey: 'common.updated',
                key: 'lastUpdated',
                contentTemplateId: 'lastUpdated',
              },
              { key: 'action', contentTemplateId: 'actions', width: 96 },
            ],
            contentTemplates: [
              {
                template: selectTemplate,
                id: 'select',
              },
              ...additionalContentTemplates,
              { template: locationTemplate, id: 'location' },
              {
                template: serialTemplate,
                id: 'serialNumber',
              },
              {
                template: statusTemplate,
                id: 'status',
              },
              {
                template: allocationTemplate,
                id: 'allocation',
              },
              {
                template: warrantyTemplate,
                id: 'warranty',
              },
              {
                template: actionTemplate,
                id: 'actions',
              },
              {
                template: stockAddedTemplate,
                id: 'stockAddedDate',
              },
              {
                template: additionalDetailsTemplate,
                id: 'additionalDetails',
              },
            ],
          }}
          errorMessageKey='stock_management.overview.table.error'
          tableKey={tableKey}
        />
      )}
    </SharedCard>
  );
};

export default StockManagementStockTable;

const StockSelectTemplate = (stock: IStockDao) => {
  const { selectedStock, setSelectedStock } = useContext(StockSelectionContext);
  return (
    <Checkbox
      disabled={stock.type === 'accessory' && stock.quantity === 0}
      checked={selectedStock.some((selected) => selected.uid === stock.uid)}
      onChange={() => {
        const index = selectedStock.findIndex((selected) => selected.uid === stock.uid);
        if (index > -1) {
          setSelectedStock((prevState) => prevState.toSpliced(index, 1));
        } else {
          setSelectedStock([...selectedStock, stock]);
        }
      }}
    />
  );
};

const HandleSelectedStock = ({ tableKey }: { tableKey: string }) => {
  const { selectedStock, setSelectedStock } = useContext(StockSelectionContext);
  const { replaceDialog, openDialog } = useDialog();

  const handleStockAllocation = async (
    patient: IPatientSearchResult,
    status: StockStatus.ON_TRIAL | StockStatus.SOLD,
  ) => {
    replaceDialog(
      <StockAllocationDialog
        patient={patient}
        status={status}
        stock={selectedStock}
        tableKey={tableKey}
        setSelectedStock={setSelectedStock}
      />,
    );
  };

  return (
    <>
      {selectedStock.length > 0 && (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.STOCK_UPDATE]]}
        >
          <SharedButton
            labelKey='stock_management.overview.table.on_trial'
            onClick={() =>
              openDialog(
                <PatientSearchDialog onSelect={(result) => handleStockAllocation(result, StockStatus.ON_TRIAL)} />,
              )
            }
          />
          <SharedButton
            labelKey='stock_management.overview.table.sold'
            onClick={() =>
              openDialog(<PatientSearchDialog onSelect={(result) => handleStockAllocation(result, StockStatus.SOLD)} />)
            }
          />
        </SharedElementPermissionGuard>
      )}
    </>
  );
};

interface IStockSearch {
  searchState?: IStockSearchState;
  setSearchState: (value: IStockSearchState | undefined) => void;
}

const StockSearch = ({ setSearchState, searchState }: IStockSearch) => {
  const [mode, setMode] = useState<SearchMode>('serialNumber');
  const [term, setTerm] = useState<string>();

  const { t } = useTranslation();
  return (
    <div className='py-4 md:p-0 flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2'>
      <Input
        className='w-[150px] md:w-[250px]'
        allowClear
        disabled={!!searchState}
        value={term}
        placeholder={t(
          mode === 'location'
            ? 'stock_management.overview.table.search.placeholder.location'
            : 'stock_management.overview.table.search.placeholder.serial_number',
        )}
        onChange={(event) => setTerm(event.target.value)}
      />
      <Select
        className='w-[150px] md:w-[250px]'
        onChange={(value) => setMode(value)}
        value={mode}
        options={[
          {
            label: t('stock_management.overview.table.search.serial_number'),
            value: 'serialNumber',
          },
          {
            label: t('stock_management.overview.table.search.location'),
            value: 'location',
          },
        ]}
      />
      <SharedButton
        labelKey={searchState ? 'common.cancel' : 'common.search'}
        disabled={!term}
        onClick={() => setSearchState(searchState ? undefined : { mode, term })}
      />
    </div>
  );
};
