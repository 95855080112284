import { Alert } from 'antd';
import { INoteSnippetDao } from 'core/api/types/organisations.interface';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedTable from 'shared/table/table';
import AddEditNoteSnippetDialog from './add-edit-note-snippet-dialog';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { OrganisationsApiService } from 'core/api';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';

const NoteSnippetsList = () => {
  const { t } = useTranslation();
  const { organisationData, userData } = useUserState();
  const dialog = useDialog();

  const actionTemplate = (snippet: INoteSnippetDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.FORM_SETTINGS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditNoteSnippetDialog existing={snippet} />)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() =>
                    OrganisationsApiService.update(organisationData?.uid!, {
                      noteSnippets: organisationData?.noteSnippets?.filter((s) => s.uid !== snippet.uid),
                      updated: getActionTimestampFromUser(userData),
                    })
                  }
                  title={t('form_settings.note_snippets.delete_snippet.title')}
                  textContent={t('form_settings.note_snippets.delete_snippet.content')}
                  successMessage={t('form_settings.note_snippets.delete_snippet.success')}
                  errorMessage={t('form_settings.note_snippets.delete_snippet.error')}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                />
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  return (
    <>
      <SharedPageHeader
        title={t('form_settings.note_snippets')}
        showBack
        actions={[
          {
            element: (
              <SharedElementPermissionGuard
                requiredPermissions={[[Permission.FORM_SETTINGS_UPDATE], [Permission.ORGANISATION_OWNER]]}
              >
                <SharedButton
                  labelKey='form_settings.note_snippets.header.add_snippet'
                  appearance='primary'
                  onClick={() => dialog?.openDialog(<AddEditNoteSnippetDialog />)}
                />
              </SharedElementPermissionGuard>
            ),
            key: 'addSnippet',
          },
        ]}
      />
      <Alert type='info' showIcon message={t('form_settings.note_snippets_info')} />
      <SharedCard outerClassName='mt-4'>
        <SharedTable
          rows={(organisationData?.noteSnippets ?? []).map((snippet) => ({
            key: snippet.uid,
            data: snippet,
          }))}
          columns={[
            {
              labelKey: 'form_settings.note_snippets.table.header.trigger',
              key: 'trigger',
              width: 160,
            },
            {
              labelKey: 'form_settings.note_snippets.table.header.snippet',
              key: 'snippet',
              contentTemplateId: 'snippet',
            },
            {
              key: 'actions',
              contentTemplateId: 'actions',
              width: 120,
            },
          ]}
          contentTemplates={[
            {
              id: 'actions',
              template: actionTemplate,
            },
            {
              id: 'snippet',
              template: (snippet: INoteSnippetDao) => <p className='body-sm truncate'>{snippet.snippet}</p>,
            },
          ]}
        />
      </SharedCard>
    </>
  );
};

export default NoteSnippetsList;
