import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import PermissionGuard from 'core/routing/permission-guard';
import { Permission } from 'core/constants/permission';
import PatientLayout from './patient-layout';
import PatientOverview from './patient-overview/patient-overview';
import { useCallback, useEffect, useState } from 'react';
import { IPatientDao } from 'core/api/types';
import { Unsubscribe, where } from 'firebase/firestore';
import { PatientApiService, PatientNotesApiService } from 'core/api';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { getPatientNavOptions } from './patient-nav-options';
import PatientNotesList from './notes/patient-notes-list';
import PatientHearingTestList from './hearing-tests/patient-hearing-test-list';
import PatientDocumentsList from './documents/patient-documents-list';
import PatientAppointmentsList from './appointments/patient-appointments-list';
import PatientAuditLog from './audit-log/patient-audit-log';
import PatientOrdersList from './orders/patient-orders-list';
import PatientTransactionsList from './transactions/patient-transactions-list';
import PatientCommunicationsList from './communications/patient-communications-list';
import PatientProductsList from './products/patient-products-list';
import PatientFormsList from './forms/patient-forms-list';
import PatientAlertsList from './alerts/patient-alerts-list';
import PatientNoahRecordList from './noah-records/patient-noah-records-list';
import PatientRepairsList from './repairs/patient-repairs-list';
import { useUserState } from 'core/providers/user-provider';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { useDialog } from 'core/providers/dialog-provider';
import PatientAlertNotesDialog from './patient-alert-notes-dialog';

const PatientRouting = () => {
  const { openDialog } = useDialog();
  const { uid } = useParams();
  const location = useLocation();
  const [patient, setPatient] = useState<IPatientDao>();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { userData } = useUserState();

  const checkForPatientAlerts = useCallback(
    async (uid: string, organisationUid: string) => {
      try {
        const notes = await PatientNotesApiService.getAll([
          where('patientUid', '==', uid),
          where('organisationUid', '==', organisationUid),
          where('alert', '==', true),
        ]);
        if (!notes.empty) {
          // Wait until any other dialog is closed before opening the alert dialog
          setTimeout(() => {
            openDialog(<PatientAlertNotesDialog notes={notes.docs.map((note) => note.data().note)} />);
          }, 250);
        }
      } catch (error) {
        message.error(t('patients.patient.patient_alert_notes.error.description'));
        sentryCaptureException(error, 'Fetching Patient alert notes');
      }
    },
    [message, openDialog, t]
  );

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (uid && userData?.organisationUid) {
      unsubscribe = PatientApiService.onDocSnapshot(
        uid,
        (doc) => {
          if (doc.exists()) {
            setPatient(doc.data());
            setLoading(false);
          }
        },
        () => {
          setLoading(false);
          message.error(t('patients.patient.patient_fetch_error'));
        }
      );

      if (
        [[Permission.PATIENT_NOTES_READ], [Permission.ORGANISATION_OWNER]].some((requiredPermissions) =>
          requiredPermissions.some((rp) => userData.permissions.includes(rp))
        )
      ) {
        checkForPatientAlerts(uid, userData.organisationUid);
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [checkForPatientAlerts, message, t, uid, userData?.organisationUid, userData?.permissions]);

  if (!uid || (!loading && !patient)) {
    return <Navigate to='/patients' state={{ from: location }} replace />;
  }

  const navList = getPatientNavOptions(uid);

  const routes = [
    {
      requiredPermissions: [[Permission.PATIENTS_READ], [Permission.ORGANISATION_OWNER]],
      path: 'overview',
      element: <PatientOverview {...patient!} />,
    },
    {
      path: 'appointments',
      requiredPermissions: [
        [Permission.APPOINTMENTS_READ_ALL],
        [Permission.APPOINTMENTS_READ_MY_CALENDAR],
        [Permission.APPOINTMENTS_READ_MY_CLINICS],
        [Permission.ORGANISATION_OWNER],
      ],
      element: <PatientAppointmentsList {...patient!} />,
    },
    {
      path: 'hearing-tests',
      requiredPermissions: [[Permission.PATIENT_HEARING_TESTS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientHearingTestList {...patient!} />,
    },
    {
      path: 'orders',
      requiredPermissions: [[Permission.ORDERS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientOrdersList {...patient!} />,
    },
    {
      path: 'products',
      requiredPermissions: [[Permission.STOCK_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientProductsList {...patient!} />,
    },
    {
      path: 'repairs',
      requiredPermissions: [[Permission.REPAIRS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientRepairsList {...patient!} />,
    },
    {
      path: 'transactions',
      requiredPermissions: [[Permission.TRANSACTIONS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientTransactionsList {...patient!} />,
    },
    {
      path: 'documents',
      requiredPermissions: [[Permission.PATIENT_DOCUMENTS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientDocumentsList {...patient!} />,
    },
    {
      path: 'forms',
      requiredPermissions: [[Permission.PATIENT_FORMS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientFormsList {...patient!} />,
    },
    {
      path: 'notes',
      requiredPermissions: [[Permission.PATIENT_NOTES_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientNotesList {...patient!} />,
    },
    {
      path: 'communications',
      requiredPermissions: [[Permission.COMMUNICATIONS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientCommunicationsList {...patient!} />,
    },
    {
      path: 'alerts',
      requiredPermissions: [[Permission.PATIENT_ALERTS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientAlertsList {...patient!} />,
    },
    {
      path: 'noah-records',
      requiredPermissions: [[Permission.PATIENT_HEARING_TESTS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientNoahRecordList {...patient!} />,
    },
    {
      path: 'audit-log',
      requiredPermissions: [[Permission.AUDIT_LOG_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientAuditLog {...patient!} />,
    },
  ];

  return (
    <Routes>
      <Route element={<PatientLayout loading={loading} navList={navList} patient={patient!} />}>
        {routes.map(({ requiredPermissions, path, element }) => (
          <Route key={path} element={<PermissionGuard requiredPermissions={requiredPermissions} navList={navList} />}>
            <Route path={path} element={element} />
          </Route>
        ))}
      </Route>
      <Route path='' element={<Navigate replace to='overview' />} />
      <Route path='*' element={<Navigate replace to='overview' />} />
    </Routes>
  );
};

export default PatientRouting;
