import { useCallback, useEffect, useState } from 'react';

type StorageValue<T, P extends boolean> = P extends true ? T | undefined : string | undefined;

const useSessionStorageListener = <T, P extends boolean>(
  key: string,
  parseJson: P = false as P
): StorageValue<T, P> => {
  const getStoredValue = useCallback((key: string, parseJson: P): StorageValue<T, P> => {
    const item = sessionStorage.getItem(key);
    if (parseJson && item) {
      try {
        return JSON.parse(item) as StorageValue<T, P>;
      } catch (error) {}
    }
    return item as StorageValue<T, P>;
  }, []);

  const [value, setValue] = useState<StorageValue<T, P>>(getStoredValue(key, parseJson));

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key && event.storageArea === sessionStorage) {
        setValue(parseJson && event.newValue ? JSON.parse(event.newValue) : event.newValue);
      }
    };

    const handleCustomEvent = () => {
      setValue(getStoredValue(key, parseJson));
    };

    // Override sessionStorage.setItem to trigger an event in the same tab
    const originalSetItem = sessionStorage.setItem;
    sessionStorage.setItem = function (k, v) {
      originalSetItem.apply(this, [k, v]);
      if (k === key) {
        window.dispatchEvent(new Event('sessionStorageChange'));
      }
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('sessionStorageChange', handleCustomEvent);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('sessionStorageChange', handleCustomEvent);
      sessionStorage.setItem = originalSetItem; // Restore original setItem
    };
  }, [getStoredValue, key, parseJson]);

  return value;
};

export default useSessionStorageListener;
