import { OrderApiService } from 'core/api';
import { IOrderDao } from 'core/api/types/order.interface';
import { IQueryOrder } from 'core/common/types';
import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useTheme } from 'core/providers/theme-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { where } from 'firebase/firestore';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';

const OrdersList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dialog = useDialog();
  const { userData, organisationData } = useUserState();
  const tableKey = 'orders.orders_list.table';
  const { primary } = useTheme();
  const orderFormSettings = useSelector(OrganisationSettingsSlice.selectOrderFormSettings);
  const disabledFields = orderFormSettings?.data?.disabledFields ?? [];
  const orderDateTemplate = (order: IOrderDao) => {
    return <p className='body-sm'>{dayjs(order.orderDate.toDate()).format('DD/MM/YYYY')}</p>;
  };

  const patientTemplate = (order: IOrderDao) => {
    const firstLine = order.patient.address?.formattedAddress.split(',')[0];
    const postCode = order.patient.address?.postcode;
    return (
      <div>
        <Link to={`/patients/${order.patient.uid}`} style={{ color: primary.bg }}>
          {order.patient.fullName}
        </Link>
        <p className='body-xs text-gray-500'>{firstLine && postCode ? `${firstLine}, ${postCode}` : 'N/A'}</p>
      </div>
    );
  };

  const goodsServicesTemplate = (order: IOrderDao) => {
    const accessoryCount = order.accessoriesAndServices.filter((item) => item.type === 'accessory').length;
    const serviceCount = order.accessoriesAndServices.filter((item) => item.type === 'service').length;
    const aidsCount = Object.keys(order.hearingAids).length;
    const items = [
      {
        key: 'accessories',
        count: accessoryCount,
        labelKey:
          accessoryCount > 1
            ? 'orders.orders_list.table.goods_services.accessories'
            : 'orders.orders_list.table.goods_services.accessory',
      },
      {
        key: 'services',
        count: serviceCount,
        labelKey:
          serviceCount > 1
            ? 'orders.orders_list.table.goods_services.services'
            : 'orders.orders_list.table.goods_services.service',
      },
      {
        key: 'hearingAids',
        count: aidsCount,
        labelKey:
          aidsCount > 1
            ? 'orders.orders_list.table.goods_services.hearing_aids'
            : 'orders.orders_list.table.goods_services.hearing_aid',
      },
    ];
    return (
      <div>
        {items.map((item) => (
          <p className='body-sm mb-px' key={item.key}>
            {item.count} {t(item.labelKey)}
          </p>
        ))}
      </div>
    );
  };

  const saleInformationTemplate = (order: IOrderDao) => {
    const { grossPrice, discount, vat } = order;
    const totalToPay = grossPrice + (vat?.amount ?? 0) - (discount ?? 0);
    const pricingData = [
      {
        key: 'grossPrice',
        labelKey: 'patients.patient.orders.gross_price',
        value: grossPrice,
      },
      ...(vat?.enabled
        ? [
            {
              key: 'vat',
              labelKey: 'patients.patient.orders.vat',
              value: vat?.amount ?? 0,
            },
          ]
        : []),
      {
        key: 'total',
        labelKey: 'patients.patient.orders.total',
        value: totalToPay,
      },
    ];

    if (discount !== undefined) {
      pricingData.splice(1, 0, {
        key: 'discount',
        labelKey: 'patients.patient.orders.discount',
        value: discount,
      });
    }

    return (
      <div className='grid grid-cols-3 w-full body-sm'>
        {pricingData.map((field) => (
          <Fragment key={field.key}>
            <p className='col-span-2'>{t(field.labelKey)}</p>
            <p className='text-right mb-px'>{formatToCurrency(field.value, organisationData?.finance.currency)}</p>
          </Fragment>
        ))}
      </div>
    );
  };

  const actionTemplate = (order: IOrderDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.ORDERS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => navigate(`edit?order=${order.uid}`)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.ORDERS_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='common.delete'
            appearance='link'
            danger
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => OrderApiService.permDelete(order.uid)}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('dialog.delete_order.title')}
                  textContent={t('dialog.delete_order.content')}
                  successMessage={t('dialog.delete_order.success.description')}
                  errorMessage={t('dialog.delete_order.error.description')}
                />,
              )
            }
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: (order: IOrderDao) => (
        <Link to={`/patients/${order.patient.uid}/orders`} style={{ color: primary.bg }}>
          {order.orderNumber}
        </Link>
      ),
      id: 'orderNumber',
    },
    {
      template: orderDateTemplate,
      id: 'orderDate',
    },
    {
      template: patientTemplate,
      id: 'patient',
    },
    {
      template: goodsServicesTemplate,
      id: 'goodsServices',
    },
    {
      template: saleInformationTemplate,
      id: 'saleInformation',
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  const tableColumns: ISharedTableColumn[] = [
    {
      labelKey: 'orders.orders_list.table.header.order_date',
      key: 'orderDate',
      contentTemplateId: 'orderDate',
      width: 200,
    },
    {
      labelKey: 'orders.orders_list.table.header.patient',
      key: 'patient',
      contentTemplateId: 'patient',
    },
    {
      labelKey: 'orders.orders_list.table.header.goods_services',
      key: 'goodsServices',
      contentTemplateId: 'goodsServices',
    },
    {
      labelKey: 'orders.orders_list.table.header.sales_information',
      key: 'saleInformation',
      contentTemplateId: 'saleInformation',
    },
    {
      key: 'actions',
      contentTemplateId: 'actions',
    },
  ];

  if (!disabledFields.includes('orderNumber')) {
    tableColumns.unshift({
      labelKey: 'orders.orders_list.table.header.order_number',
      key: 'orderNumber',
      contentTemplateId: 'orderNumber',
      width: 200,
    });
  }

  const queryOrder = useMemo((): IQueryOrder => ['updated.at', 'desc'], []);

  const queryConstraints = useMemo(
    () => [where('organisationUid', '==', userData?.organisationUid)],
    [userData?.organisationUid],
  );

  return (
    <>
      <SharedPageHeader title={t('navigation.orders')} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.ORDERS}
            queryConstraints={queryConstraints}
            queryOrder={queryOrder}
            tableConfig={{
              columns: tableColumns,
              contentTemplates: contentTemplates,
            }}
            errorMessageKey='orders.orders_list.error'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default OrdersList;
