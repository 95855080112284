import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { Drawer, Segmented } from 'antd';
import { Settings } from 'react-feather';
import FormItemMimic from 'shared/form/form-item-mimic';
import { CalendarMode, CalendarModeData, CalendarModeOptions } from 'core/constants/calendar-mode';
import { CalendarTimeframe, CalendarTimeframeData, CalendarTimeframeOptions } from 'core/constants/calendar-timeframe';
import { useUserState } from 'core/providers/user-provider';

const CalendarSettingsDrawer = ({ onChange }: { onChange: (event: any) => void }) => {
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showWeeklyViewToggle, setShowWeeklyViewToggle] = useState<boolean>(true);
  const [mode, setMode] = useState<string | null>(localStorage.getItem('calendarMode'));
  const [timeframe, setTimeframe] = useState<string | null>(localStorage.getItem('calendarTimeframe'));
  const { organisationData } = useUserState();

  const handleToggleChange = useCallback(
    (key: 'calendarMode' | 'calendarTimeframe', value: string | null) => {
      if (!value) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, value);
      }

      onChange({ key, value });

      if (key === 'calendarMode') {
        setMode(value);
      } else {
        setTimeframe(value);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (!mode) {
      handleToggleChange('calendarMode', CalendarMode.PEOPLE);
    }
    if (!timeframe && organisationData) {
      handleToggleChange('calendarTimeframe', organisationData.calendar.defaultTimeframe ?? CalendarTimeframe.DAY);
    }
  }, [mode, timeframe, handleToggleChange, organisationData]);

  useEffect(() => {
    if (mode === CalendarMode.CLINICS) {
      setShowWeeklyViewToggle(false);
      handleToggleChange('calendarTimeframe', CalendarTimeframe.DAY);
    } else {
      setShowWeeklyViewToggle(true);
    }
  }, [mode, t, handleToggleChange]);

  return (
    <>
      <SharedButton appearance='default' icon={<Settings size={20} />} onClick={() => setOpenDrawer(true)} />
      <Drawer title={t('calendar.settings_drawer.title')} onClose={() => setOpenDrawer(false)} open={openDrawer}>
        <FormItemMimic label={t('calendar.settings_drawer.mode')}>
          <Segmented
            value={mode}
            options={CalendarModeOptions.map((mode) => {
              const data = CalendarModeData[mode];
              return {
                value: data.value,
                label: t(data.translationLabelKey),
              };
            })}
            onChange={(value) => handleToggleChange('calendarMode', value)}
          />
        </FormItemMimic>
        {showWeeklyViewToggle && (
          <FormItemMimic label={t('calendar.settings_drawer.timeframe')}>
            <Segmented
              value={timeframe}
              options={CalendarTimeframeOptions.map((mode) => {
                const data = CalendarTimeframeData[mode];
                return {
                  value: data.value,
                  label: t(data.translationLabelKey),
                };
              })}
              onChange={(value) => handleToggleChange('calendarTimeframe', value)}
            />
          </FormItemMimic>
        )}
      </Drawer>
    </>
  );
};

export default CalendarSettingsDrawer;
