import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';

interface IPatientAlertNotesDialog {
  notes: string[];
}

const PatientAlertNotesDialog = ({ notes }: IPatientAlertNotesDialog) => {
  const { t } = useTranslation();

  return (
    <SharedDialogBase
      title={t('patients.patient.patient_alert_notes_warning')}
      customContentTemplate={
        <div className='p-4 overflow-y-auto'>
          <p className='mb-2'>{t('patients.patient.patient_alert_notes_warning.description')}</p>
          {notes.map((note) => (
            <p className='body-sm font-semibold' key={note}>
              {note}
            </p>
          ))}
        </div>
      }
    />
  );
};

export default PatientAlertNotesDialog;
