import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import AddEditPatientDialog from './add-edit-patient-dialog';
import SharedCard from 'shared/card/card';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { CollectionID } from 'core/constants/collection-id';
import { where } from 'firebase/firestore';
import { useUserState } from 'core/providers/user-provider';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import { IPatientDao } from 'core/api/types';
import dayjs from 'dayjs';
import { Select, Tag } from 'antd';
import { useTheme } from 'core/providers/theme-provider';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BulkAddPatientsDialog from './bulk-add-patients-dialog';
import ExportPatientsDialog from './export-patients-dialog';
import { IQueryOrder } from 'core/common/types';

const columns: ISharedTableColumn[] = [
  {
    labelKey: 'patients.table.header.patient_name',
    key: 'fullName',
    contentTemplateId: 'patient',
    width: 240,
  },
  { labelKey: 'patients.table.header.location', key: 'location', contentTemplateId: 'location', width: 350 },
  {
    labelKey: 'patients.table.header.status',
    key: 'status',
    contentTemplateId: 'status',
    width: 180,
  },
  {
    labelKey: 'patients.table.header.status_updated',
    key: 'statusUpdated',
    contentTemplateId: 'statusUpdated',
    width: 160,
  },
  {
    key: 'actions',
    contentTemplateId: 'actions',
    width: 100,
  },
];

const PatientDashboard = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const { primary } = useTheme();
  const workflowStatuses = useSelector(OrganisationSettingsSlice.selectPatientWorkflowStatuses) ?? [];
  const meta = useSelector(OrganisationSettingsSlice.selectMeta);
  const [activeStatuses, setActiveStatuses] = useState<string[]>([]);
  const options = workflowStatuses.map((item) => ({
    value: item.key,
    label: item.name,
  }));
  const navigate = useNavigate();

  const toggleStatus = (status: string) => {
    setActiveStatuses((prevStatuses) =>
      prevStatuses.includes(status) ? prevStatuses.filter((item) => item !== status) : [...prevStatuses, status],
    );
  };

  const headerActions = [
    {
      element: (
        <div className='space-x-2'>
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.PATIENTS_CREATE], [Permission.ORGANISATION_OWNER]]}
          >
            <SharedButton
              labelKey='patients.patients_dashboard.header.add_patient'
              onClick={() => dialog?.openDialog(<AddEditPatientDialog />)}
              appearance='primary'
            />
          </SharedElementPermissionGuard>
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.PATIENTS_EXPORT], [Permission.ORGANISATION_OWNER]]}
          >
            <SharedButton
              labelKey='patients.patients_dashboard.header.export_patients'
              onClick={() => dialog?.openDialog(<ExportPatientsDialog />)}
              appearance='default'
            />
          </SharedElementPermissionGuard>
          <SharedElementPermissionGuard requiredPermissions={[[Permission.PATIENTS_IMPORT]]}>
            <SharedButton
              onClick={() => dialog?.openDialog(<BulkAddPatientsDialog />)}
              type='button'
              labelKey='common.import'
            />
          </SharedElementPermissionGuard>
        </div>
      ),
      key: 'addPatient',
    },
  ];

  const patientTemplate = (data: IPatientDao) => {
    return (
      <Link to={`/patients/${data.uid}`} style={{ color: primary.bg }}>
        {data.fullName}
      </Link>
    );
  };

  const locationTemplate = (data: IPatientDao) => {
    const firstLine = data.address?.formattedAddress.split(',')[0];
    const postCode = data.address?.postcode;

    return (
      <div className='flex space-x-2 items-center'>
        <p>
          {firstLine && postCode ? `${firstLine}, ${postCode}` : t('patients.patient.information.address_not_defined')}
        </p>
      </div>
    );
  };

  const statusTemplate = (data: IPatientDao) => {
    const status = data.status.status;
    const statusName = workflowStatuses.find((s) => s.key === status)?.name;

    return (
      <div className='flex space-x-2 items-center'>
        <Tag color={primary.bg}>{statusName}</Tag>
      </div>
    );
  };

  const statusUpdatedTemplate = (data: IPatientDao) => {
    return (
      <div className='flex space-x-2 items-center'>
        <p>
          {dayjs(data.status.updated.at.toDate()).format('DD/MM/YYYY HH:mm')} by {data.status.updated.by.fullName}
        </p>
      </div>
    );
  };

  const actionTemplate = (patient: IPatientDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedButton
          onClick={() => navigate(patient.uid)}
          type='button'
          appearance='link'
          labelKey='common.view'
          primaryOverride
        />
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: patientTemplate,
      id: 'patient',
    },
    {
      id: 'location',
      template: locationTemplate,
    },
    {
      id: 'status',
      template: statusTemplate,
    },
    {
      id: 'statusUpdated',
      template: statusUpdatedTemplate,
    },
    {
      id: 'actions',
      template: actionTemplate,
    },
  ];

  const queryConstraints = useMemo(
    () => [
      where('organisationUid', '==', userData?.organisationUid),
      ...(activeStatuses.length ? [where('status.status', 'in', activeStatuses)] : []),
    ],
    [userData?.organisationUid, activeStatuses],
  );

  const queryOrder = useMemo((): IQueryOrder => ['updated.at', 'desc'], []);

  return (
    <div className='h-full flex flex-col md:flex-row relative overflow-hidden'>
      <div className='hidden md:block border-r bg-white p-4 basis-[280px] grow-0 shrink-0 sticky top-0'>
        <p className='header-md mb-4'>{t('patients.statuses')}</p>
        <div className='relative'>
          <div
            key={'ALL'}
            className={`flex items-center justify-between mb-1 py-1 relative ${
              activeStatuses.length ? 'opacity-50' : ''
            } hover:opacity-100`}
          >
            <Tag onClick={() => setActiveStatuses([])} className={'cursor-pointer'} color={primary.bg}>
              {t('patients.statuses.all')}
            </Tag>
            {meta?.data?.patientMeta.total ?? 0}
          </div>
          {workflowStatuses.map((item) => (
            <div
              key={item.key}
              className={`flex items-center justify-between mb-1 py-1 relative ${
                activeStatuses.includes(item.key) ? 'opacity-100' : 'opacity-50'
              } hover:opacity-100`}
            >
              <Tag onClick={() => toggleStatus(item.key)} className={'cursor-pointer'} color={primary.bg}>
                {item.name}
              </Tag>
              {meta?.data?.patientMeta[item.key] ?? 0}
            </div>
          ))}
        </div>
      </div>
      <div className='md:hidden block p-4'>
        <Select
          className='w-full'
          mode='multiple'
          placeholder='Status Filter'
          defaultValue={activeStatuses}
          options={options}
          onChange={(values) => setActiveStatuses(values)}
        />
      </div>
      <div className='overflow-y-auto px-4 pb-4 w-full'>
        <SharedPageHeader title={t('navigation.patients')} actions={headerActions} />
        <SharedCard>
          {userData && (
            <SharedPaginatedTable
              collectionId={CollectionID.PATIENTS}
              queryConstraints={queryConstraints}
              queryOrder={queryOrder}
              tableConfig={{
                columns,
                contentTemplates,
              }}
              errorMessageKey='patients.table.error.description'
              tableKey={'patients'}
            />
          )}
        </SharedCard>
      </div>
    </div>
  );
};

export default PatientDashboard;
