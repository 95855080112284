import { ControlType } from 'core/enums/control-type';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { AppointmentLocationData, AppointmentLocationOptions } from 'core/constants/appointment-location';
import { Dayjs } from 'dayjs';
import { IFormFieldsProps } from './types';

export const getFormFields = ({
  t,
  organisationData,
  selectedType,
  clinic,
  showSendConfirmationSwitch,
  assigneeList,
  recommendedResources,
  appointmentTypes,
  clinics,
  leadTypes,
  referralSubTypeOptions,
  calendarHours,
  checkTimeIsWithinCalendarHours,
  startTime,
  endTime,
  referral,
}: IFormFieldsProps): ISharedField[] => [
  {
    fieldKey: 'type',
    control: ControlType.Select,
    label: t('calendar.add_edit_appointment.form.appointment_type'),
    options:
      appointmentTypes.filter((type) => !type.deleted).map((type) => ({ label: type.name, value: type.uid })) ?? [],
    required: true,
  },
  {
    fieldKey: 'clinic',
    control: ControlType.Select,
    label: t('calendar.add_edit_appointment.form.clinic'),
    options:
      clinics?.filter((clinic) => !clinic.deleted).map((clinic) => ({ label: clinic.name, value: clinic.uid })) ?? [],
    required: true,
  },
  {
    fieldKey: 'location',
    control: ControlType.RadioButton,
    label: t('calendar.add_edit_appointment.form.location'),
    options: AppointmentLocationOptions.map((location) => {
      const option = AppointmentLocationData[location];
      return {
        label: t(option.translationLabelKey),
        value: option.value,
      };
    }),
    required: true,
    hidden: !selectedType || !clinic,
  },
  {
    fieldKey: 'assignee',
    control: ControlType.Select,
    label: t('calendar.add_edit_appointment.form.assignee'),
    options: assigneeList?.map((resource) => ({ label: resource.fullName, value: resource.uid })) ?? [],
    required: true,
    hidden: !selectedType || !clinic,
    extra: recommendedResources.length > 0 && (
      <div className='border border-blue-400 bg-blue-50 rounded-md px-4 py-2 body-xs text-blue-950'>
        <p className='font-bold mb-1'>Recommended assignee</p>
        {recommendedResources.map((res) => (
          <p key={res.uid}>• {res.fullName}</p>
        ))}
      </div>
    ),
  },
  {
    fieldKey: 'date',
    control: ControlType.DatePicker,
    label: t('calendar.add_edit_appointment.form.date'),
    disabledDate: (current: Dayjs) =>
      !(organisationData?.calendar.enabledDays ?? [0, 1, 2, 3, 4, 5, 6]).includes(current.day()),
    required: true,
    hidden: !selectedType || !clinic,
    fullWidth: true,
  },
  {
    fieldKey: 'startTime',
    control: ControlType.TimePicker,
    label: t('calendar.add_edit_appointment.form.start_time'),
    minuteStep: 5,
    required: true,
    hidden: !selectedType || !clinic,
    allowClear: true,
    fullWidth: true,
    disabledTime: () => ({
      disabledHours: () => calendarHours.disabled,
    }),
    dependencies: ['endTime'],
    customRules: [
      {
        validator: async (_, value: Dayjs) => {
          if (value) {
            if (endTime && value.isAfter(endTime, 'minute')) {
              return Promise.reject(t('calendar.add_edit_appointment.form.start_time_after_end'));
            }

            if (!checkTimeIsWithinCalendarHours(value)) {
              return Promise.reject(t('calendar.add_edit_appointment.form.time_outside_working_hours'));
            }
          }
        },
      },
    ],
  },
  {
    fieldKey: 'endTime',
    control: ControlType.TimePicker,
    disabledTime: () => ({
      disabledHours: () => calendarHours.disabled,
    }),
    minuteStep: 5,
    label: t('calendar.add_edit_appointment.form.end_time'),
    required: true,
    hidden: !selectedType || !clinic,
    allowClear: true,
    fullWidth: true,
    dependencies: ['startTime'],
    customRules: [
      {
        validator: async (_, value: Dayjs) => {
          if (value) {
            if (startTime && value.isBefore(startTime, 'minute')) {
              return Promise.reject(t('calendar.add_edit_appointment.form.end_time_before_start'));
            }

            if (!checkTimeIsWithinCalendarHours(value)) {
              return Promise.reject(t('calendar.add_edit_appointment.form.time_outside_working_hours'));
            }
          }
        },
      },
    ],
  },
  {
    fieldKey: 'additionalNote',
    control: ControlType.TextArea,
    label: t('calendar.add_edit_appointment.form.additional_information'),
    rows: 3,
    required: false,
    hidden: !selectedType || !clinic,
  },
  {
    fieldKey: 'referral',
    control: ControlType.Select,
    label: t('calendar.add_edit_appointment.form.referral'),
    options:
      leadTypes
        .filter((leadType) => !leadType.deleted)
        .map((leadType) => ({
          label: leadType.name,
          value: leadType.uid,
        })) ?? [],
    required: false,
    hidden: !selectedType || !clinic,
    allowClear: true,
  },
  {
    fieldKey: 'referralSubType',
    control: ControlType.Select,
    label: t('calendar.add_edit_appointment.form.referral_sub_type'),
    options: referralSubTypeOptions ?? [],
    required: false,
    hidden: !referral || !referralSubTypeOptions || referralSubTypeOptions.length === 0,
    allowClear: true,
  },
  {
    fieldKey: 'sendConfirmation',
    control: ControlType.Switch,
    label: t('calendar.add_edit_appointment.form.send_confirmation'),
    required: true,
    hidden: !selectedType || !clinic || !showSendConfirmationSwitch,
  },
];
