import { useState, useEffect } from 'react';

const useOS = () => {
  const [os, setOS] = useState<'mac' | 'windows' | 'other'>('other');

  useEffect(() => {
    if (/Mac|iPod|iPhone|iPad/.test(navigator.userAgent)) {
      setOS('mac');
    } else if (/Win/.test(navigator.userAgent)) {
      setOS('windows');
    }
  }, []);

  return os;
};

export default useOS;
