import { LoadingOutlined } from '@ant-design/icons';
import { App, Checkbox, Input, InputRef, Spin } from 'antd';
import { MarketingApiService } from 'core/api';
import { IMarketingQueryDao } from 'core/api/types/marketing-query.interface';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { arrayUnion } from 'firebase/firestore';
import { IUserDao } from 'core/api/types';
import { ArrowLeftCircle, Send } from 'react-feather';
import SharedSpinner from 'shared/spinner/spinner';
import { useTheme } from 'core/providers/theme-provider';
import Typewriter from 'typewriter-effect';
import { motion } from 'framer-motion';
import FadeWrapper from 'shared/animation/fade-wrapper';
import { AnimationState } from 'core/constants/animation-state';
import MarketingQueryMessage from './marketing-query-message';

const MarketingQuery = ({ creating }: { creating: boolean }) => {
  const { t } = useTranslation();
  const [userPrompt, setUserPrompt] = useState('');
  const [sending, setSending] = useState(false);
  const { uid } = useParams();
  const { userData } = useUserState();
  const [marketingQuery, setMarketingQuery] = useState<IMarketingQueryDao>();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [loadingConversation, setLoadingConversation] = useState(true);
  const [generatingResponse, setGeneratingResponse] = useState(false);
  const { primary } = useTheme();
  const inputRef = useRef<InputRef>(null);
  const [showPrompts, setShowPrompts] = useState(false);
  const [animationState, setAnimationState] = useState(AnimationState.ENTERING);
  const [userIntroSetting, setUserIntroSetting] = useState<Record<string, boolean>>({});
  const [hideIntroOnSubmit, setHideIntroOnSubmit] = useState(false);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (!creating && uid && userData?.organisationUid) {
      setLoadingConversation(true);
      unsubscribe = MarketingApiService.onDocSnapshot(
        uid,
        (doc) => {
          if (doc.exists()) {
            setMarketingQuery(doc.data());
            setLoadingConversation(false);
          }
        },
        (err) => {
          setLoadingConversation(false);
          message.error(t('marketing.marketing_query.fetch.error'));
        }
      );
    } else {
      setLoadingConversation(false);
    }

    const marketingQueryIntroString = localStorage.getItem('marketingQueryUserIntroSetting');
    if (marketingQueryIntroString) {
      const marketingQueryIntro = JSON.parse(marketingQueryIntroString);
      setUserIntroSetting(marketingQueryIntro);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [creating, message, t, uid, userData?.organisationUid]);

  const generateResponse = async (uid: string) => {
    try {
      setGeneratingResponse(true);
      await MarketingApiService.generateMarketingQueryResponse(uid);
    } catch (error) {
      message.error(t('marketing.marketing_query.response.error'));
    } finally {
      setGeneratingResponse(false);
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current!.focus();
        }, 1000);
      }
    }
  };

  const newMessage = (prompt: string, user?: IUserDao) => {
    if (!userData?.organisationUid) {
      throw new Error('User data is not completed');
    }
    const actionTimestamp = getActionTimestampFromUser(userData);
    return {
      sender: { type: 'user' as const, name: userData.fullName },
      publicMessage: prompt,
      internalMessage: prompt,
      createdAt: actionTimestamp.at,
    };
  };

  const createNewQueryDoc = async (prompt: string) => {
    const queryUid = await MarketingApiService.createMarketingQuery(prompt);
    navigate(`/marketing/${queryUid}`, { replace: true });
    return queryUid;
  };

  const onPromptSubmit = async (prompt: string) => {
    if (creating) {
      setAnimationState(AnimationState.EXITING);
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
    if (hideIntroOnSubmit) {
      localStorage.setItem(
        'marketingQueryUserIntroSetting',
        JSON.stringify({ ...userIntroSetting, [userData?.uid!]: false })
      );
    }
    let queryUid: string;
    try {
      setSending(true);
      if (creating || !uid) {
        queryUid = await createNewQueryDoc(prompt);
      } else {
        queryUid = uid;
        await MarketingApiService.update(uid, {
          updated: getActionTimestampFromUser(userData),
          conversationHistory: arrayUnion(newMessage(prompt, userData)),
        });
      }
      setUserPrompt('');
      generateResponse(queryUid);
    } catch (error) {
      message.error(t('marketing.marketing_query.send.error'));
    } finally {
      setSending(false);
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current!.focus();
        }, 1000);
      }
    }
  };

  return (
    <div className='grow my-4 overflow-hidden rounded-md bg-white shadow-md flex flex-col'>
      <div className='border-b p-4'>
        <button
          className='bg-transparent flex items-center text-white-600 mb-px cursor-pointer text-gray-600'
          onClick={() => navigate(-1)}
        >
          <ArrowLeftCircle size={16} />
          <p className='body-sm ml-1'>{t('common.back')}</p>
        </button>
      </div>
      <div className='grow flex overflow-hidden'>
        {loadingConversation ? (
          <div className='grow flex flex-col items-center justify-center'>
            <SharedSpinner color={primary.bg} size={32} />
          </div>
        ) : (
          <>
            {(!marketingQuery || marketingQuery?.conversationHistory.length === 0) && !generatingResponse ? (
              <>
                {userIntroSetting[userData?.uid!] !== false && (
                  <FadeWrapper
                    className='grow flex flex-col items-center justify-center text-center p-4'
                    animationState={animationState}
                  >
                    <Typewriter
                      onInit={(typewriter) => {
                        typewriter
                          .pauseFor(500)
                          .changeDelay(100)
                          .typeString('<span class="text-2xl">Hi!</span>')
                          .pauseFor(1200)
                          .changeDelay(45)
                          .typeString(
                            '<span class="body-sm leading-6"><br><br>My name is <strong>Tonee</strong>, HearLink\'s AI assistant.</span>'
                          )
                          .pauseFor(1200)
                          .typeString(
                            '<span class="body-sm leading-6"><br>I can help you identify patients in your database using a variety of criteria.</span>'
                          )
                          .pauseFor(1200)
                          .typeString(
                            '<span class="body-sm leading-6"><br>As with all AI, I\'m still learning, so please be patient with me and correct me if I make a mistake.</span>'
                          )
                          .pauseFor(1200)
                          .typeString(
                            '<span class="body-sm leading-6"><br>To get started, I\'ve prepared a few prompt examples you can try out, or feel free to type your own query.</span>'
                          )
                          .callFunction(() => {
                            setTimeout(() => {
                              setShowPrompts(true);
                            }, 800);
                          })
                          .start();
                      }}
                    />
                    {showPrompts && (
                      <div className='flex flex-col items-center mt-4'>
                        {[
                          'marketing.marketing_query.example_1',
                          'marketing.marketing_query.example_2',
                          'marketing.marketing_query.example_3',
                        ].map((example, index) => (
                          <motion.button
                            key={example}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                              duration: 0.8,
                              delay: index * 0.4, // Stagger effect
                            }}
                            className='bg-white text-blue-500 mb-2 last:mb-0 flex items-center space-x-2'
                            onClick={() => {
                              setUserPrompt(t(example));
                              onPromptSubmit(t(example));
                            }}
                          >
                            <span>{t(example)}</span>
                            <Send size={16} className='hidden md:block' />
                          </motion.button>
                        ))}
                        <motion.div
                          className='mt-4'
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{
                            duration: 0.8,
                            delay: 3 * 0.4, // Stagger effect
                          }}
                        >
                          <Checkbox
                            checked={hideIntroOnSubmit}
                            onChange={(e) => setHideIntroOnSubmit(e.target.checked)}
                          >
                            {t('marketing.marketing_query.hide_intro')}
                          </Checkbox>
                        </motion.div>
                      </div>
                    )}
                  </FadeWrapper>
                )}
              </>
            ) : (
              <div className='grow overflow-y-auto flex flex-col-reverse p-4'>
                {generatingResponse && (
                  <div className='justify-start flex w-full mt-6 last:mt-0'>
                    <div className='flex flex-col md:max-w-[60%]'>
                      <div className='flex items-center mb-1 space-x-1'>
                        <p className='font-semibold text-[15px]'>Tonee</p>
                        <div className='hearlink-ai-mask' />
                      </div>
                      <div className='bg-gray-100 rounded-tl-none p-2 px-3 rounded-xl w-fit flex items-center space-x-2'>
                        <p className='text-gray-900 animate-pulse'>Generating response</p>
                        <Spin className='text-gray-700' indicator={<LoadingOutlined spin />} size='small' />
                      </div>
                    </div>
                  </div>
                )}
                {marketingQuery?.conversationHistory
                  .filter((msg) => msg.sender.type !== 'system')
                  .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
                  .map((msg, index) => (
                    <MarketingQueryMessage key={`msg-${index}`} msg={msg} />
                  ))}
              </div>
            )}
          </>
        )}
      </div>
      <div className='basis-[64px] flex-grow-0 flex-shrink-0 bg-gray-50 border-t p-4'>
        <form
          className='flex items-center space-x-2'
          onSubmit={(event) => {
            event.preventDefault();
            onPromptSubmit(userPrompt);
          }}
        >
          <Input
            ref={inputRef}
            autoFocus
            disabled={sending || generatingResponse}
            value={userPrompt}
            onChange={(event) => setUserPrompt(event.currentTarget.value)}
            id='messageInput'
          />

          <SharedButton loading={sending} appearance='primary' type='submit' labelKey='common.submit' />
        </form>
      </div>
    </div>
  );
};

export default MarketingQuery;
